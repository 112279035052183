.gallery__inner {
		position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
		@include u.below('md') {
		    position: absolute;
				top: 0;
				left: 0;
		}
}
