/* Layout helpers
---------------------------------- */
.ui-helper-hidden {
	display: none;
}

.ui-helper-hidden-accessible {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
}

.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}

.ui-helper-clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.ui-helper-clearfix {
	display: inline-block;
}

/*  required comment for clearfix to work in Opera \ */
* html .ui-helper-clearfix {
	height: 1%;
}

.ui-helper-clearfix {
	display: block;
}

/*  end clearfix  */
.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0);
}
