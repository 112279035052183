@use "src/assets/scss/utils" as u;
@import "src/assets/scss/old/common/_variables.scss";
@import "src/assets/scss/old/common/_mixins.scss";

html {
  width: 100%;
  overflow-x: hidden;
  &.index-page {
    *, :after, :before {
      box-sizing: border-box;
    }
  }
}

@keyframes skeleton-shine {
  to {
    background-position: 125% 0,
    0 0,
    75px 20px,
    75px 0,
    0 70px,
    0 430px;
  }
}

.lozad-picture, .lozad-image {
  transition: opacity .5s ease-in-out;
  opacity: 0;

  &[data-loaded="true"] {
    opacity: 1;
  }
}

html, body {
  @include media((
          overflow-x: (md: hidden),
          overflow-y: (md: auto),
  ));
  min-height: 100%;
}

body {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  color: $color-text;
  font-family: $basic-font-family;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  bottom: 0;
  //  right: 0;
  //  background-color: rgba(#{u.var-color('black')}, .3);
  //  opacity: 0;
  //  visibility: hidden;
  //  transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
  //}
  &.body--logged {
    @include media((
            padding: (md: $offset-size-header 0 60px 0)
    ));
  }

  @include media((
          transition: (md: (padding .25s ease-in-out)),
          box-sizing: (md: border-box),
          padding: (md: $offset-size-header 0 0)
  ));
}

.nowrap {
  white-space: nowrap;
}

/**
Скрываем отображение CRON тасков phpbb
 */
img[alt=cron] {
  display: none;
}

.site-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  transition: margin .3s ease-in-out;

  *, *:before, *:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

.btn {
  display: inline-block;
  padding: 0.5em 1em;
  margin: 2px 0;
  border: solid 1px transparent;
  color: #333333;
  font-family: $decor-font-family;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  //@include button-reset;
  @include u.below('md') {
    box-sizing: border-box;
  }
}

.btn--default {
  border-color: $color-background;
  background-color: $optwear-white;
}

.btn--blue {
  @include btn-variant($color-text-invert, $optwear-blue-light, $optwear-blue-strong, to left, $optwear-blue-strong);
}

.btn--orange {
  @include btn-variant($color-text-invert, #f5b16d, #d55333, to left, #d55333);
}

.btn--gray {
  @include btn-variant($color-text-invert, #eaebec, darken(#eaebec, 25%), to left, darken(#eaebec, 25%));
}

.btn--warn {
  @include btn-variant($color-text-invert, #f00, desaturate(#f00, 45%), to left, desaturate(#f00, 35%));
}

.btn--success {
  @include btn-variant($color-text-invert, #2bc253, #2bc253, to left, #2bc253);
}

.inpt {
  position: relative;
  width: 100%;
  height: 32px;
  padding: 0 12px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  font-size: 15px;
  line-height: normal;
  box-shadow: none;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 0.25s;

  &:hover {
    border-color: rgba(0, 0, 0, 0.24);
    transition: all 0.3s;
  }

  &:focus {
    border: 1px solid $optwear-blue-light;
    outline: none;
  }

  &[readonly] {
    background-color: #edeef0;
  }
}

select.inpt {
  -moz-appearance: menulist;
  -webkit-appearance: menulist;
}

.img-responsive {
  //@include img-responsive;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  font-family: "object-fit: contain;";
  width: 100% !important;
  height: 100% !important;
}

.loader {
  position: relative;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  background-color: $optwear-blue-light;
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border: 0 solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: loading 1000ms ease-out forwards infinite;
  }

}

.loader-horizontal {
  position: relative;
}

.loader-horizontal div {
  position: absolute;
  top: 20px;
  left: -20px;
  width: 20px;
  height: 20px;
  background-color: $color-text-weak;
  border-radius: 50%;
  animation: loading-horizontal 4s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);

  &:nth-child(2) {
    animation-delay: 150ms;
  }

  &:nth-child(3) {
    animation-delay: 300ms;
  }

  &:nth-child(4) {
    animation-delay: 450ms;
  }
}

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap; /* 1 */
}

.hidden {
  display: none;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: inherit;
}

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

.pagination {
  padding: 0;
  border-right: 0;
  font-weight: normal;

  &__item {
    display: inline-block;
    height: 34px;
    padding: 1px 10px;
    margin: 0;
    border: 1px solid #d8d8d8;
    color: $optwear-white;
    font-size: 18px;
    line-height: 36px;
    font-weight: normal;
    text-decoration: none;
    float: left;
    background-color: $optwear-white;

    &:focus,
    &:active,
    &:visited,
    &:link {
      color: $optwear-blue-light;
      text-decoration: none;
    }

    &:focus,
    &:active,
    &:hover {
      color: $optwear-white;
      background-color: $optwear-blue-strong;
    }

    &-active {
      position: relative;
      display: inline-block;
      font-size: 18px;
      background-color: $optwear-blue-light;
    }
  }

}

.error404 {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  max-width: 477px;

  .Logo-default {
    margin: 0;
  }

  .btn {
    font-size: 22px;
  }

  span {
    text-align: center;
  }
}

.dropdown-pane {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
}

@import "line-clamp";
@import "order-status";
@import "controls";
@import "layout";
@import "modules";
