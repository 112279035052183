.reveal-overlay, .reveal {
	display: block;
	> .reveal {
			@include u.below('md') {
					box-sizing: border-box;
			}
	}
}


.reveal {
	background: none;
	border: none;
	overflow: hidden;
	@media screen and (max-width: 39.9375em) {
		overflow: scroll;
	}

	dl dt {
		font-weight: 300;
	}
}

.gallery__main-image-wrapper {
	display: flex;
	justify-content: center;
}

.gallery-thumbs-container__list {
	justify-content: center;
		@include u.below('md') {
		    padding: 0;
		}
}

.modal-container {
	position: relative;
	display: flex;

	.Search-box-clear {
		position: absolute;
		right: 0 !important;
		left: auto !important;
		top: 0 !important;
		display: block;
		color: black;
		opacity: 0.4;
		cursor: pointer;
		transition: opacity 0.5s;
		font-size: 1.8em;
		z-index: 9;
			font-weight: 400 !important;
			@include u.below('md') {
			    position: absolute;
					top: 5px !important;
					right: 15px !important;
					font-size: 25px;
					padding: 0 0 0 15px !important;
			}


		&:focus {
			outline: none;
		}

		&:hover, &:focus {
			opacity: 1;
		}

		&:active {
			opacity: 1;
			font-weight: bold;
		}
	}

	.lot-summary {
		box-shadow: 0 2px 8px rgba(0, 0, 0, .33);


		&-label {
			position: relative;
@include u.below('md') {
    position: initial;
}
			&__header {
				max-width: calc(100% - 55px) !important;
					@include u.below('md') {
					    max-width: none;
							width: 100%;
							
					}
			}
		}

		&-description {
			//overflow-y: auto;
			max-height: 15em;
		}
	}
}

.modal-arrow {
	width: 70px;
	position: relative;
	cursor: pointer;
	top: 0;
	min-height: 100%;

	&__icon {
		left: 20%;

		opacity: 0.4;
		transition: opacity 0.15s ease-in-out;
		cursor: pointer;
		position: relative;
		top: 50%;
		transform: translateZ(0);

		&_disabled {
			opacity: 0;
		}

		&:hover, &:focus {
			outline: none;
		}

		&:hover polyline,
		&:focus polyline {
			stroke-width: 3;
		}

		&:active polyline {
			stroke-width: 6;
			transition: all 100ms ease-in-out;
		}

		&:hover polyline,
		&:focus polyline {
			stroke-width: 3;
		}

		&:active polyline {
			stroke-width: 6;
			transition: all 100ms ease-in-out;
		}

		polyline {
			transition: all 250ms ease-in-out;
			fill: none;
			stroke: $optwear-white;
			stroke-width: 1;
			stroke-linecap: round;
			stroke-linejoin: round;
		}
	}

	&:hover &__icon,
	&:focus &__icon {
		outline: none;
		opacity: 1;
	}
}

.modal-left {
	left: 0;

	.modal-arrow__icon {
		left: 0;
	}
}

.modal-right {
	right: 0;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
