@use "src/assets/scss/utils" as u;

.lot-user-form {
  border: 1px solid #dddddd;
  border-radius: 3px;
  box-shadow: 4px 4px 1px #eeeeee;
  margin-bottom: 0;
  margin-right: 5px;
  padding: 0;
  margin-top: 10px;
  background: #e2e2e2;

  &__item {
    margin: 0;
    padding: 5px 5px 5px 0;
  }

  &__item-title {
    text-align: left;
    padding-left: 5px;
    padding-top: 8px;
    float: left;
    line-height: 14px;
    word-wrap: break-word;
    font-size: small;
    width: 85px;
  }

  &__item-main {
    min-height: 30px;
    padding-top: 4px;
    margin-left: 79px;
  }

  &__price-amount {
    font-size: 18px;
    font-weight: 700;
    margin-right: 5px;
    padding-top: 10px;
  }

  &__submit {
    cursor: pointer;
    -webkit-appearance: none;
    border: solid transparent;
    position: relative;
    border-radius: 4px;
    letter-spacing: 0.06em;
    transition: all 0.12s ease-out;
    background-color: $optwear-buy-button;

    &:hover, &:focus {
      background-color: darken($optwear-buy-button, 10%);
    }

    &[disabled] {
      background-color: $color-background;
    }

    padding: 0 18px;
    border-width: 2px;
    width: 100%;
    color: $optwear-white;

    &-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      a, a:link {
        display: block;
        text-align: center;
        vertical-align: middle;
        color: $optwear-white;
        margin: 0;
        padding: 0;
        line-height: 3.5em;
        text-decoration: none;

        &:hover, &:focus, &:active {
          text-decoration: none;
        }
      }
    }

  }

  &__add-to-other-cart-wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;

    input {
      flex-grow: 2;
    }

    label {
      padding: 0 5px;
    }
  }

  &__success {
    background-color: $optwear-white;
    border-color: $optwear-buy-button;
    color: darken($optwear-buy-button, 10%);
    font-weight: 700;

    &:hover, &:focus {
      background-color: $optwear-white;
    }

    &-text {
      position: relative;
      color: darken($optwear-buy-button, 10%);

      &:before {
        position: relative;
        content: " ";
        width: 30px;
        display: inline-block;
        height: 11px;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAANCAMAAACXZR4WAAAAkFBMVEUAAAAAAACAAACqAACAAACZADOqFSukEjevIDCqHDmuGzaqGDGxFjeqIDWxHTuwGjWyHzaxHDmyIDmzHjm0IDmzIDqzIDqzITqzIDuzIDuzIDq0ITuzITqzITqzIDqzIDuzIDqzIDq0IDqzITqzIDqzITuzIDu0IDuzIDqzIDq0IDq0IDqzITuzIDq0IDu0ITs3bouXAAAAL3RSTlMAAQIDBAUMDhASExUXGBodISQ/Q0eRlpugpaeqq6yvtri9xcnN0dTV19jc3+L192va3FkAAABtSURBVAgdBcEJIkIBAEDBUZ9KCGXLmsrOu//tzACAw8cVABie+lsCYHxfPQMwuqtejwCMbqvtBMv1gIObajfF5W8PA9fV/hheqvX4qnqfg8m2+qg+TwHTXVVfZwBm++p7AYD5Wz/nAOBkcwHgH9gcC/RVRBitAAAAAElFTkSuQmCC") 8px 50% no-repeat;
      }
    }
  }

  &__add-to-favorites {
    border: 2px solid #c7c7c7;
    color: $color-text;
    letter-spacing: 0;
    width: u.size('unit-7x');
    margin-left: u.size('unit');
    @include u.below('lg') {
      width: u.size('unit-5x');
    }
    @include u.below('md') {
      margin-left: u.size('unit-half');
    }


    background-color: transparent;
    background-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
      transition: all 0.25s;

      path {
        fill: #c7c7c7;
      }
    }

    &:hover, &:focus {
      border-color: $optwear-orange-middle;
      background-color: $optwear-orange-middle;

      svg {
        transform: scale(0.9);
      }

      svg path {
        fill: $optwear-white;
      }
    }

    &_active {
      border: 0;

      svg path {
        fill: $optwear-orange-middle;
      }

    }
  }

  &__error {
    background-color: $optwear-blue-light;
  }
}

@import "lot-user-form-attr-list";
@import "lot-user-form-quantity";
