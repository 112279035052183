@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.gallery {
  position: relative;
  margin: 0;

  &-fancybox__label {
    border-left: 1px solid $color-background;
    text-align: left;
    padding-left: 10px;
  }

  &__item-wrap {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

@import "gallery-thumbs-container/gallery-thumbs-container";
@import "image-container";
@import "inner";
@import "main-image";
@import "gallery-zoom";
