@use "src/assets/scss/utils" as u;

.TopicQuickReplyButtonsBB {
  background-color: #efefef;
  font-family: $decor-font-family;
  @include u.below('md') {
    padding: 0 0 10px;
  }

  &-tip {
    margin: 0;
  }

  &-button {
    transition: all 0.25s;
    @include u.below('md') {
      padding: 10px !important;
    }

    &--smile {
      font-size: 27px;

      &:hover,
      &:focus {
        > svg {
          fill: u.var-color('primary');
        }
      }
    }
  }
}
