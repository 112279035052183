.AnnouncementBlock-Page {
	margin-left: 5%;
	list-style-type: none;
	line-height: 1.5em;
	font-size: 25px;
	max-width: 800px;
	text-align: center;
}

.AnnouncementBlock-Page_separator {
	margin: 20px 0;
}

.AnnouncementBlock-Page_list {

	padding-top: 25px;

}

.AnnouncementBlock-Page_topSpace {
	margin-top: 20px;
}
.AnnouncementBlock-Page_hr {
	box-sizing: content-box;
	overflow: visible;
	clear: both;
	max-width: 75rem;
	height: 0;
	margin: 0.5rem auto;
	border-top: 0;
	border-right: 0;
	border-bottom: 1px solid #cacaca;
	border-left: 0;
}
