//@import 'common/fonts';
@use "src/assets/scss/utils" as u;
@use 'sass:color';
@import "common/variables";
@import "blocks/catalog/catalog";
@import "blocks/fancybox/fancybox-overrides";
@import "blocks/drift-zoom/drift-zoom-overrides";
@import "blocks/them-stat/them-stat";
@import "blocks/cart-user-settings/cart-user-settings";
@import "blocks/sp-comments/sp-comments";
@import "blocks/catalogs-list/catalogs-list";

@import "blocks/pristroy/edit/pristroy-edit";
@import "blocks/switch/switch";
@import "blocks/org-turbo/org-turbo";
@import "blocks/office-widget/office-widget";

.button_sp {
  cursor: pointer;
  font-weight: bold;
  color: #2e6e9e;
  padding: 3px;
  margin: 0;
  white-space: nowrap;
  display: inline-block;
  zoom: 1;
  background: #d1e5f5 linear-gradient(#d1e5f5, #ffffff);
  background-size: 100% 100%;
  background-clip: border-box;
  background-origin: border-box;
  border-radius: 5px;
  border: 1px solid #7ec9f2;
}

.button_sp:hover {
  color: #2e6e9e;
  text-decoration: none;
  box-shadow: 0 1px 5px #666666;
  zoom: 1;
  background: linear-gradient(#ffffff, #d1e5f5);
}

.button_sp:disabled {
  color: #cccccc;
}

.inner {
  border-bottom: none;
  margin: 20px 0;
}

.panel table.sp {
  thead {
    background: $optwear-blue-gradient;
  }

  th {
    background: none;
  }
}

#report table.sp {
  thead {
    background: inherit;
  }
}

.forabg {
  border: 1px solid $color-background;
  background: transparent;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  .reservs &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    transform: translateY(-5px);
  }

}

table.sp {
  //max-width: 1200px;
  width: 100%;
  color: #000;
  border-collapse: collapse;
  border-spacing: 0;
}

table.splist {
  width: 100%;
  color: #000;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1.3em;
}

table.splist th {
  text-align: center;
}

table.splist td {
  padding: 3px;
}

table.splist tr {
  border-bottom: 1px solid #000;
  background-color: #EAF2F8;
}

table.splist tfoot tr {
  background: transparent;
}

table.spcart {
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 1px solid #000;
}

table.sp thead th {
  color: #FFFFFF;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.3em;
  padding: 2px 3px;
  text-transform: uppercase;
}

table.sp tbody td {
  padding: 5px;
}

table.sp td {
  //font-size: 1.1em;
}

table.sp table td {
  border: 0 none;
  padding: 0;
}

table.sp table.sp td {
  border: 1px solid #AAA;
  border-top: 1px solid #FAFAFA;
  padding: 0;
}

.cartpurstat0 {
  background-color: color.scale($optwear-green, $lightness: 50%);
}

.cartpurstat2 {
  //background-color: #F9FCC0;
  background-color: color.scale($optwear-yellow, $lightness: 50%);
}

.cartpurstat5 {
  background-color: color.scale($optwear-blue, $lightness: 50%);
}

.cartpurstat6 {
  background-color: color.scale($optwear-red, $lightness: 50%);
}

.o_state0 {
  background-color: $optwear-green;
  color: #fff;
}

.o_state1 {
  background-color: $optwear-orange;
  color: #fff;
}

.o_state2 {
  background-color: #FF69B4;
  color: #fff;
}

.o_state3 {
  background-color: $optwear-red;
  color: #fff;
}

.o_state4 {
  background-color: $optwear-blue;
  color: #fff;
}

.o_state5 {
  background-color: #9D9D9D;
  color: #fff;
}

.o_state6 {
  background-color: $optwear-red;
  color: #fff;
}

.o_state7 {
  background-color: $optwear-indigo;
  color: #fff;
}

.o_state8 {
  background-color: $optwear-purple;
  color: #fff;
}

.o_state9 {
  background-color: $optwear-cyan;
  color: #fff;
}

.o_state10 {
  background-color: $optwear-brown;
  color: #fff;
}


.t_o_state0 {
  color: #00FF00;
}

.t_o_state1 {
  color: #ff9900;
}

.t_o_state2 {
  color: #000;
}

.t_o_state3 {
  color: #FF0000;
}

.t_o_state4 {
  color: #1E6CED;
}

.t_o_state5 {
  color: #9D9D9D;
}

.t_o_state6 {
  color: #FF0000;
}

.t_o_state7 {
  color: #8040FF;
}

.t_o_state8 {
  color: #FF00FF;
}

.oo_state0 {
  background-color: #00FF00;

}

.oo_state1 {
  background-color: #ff9900;

}

.oo_state2 {
  background-color: #fff;

}

.oo_state3 {
  background-color: #FF0000;

}

.oo_state4 {
  background-color: #1E6CED;

}

.oo_state5 {
  background-color: #9D9D9D;

}

.oo_state6 {
  background-color: #FF0000;

}

.oo_state7 {
  background-color: #8040FF;

}

.oo_state8 {
  background-color: #FF00FF;
}

table.sp table td.spprdesc {
  background: #F0C4F7;
  border: 1px solid #989898;
  padding: 5px 2px;
}

.cart-props-info {
  background-color: $optwear-yellow;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: u.size('unit-2x') u.size('unit');
}

.cart-props-info img {
  max-height: 200px;
  max-width: 200px;
}

/* spoiler rule */
.cart-props-info div[style*="border-right: 2px solid #B9CBDC"] img {
  max-height: 100%;
  max-width: 100%;
}

.sp_cart {
  color: #EFEFF5;
}

.sp_cart_oplata {
  color: #ff0000;
  font-size: 13px;
}

.sp_jpopup {
  //background: url(images/sp/rightarrow.png) no-repeat center center;
  cursor: pointer;
  width: 16px;
  overflow: hidden;
  height: 0;
  padding-top: 16px;
  display: inline-block;
}

.sp_menu div {
  position: absolute;
  width: 260px;
  background: #e4e4e4;
  border: 1px solid #a4a4a4;
  box-shadow: 2px -3px 5px #c5c5c5, -3px 0px 5px #cdcdcd;
  display: none;
  z-index: 9999;
}

.sp_menu div ul li {
  white-space: normal;
  list-style-type: none;
  border-bottom: 1px solid #989898;
  padding: 5px 2px;
  margin: 1px 2px 1px 2px;
  color: #2E6E9E
}

.sp_menu div ul li:hover {
  background: #C1C4F8;
}

.sp_menu div ul li a {
  font-size: 10px;
  color: #989898;
  display: block;
}

.sp_menu div ul li a:link {
  color: #2E6E9E;
}

.sp_menu div ul li a:visited {
  color: #2E6E9E;
}

.sp_menu div ul li a:hover {
  color: #2E6E9E;
  text-decoration: none;
}

.sp_menu div ul li a:active {
  color: #2E6E9E;
}

ul.spprlist li {
  list-style-type: none;
  border: 1px solid #989898;
  padding: 2px;
  margin: 3px;
}

ul.spprlist li.select {
  background: #F0C4F7;
  padding: 2px;
  margin-right: -2px;
}

ul.spprlist li.select2 {
  background: #EAF1BE;
  padding: 2px;
  margin-right: -2px;
}

table.sp table td.spprdesc {
  background: #F0C4F7;
  border: 1px solid #989898;
  padding: 5px 2px;
}

.ac {
  text-align: center;
}

.vam {
  vertical-align: middle;
}

div[id^=purchase].forabg table.sp thead tr {
  background: $optwear-blue-gradient;

  th {
    padding: 10px 0;
  }
}

.cart_del {
  //background: url(./images/lib/sp/cart_del.png) no-repeat center center;
  cursor: pointer;
  color: $optwear-red;

  svg {
    @include u.square(u.size('unit-2x'))
  }

  display: flex;
  align-items: center;
  justify-content: center;
  @include u.square(u.size('unit-3x'));
  margin: 0 auto;
}

.cart_sale {
  //background: url('~@Assets/imageset-old/images-old-optwear/sp/sale.gif') no-repeat center center;
  //@include u.square(u.size('unit-3x'));
  //background-size: contain;
  //display: block;
  //margin: 0 auto;
}

.cart_pr {
  //background: url('~@Assets/imageset-old/images-old-optwear/sp/pr.png') no-repeat center center;
  //cursor: pointer;
  //@include u.square(u.size('unit-3x'));
  //background-size: contain;
  //display: block;
  //margin: 0 auto;
}


#tab2 .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
  color: $optwear-white;
}

#tab2 .ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited,
#tab2 .ui-state-hover a, .ui-state-hover a:link, .ui-state-hover a:visited {
  color: $optwear-blue-strong;
}

.NavLineItems.ui-tabs-nav li a {
  padding: 0 5px;
  font-size: 15px;
}

#sp_seting .content.vat {
  margin: 0 auto;
  @include u.below('xs') {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#sp_seting h3 {
  text-align: center;
}


.sp-hints {

  &-top {
    margin: u.size('unit-2x') 0 u.size('unit-4x') 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include u.below('xs') {
      align-items: center;
    }

    &-list {
      width: 100%;
      display: flex;
      list-style: none;
      align-items: flex-start;
      padding: 0;
      margin: 0;
      @include u.below('xs') {
        flex-direction: column;
        align-items: center;
      }

      &-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        @include u.below('lg') {
          flex-direction: column;
          text-align: center;
        }
        @include u.below('xs') {
          margin: 0 0 u.size('unit-2x');
        }

        &-label {
          width: u.size('unit-6x');
          height: u.size('unit-4x');
          border-radius: u.size('unit');
          margin: 0 u.size('unit-half') 0 0;
          @include u.below('lg') {
            margin: 0 0 u.size('unit-half');
          }
        }
      }
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: u.size('unit-2x') 0;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: u.size('unit');
      background-color: color.scale(#f4f4f4, $lightness: -5%);
      border-radius: u.size('unit-half');
      @include u.below('xs') {
        align-items: center;
        text-align: center;
      }


      &:not(:last-child) {
        margin: 0 0 u.size('unit-2x');
      }

      &-label {
        display: block;
        font-weight: 500;
        padding: u.size('unit-half') u.size('unit');
        border-radius: u.size('unit');
        margin: 0 u.size('unit-half') u.size('unit') 0;
        box-sizing: border-box;
      }
    }
  }

  &-reset {
    color: $optwear-red;
  }
}
