@import "@Scss/old/common/_variables.scss";
.share {
	display: flex;
	font-size: 0;
	margin-bottom: 10px;
	max-width: 350px;
	flex-wrap: wrap;
	justify-content: space-between;
	svg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		content: "";
	}
	&__link {
		max-width: 56px;
		margin-bottom: 10px;
		position: relative;
		display: inline-block;
		vertical-align: top;
		flex-shrink: 0;
		flex-grow: 1;
		cursor: pointer;
		width: 70px;
		height: 40px;
		border-radius: 3px;
		overflow: hidden;
		background: #f6f5f3;
		//margin-left: 8px;
		//&:first-child {
		//	margin-left: 0;
		//}
		svg use {
			fill: #cbcaca;
		}
		&:hover svg use {
			fill: $optwear-white !important;
		}
		&_wa {
			&:hover {
				background-color: #49cc58;
			}
		}
		&_vi {
			&:hover {
				background-color: #7941ac;
			}
		}
		&_tg {
			&:hover {
				background-color: #64a9dc;
			}
		}
		&_ok {
			&:hover {
				background-color: #ee8208;
			}
		}
		&_vk {
			&:hover {
				background-color: #4680c2;
			}
		}
	}
}
