@use "src/assets/scss/utils" as u;

%slider-btn {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 100%;
  border: none;
  background: rgba(#fff, 0.2);
  color: #333;
  font-size: 36px;
  outline: none;
  cursor: pointer;
  z-index: u.z-index('slider-button');
  transition: opacity .25s ease-in-out;
  @include u.below('lg') {
    font-size: 24px;
  }

  & > svg {
    width: 32px;
    height: 32px;
  }
}


.index-top {
  overflow: hidden;
  margin: 0 0 30px;

  &-tabs {
    grid-column: full;
    position: relative;
  }

  &-tab {
    opacity: 0;
    transition: opacity .5s ease-in-out;

    &-active {
      opacity: 1;
    }
  }

  @include b(nav) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    @include u.below('md') {
      flex: initial;
      width: 100%;
      margin: 12px 0 24px;
    }
    @include b(list) {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      @include u.below('md') {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0 u.size('unit');
      }
      @include b(item) {
        display: flex;
        align-items: center;
        font-size: u.size('text-md');

        @include u.below('lg') {
          font-size: u.size('text-sm');
        }
        @include u.below('md') {
          flex: 1;
          justify-content: center;
          font-size: u.size('text-md');
          background-color: u.var-color('grey-5');
          border: 1px solid u.var-color('grey-50');
          border-radius: u.size('unit');
        }
        @include u.below('sm') {
          flex-direction: column;
          align-items: center;
        }
        @include u.below('xs') {
          font-size: u.size('text-sm');
        }
        @include m(active) {
          @include u.below('md') {
            background-color: u.var-color('primary');
            border-color: u.var-color('primary');
          }
          > a {
            color: $optwear-orange-strong;
            @include u.below('md') {
              color: #fff;
            }
            pointer-events: none;
          }
        }
        &__link {
          &-status {
            margin: 0 8px 0 0;

            &:before {
              width: u.size('unit-3x') !important;
              height: u.size('unit-3x') !important;
              margin: 0 !important;
            }

            @include u.below('xs') {
              margin: 0 0 u.size('unit');
            }
          }

          user-select: none;
          display: flex;
          color: #004d97;

          @include u.below('md') {
            color: u.color('text');
          }

          align-items: center;
          justify-content: center;

          @include u.above('xs') {
            white-space: nowrap;
          }
          @include u.below('md') {
            @include u.mixins-text-height(u.size('unit-5x'));
            flex: 1;
            text-align: center;
          }
          @include u.below('sm') {
            line-height: initial;
            min-height: u.size('unit-5x');
            width: 100%;
          }
          @include u.below('xs') {
            flex-direction: column;
          }
          @include u.mixins-text-height(u.size('unit-3x'));
          transition: color .25s ease-in-out;
          @include u.mixins-hover {
            &:not(.index-top-nav-list-item__link--active) {
              color: $optwear-orange-middle;
            }
          }


        }

        @include e(icon) {
          margin: 0 16px 0 0;
          font-size: 24px;
          display: flex;
          align-items: center;
          @include u.below('lg') {
            font-size: 20px;
            margin: 0 8px 0 0;
          }
          @include u.below('md') {
            font-size: 24px;
            margin: 0 16px 0 0;
          }
          @include u.below('xs') {
            margin: 0;
          }
        }
      }
    }
    @include e(hr) {
      width: 100%;
      color: #333;
      margin: u.size('unit-half') 0;
    }
  }

  @include b(slider) {
    display: flex;
    align-items: center;
    margin: 0 0 0 32px;
    @include u.below('lg') {
      margin: 0 0 0 16px;
    }
    @include u.below('md') {
      margin: initial;
      flex: initial;
      width: 100%;
    }
    @include b(container) {
      position: relative;
      width: 750px;
      overflow: hidden;
      box-shadow: 0 0 15px 0 rgba(#dedede, .8);
      @include u.below('lg') {
        width: 690px;
      }
      @include u.below('md') {
        width: 100%;
      }
      &:hover {
        & > .index-top-slider-container__btn-next, & > .index-top-slider-container__btn-prev {
          opacity: 1;
        }
      }

      @include e(wrapper) {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        box-sizing: content-box;
        z-index: u.z-index('default');
        transition-property: transform;
      }
      @include e(btn-prev) {
        left: 0;
        @extend %slider-btn;
      }
      @include e(btn-next) {
        right: 0;
        @extend %slider-btn;
      }
      @include b(item) {
        height: 0;
        padding: 37.5% 0 0;
        @include e(image) {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      @include b(pagination) {
        position: absolute;
        left: 50% !important;
        bottom: 12px;
        display: flex;
        justify-content: space-around;
        width: 80%;
        z-index: u.z-index('slider-button');
        @include u.below('lg') {
          bottom: 6px;
        }
        transform: translate(-50%, 0);
        @include e(bullet) {
          display: inline-block;
          width: 12px;
          height: 12px;
          @include u.below('lg') {
            width: 6px;
            height: 6px;
          }
          background: rgba(#333, .45);
          cursor: pointer;
          border-radius: 100%;
          transition: background .25s ease-in-out;
          @include m(active) {
            background: #333;
          }
        }
      }
    }

  }
  @include e(content) {
    flex-direction: row;
    align-items: stretch;
    padding: 16px;
    border-width: 1px;
    border-style: solid;
    border-color: #dedede;
    box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    @include u.below('md') {
      flex-direction: column;
    }
    @include u.below('sm') {
      padding: 8px;
    }
  }
}

