@use "src/assets/scss/utils" as u;

.ucp-zebra {
  order: 0;
  margin: 0;
  @include u.below('md') {
    overflow: initial;
  }

  &__user {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    &--add {
      padding: 0 32px 0 8px;
      @include u.below('md') {
        padding: 0 24px 0 0;
      }
    }

    a {
      @include u.below('md') {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @include u.above('md') {
      line-height: 24px;
      padding: 0 8px;
      font-size: 13px;
    }

    &-btn {
      position: absolute;
      @include u.mixins-absolute-center-vertical;
      right: 0;
      padding: 0;
      display: flex;
      align-items: center;
      background: none;
      outline: none;
      border: none;
      margin: 0 8px 0 0;
      @include u.below('md') {
        margin: 0;
      }

      svg {
        @include u.square(u.size('unit-2x'))
      }
    }

    &_online .username-coloured {
      position: relative;

      &:after {
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        content: " ";
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: #46b946;
      }
    }
  }

  &__users {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .ucp-navbar__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      background-color: $optwear-orange-light;
      content: " ";
      display: block;
      //position: absolute;
      //left: 83px;
      //top: calc(50% - 7px);
      border-radius: 50%;
      margin: 0 10px 0 0;
      width: 10px;
      height: 10px;
    }

  }
}
