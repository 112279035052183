@use "src/assets/scss/utils" as u;

%slider-btn-purchases-widget {
  width: 64px;
  background: rgba(#fff, 0.2);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 36px;
  color: #333;
  z-index: u.z-index('slider-button');
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: color .25s ease-in-out;

  &.swiper-button-disabled {
    color: grey;
    opacity: .75;
  }

  @include u.below('sm') {
    display: none;
  }
}

.index-purchases-widget {
  $grey: #e7e7e7;
  $item-height: 500px;
  width: 100%;
  margin: 0 0 30px;
  @include wrapper;

  &:not(.index-purchases-widget--init) {
    .index-purchases-widget-tabs {
      a {
        pointer-events: none;
        color: grey !important;
      }
    }
  }

  @include e(content) {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #dedede;
    @include u.below('md') {
      padding: 8px;
    }
  }
  @include b(skeletons) {
    display: flex;
    width: 100%;
    background: #fff;
    position: relative;
    height: 100%;
    @include e(item) {
      &:nth-child(2) {
        @include u.below('sm') {
          display: none;
        }
      }
      &:nth-child(3) {
        @include u.below('md') {
          display: none;
        }
      }
      overflow: hidden;
      position: relative;
      &:not(:last-child) {
        margin: 0 16px 0 0;
        @include u.below('md') {
          margin: 0 8px 0 0;
        }
        @include u.below('sm') {
          margin: 0;
        }
      }
      height: 100%;
      flex: 1;
      background-image: radial-gradient(circle 30px, $grey 99%, transparent 0),
      linear-gradient(#fff 15px, transparent 0),
      linear-gradient($grey 50px, transparent 0),
      linear-gradient($grey 350px, transparent 0),
      linear-gradient(to right, $grey 17.75%, #fff 17.75%, $grey 18.75%, $grey 36.5%, #fff 36.5%, $grey 37.5%, $grey 55.25%, #fff 55.25%, $grey 56.25%, $grey 74%, #fff 74%, $grey 75%, $grey 100%);
      background-size: 60px 60px,
      100% 10px,
      100% 50px,
      100% 350px,
      100% 70px;
      background-position: 0 0,
      75px 20px,
      75px 0,
      0 70px,
      0 430px;
      background-repeat: no-repeat;
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(100deg, rgba(#fff, 0), rgba(#fff, .3) 50%, rgba(#fff, 0) 80%);
        background-size: 50px 100%;
        background-position: -125% 0;
        background-repeat: no-repeat;
        animation: skeleton-shine 1s infinite;
      }
    }
  }
  @include e(lists) {
    width: 100%;
    overflow: hidden;
    height: $item-height;
    position: relative;
    @include u.below('md') {
      height: 516px;
    }
  }
  @include b(list) {
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    visibility: hidden;
    position: absolute !important;
    top: 0;
    left: 0;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    @include m(active) {
      opacity: 1;
      visibility: visible;
    }
    flex-wrap: nowrap;
    overflow: hidden;
    @include e(btn-next) {
      @extend %slider-btn-purchases-widget;
      right: 0;
    }
    @include e(btn-prev) {
      @extend %slider-btn-purchases-widget;
      left: 0;
    }
    @include b(item) {
      display: flex;
      flex-direction: column;
      height: $item-height;
      overflow: hidden;
      &:last-of-type {
        margin-left: -3px;
      }
      @include b(header) {
        display: flex;
        width: 100%;
        margin: 0 0 24px;
        height: 64px;
        @include u.below('md') {
          margin: 0 0 16px;
        }
        @include u.below('xs') {
          margin: 0 0 8px;
        }
        @include e(avatar) {
          width: 64px;
          height: 64px;
          @include u.below('md') {
            width: 48px;
            height: 48px;
          }
          @include m(empty) {
            background-color: $grey;
          }
          display: inline;
          border-radius: 100%;
          background-size: cover;
          background-position: center center;
          margin: 0 16px 0 0;
          transition: opacity .25s ease-in-out;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: opacity .25s ease-in-out;
            background: rgba(#fff, .125);
            opacity: 0;
            z-index: u.z-index('default');
          }
          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
        @include b(title) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;
          margin: 0;
          min-width: 0;
          @include e(organizer) {
            color: $optwear-blue-light;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 600;
            font-size: 13px;
            transition: color .25s ease-in-out;
            margin: 0 0 8px;
            &:hover {
              color: $optwear-blue-strong;
            }
          }
          @include e(text) {
            color: #333;
            line-height: 1.25;
            max-height: 40px;
            font-size: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            transition: color .25s ease-in-out;
            &:hover {
              color: $optwear-orange-strong;
            }
          }
        }
      }
      @include b(content) {
        width: 100%;
      }
      @include b(link) {
        display: flex;
        position: relative;
        width: 100%;
        height: 350px;
        @include u.below('md') {
          height: 344px;
        }
        &:hover {
          &:after {
            opacity: 1;
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          transition: opacity .25s ease-in-out;
          background: rgba(#fff, .125);
          opacity: 0;
          z-index: u.z-index('default');
        }
        @include e(image) {
          position: absolute;
          max-width: 100%;
          max-height: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        @include e(price) {
          z-index: u.z-index('default');
          position: absolute;
          top: 15px;
          left: 0;
          pointer-events: none;
          background-color: $optwear-orange-strong;
          border-radius: 2px;
          color: #fff;
          font-size: 13px;
          font-weight: 700;
          line-height: 32px;
          height: 32px;
          padding: 0 16px;
        }
      }
      @include b(lots) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @include b(lot) {
          width: 17.75%;
          margin: 0 1% 0 0;
          height: 100%;
          &:hover {
            &:after {
              opacity: 1;
            }
          }
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: opacity .25s ease-in-out;
            background: rgba(#fff, .125);
            opacity: 0;
            z-index: u.z-index('default');
          }
          position: relative;
          @include e(image) {
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      @include b(catalog) {
        width: 25%;
        transition: border-color .25s ease-in-out, color .25s ease-in-out;
        border-style: solid;
        border-width: 1px;
        border-color: currentColor;
        color: $optwear-blue-light;
        font-size: 14px;
        box-sizing: border-box;
        border-radius: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 5px;
        &:hover {
          color: $optwear-orange-strong;
        }
        @include e(counter) {
          font-weight: 700;
          font-size: 18px;
        }
      }
      @include b(footer) {
        width: 100%;
        height: 70px;
        margin: 10px 0 0;
        display: flex;
        @include u.below('md') {
          margin: 16px 0 0;
          height: 64px;
        }
      }
    }
  }
  @include b(tabs) {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 0 0 18px;
    margin: 0 0 16px;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #dedede;
    @include u.below('md') {
      flex-direction: column;
      align-items: initial;
      padding: 0 0 10px;
    }
    @include e(title) {
      display: flex;
      align-items: baseline;
      margin: 0 36px 0 0;
      font-size: 20px;
      @include u.below('md') {
        margin: 0 0 32px;
        font-size: 24px;
      }
    }
    @include e(all) {
      font-weight: 700;
      font-size: 13px;
      color: $optwear-blue-light;
      transition: color .25s ease-in-out;
      margin: 0 0 0 auto;
      @include hover-supported {
        color: $optwear-blue-strong;
      }
    }
    @include b(list) {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      @include b(item) {
        transition: color .25s ease-in-out;
        &:not(:last-child) {
          margin: 0 16px 0 0;
        }
        @include e(link) {
          position: relative;
          padding: 0 0 18px;
          @include u.below('md') {
            padding: 0 0 9px;
          }
          font-size: 13px;
          color: #434343;
          transition: color .2s ease-in-out, border-color .2s ease-in-out;
          text-decoration: none;
          border-width: 0 0 3px;
          border-color: transparent;
          border-style: solid;
          &:not(.index-purchases-widget-tabs-list-item__link--active):not(.index-purchases-widget-tabs-list-item__link--disable):hover {
            color: $optwear-orange-strong;
            border-color: currentColor;
          }
          @include m(active) {
            color: $optwear-orange-strong;
            border-color: currentColor;
            font-weight: 700;
          }
          @include m(disable) {
            color: lighten(#434343, 30%);
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.js-index-widget-purchases-child {
  margin: 0;
}
