@import "@Scss/old/common/_variables.scss";
.contact-us {
	font-family: $second-font-family;
	&__inner {
		display: flex;
		justify-content: space-between;
		//max-width: 320px;
		margin: 0 auto;
		align-items: center;
	}
	&__icon {
		//margin-right: 20px;
		svg path,
		svg circle {
			fill: $color-background;
		}
	}
		&__label {
				text-align: center;
		}
}
///
