@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.about-teaser {
  width: 580px;
  padding: 25px 0 20px 0;

  box-sizing: content-box;
  margin: 0 auto;
  @include u.below('md') {
    padding: u.size('unit-2x') 0;
  }
  @include u.below('sm') {
    padding: u.size('unit');
    width: 100%;
  }

  &__container {
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #FFDA89;
    @include u.below('md') {
      padding: 0;
    }
  }

  &__icon {
    position: absolute;
    left: -119px;
    top: -34px;

    path, circle {
      fill: #fff;
    }

    @include u.below('md') {
      display: none;
    }
  }

  .teaser-descr {
    margin: 5px 0 10px;
    font-size: 15px;
    line-height: 21px;
    color: $color-text-weak;
    position: relative;
  }

  .close-btn {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    font-size: 12px;
    color: $color-text-weak;
    border-bottom: 1px dashed $color-text-weak;
    cursor: pointer;
  }
}

