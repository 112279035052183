@use "src/assets/scss/utils" as u;

.index-features {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  background: $optwear-blue-gradient;
  @include wrapper;
  @include e(content) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include e(title) {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    margin: 0 0 24px;
  }
  @include b(list) {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    width: 100%;
    @include u.below('xs') {
      flex-direction: column;
    }
    @include b(item) {
      flex: 1;
      display: flex;
      align-items: center;
      @include u.below('sm') {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      &:not(:last-child) {
        margin: 0 16px 0 0;
        @include u.below('sm') {
          margin: 0 8px 0 0;
        }
        @include u.below('xs') {
          margin: 0 0 16px;
        }
      }
      justify-content: center;
      @include b(heading) {
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 13px;
        color: #fff;
        @include e(title) {
          font-weight: 700;
          margin: 0 0 6px;
        }
        @include e(link) {
          color: $optwear-orange-light;
        }
      }
      @include e(icon) {
        font-size: 32px;
        margin: 0 16px 0 0;
        color: #fff;
        @include u.below('sm') {
          margin: 0 0 8px;
        }
        @include u.below('xs') {
          font-size: 40px;
        }
      }
    }
  }
}
