.catalog {
	button.breadcrumbs-item {
		padding: 0 5px;
		border: 1px solid #ccc;
	}

	.breadcrumbs li {
		text-transform: unset;
	}

	.breadcrumbs-search {
		margin: 0;
		padding: 0;
		height: 26px;
		width: 170px;
		line-height: 20px;
		font-size: 12px;
		padding-left: 10px;
	}

	.breadcrumbs {
		display: flex;
		align-items: center;
	}

	.breadcrumbs li:not(:last-child)::after {
		margin: 0 0.35rem;
	}
}
