/* Interaction Cues
---------------------------------- */
.ui-state-disabled {
	cursor: default !important;
}

/* Misc visuals
---------------------------------- */

/*  Overlays  */
.ui-widget-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Interaction states */
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	background: transparent;
	color: $color-text;
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
	color: #2e6e9e;
	text-decoration: none;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
	background: #edf0f5;
}

.ui-state-hover a, .ui-state-hover a:hover {
	color: #1d5987;
	text-decoration: none;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
	font-weight: 700;
	background: #edf0f5;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
	color: #e17009;
	text-decoration: none;
}

.ui-widget :active {
	outline: none;
}

/* Interaction Cues
---------------------------------- */
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
	background-color: #f2f6fa;
	border: 1px solid #98b0cd;
	color:$color-text;
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
	color: #363636;
}

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
	border: 1px solid #cd0a0a;
	background: #fef1ec url('~@Assets/imageset-old/images-old/lib/jquery-ui/ui-bg_glass_95_fef1ec_1x400.png') 50% 50% repeat-x;
	color: #cd0a0a;
}

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
	color: #cd0a0a;
}

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
	color: #cd0a0a;
}

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
	font-weight: bold;
}

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
	opacity: .7;
	filter: Alpha(Opacity=70);
	font-weight: normal;
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
	opacity: .35;
	filter: Alpha(Opacity=35);
	background-image: none;
}
