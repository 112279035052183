.pristroy-edit-top {
	display: flex;
	align-items: center;
	&__header {
		width: 977px;
		margin-bottom: 10px;
	}
	&__label {
		font-size: 15px;
		line-height: 18px;
		color: #858585;
	}
	&__image {
		flex-grow: 2;
	}
}
