$shadow-color:#e6e6e6;
.lot-row {
	position: relative;
	display: flex;
	width: calc((100% / 2) - 10px);
	margin-right: 10px;
	flex-direction: column;
	overflow: hidden;

	&:nth-child(2n) {
		margin-right: 0;
	}

	margin-bottom: 10px;
	box-shadow:
		/* правая грань - Бордер*/ -1px 0 0 0 $shadow-color inset,
		/* нижняя грань - Бордер*/ 0 -1px 0 0 $shadow-color inset,
		/* верхняя и левая грань*/ -1px -1px 0 0 $shadow-color,
		-1px 0 0 0 $shadow-color,
		0 -1px 0 0 $shadow-color;
	transition: box-shadow 0.25s linear;
	&:hover, &-hover_active {
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.13), -1px -1px 0 0 $shadow-color;
		transition: box-shadow 0.25s linear;
	}
	& .lot__tag-group {
		left: 10px;
		max-width: 100px;
		overflow: hidden;
	}
	& .lot-image {
		min-width: 200px;
		max-width: 200px;
	}

	& .lot-card-descItem {
		overflow: hidden;
	}

	.lot-card-descItemPropItemValueList__showAll {
		box-shadow: 0px 0px 4px 13px white;
	}

	&-header {
		border-bottom: 1px solid $color-background;
		padding-top: 5px;
		padding-left: 10px;

		.lot-title {
			margin: 0 30px 0 0;
		}
	}

	&__body {
		padding: 10px;
		display: flex;
		word-wrap: break-word;
		height: 100%;
	}
	&__desc {
		font-size: 15px;
		line-height: 20px;
		max-height: 300px;
		list-style: none;
		margin-left: 0;
		word-break: break-word;
		flex-grow: 2;
	}

	&__fav-btn {
		top: 0;
		position: absolute;
		right: 0;

		.lot-user-form__add-to-favorites {
			border: none;
			width: 30px;
			background-size: 20px;
			height: 30px;
			margin: 0;
			float: right;
			opacity: 0;
		}
	}

	&:hover, &:focus-within {
		.lot-user-form__add-to-favorites {
			opacity: 1;
		}
	}
	&__top {
		margin-bottom: 17px;
	}

	&-orgFee {
		white-space: nowrap;
		color: #ccc;
		text-align: left;
	}

	&-mainPrice {
		font-weight: 400;
		font-size: 25px;
		text-align: left;
		line-height: 21px;
		color: #202020;
		white-space: nowrap;
		margin: 0;
	}

	&-price {
		flex-grow: 2;
		text-align: left;
		@media (min-width: 1300px) {
			max-width: 150px;
		}
		@media (min-width: 1600px) {
			max-width: 270px;
		}

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 5px;
	}

	&-addOrder__container {
		justify-content: flex-end;
		display: flex;
		align-items: center;
	}
	&__main-price {
		margin-bottom: 2px;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		color: $color-text;
	}

	.lot-card-addOrder {
		&__container {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		&__small-btn {
			font-size: 20px !important;
		}


		&__amount {
			margin-right: 20px;

			.lot-user-form-quantity {
				min-width: 95px;
			}
		}

		&__btn {
			padding: 0 15px;
			background-color: transparent;
			border-color: #2bc253;
			color: #2bc253 !important;
			width: 135px;

			&:hover {
				background-color: #72bf44;
				color: #fff;
				border-color: transparent;
			}
		}
	}

	.lot-card__fav-btn {
		position: absolute;
		right: 0;

		.lot-user-form__add-to-favorites {
			border: none;
			width: 30px;
			background-size: 20px;
			height: 30px;
			margin: 0;
			float: right;
			opacity: 0;

		}

		.lot-user-form__add-to-favorites_active {
			opacity: 1;
		}
	}

	.lotCard:hover .lot-card__fav-btn .lot-user-form__add-to-favorites,
	.lot-card:hover .lot-card__fav-btn .lot-user-form__add-to-favorites {
		opacity: 1;
	}

	.openByLink {
		border: 2px solid #1313ec !important;
	}

	.showFull {
		color: $color-text-link;
	}
}

.lotListList {
	margin: 0;
	padding-left: 0;
	list-style-type: none;
}
@import "lot-row-part";
