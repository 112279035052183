.lot-summary-large-description {
	&__container {
		background: #fff;
		padding: 15px;
		border: 1px solid #ccc;
		border-radius: 5px;

		p {
			font-size: 0.925rem;
			line-height: 1.225rem;
		}
	}
}
