.calendar-tape {
	text-align: center;
	&__hour {
		border-bottom: 1px solid $optwear-blue-light;
		&_active {
			background-color: $optwear-blue-light;
			color: $optwear-white;
		}
	}
	&__event {
		margin: 2px 0;
		$colors: "FF7D7D" "BDEE2E" "FFF088" "F1C2A9" "F29351" "BDD0EF";
		@each $color in $colors {
			$i: index($colors, $color);
			&_t_#{$i} {
				background-color: unquote("#"+$color);
			}
		}
	}
}
