@use "src/assets/scss/utils" as u;

@keyframes show-index-categories-img-item {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.index-categories-img {
  width: 100%;
  margin: 0 0 32px;
  @include u.below('md') {
    margin: 0 0 40px;
  }

  &:not(.index-categories-img--init) {
    .index-categories-img-nav {
      a, .index-categories-img-nav-header-scopes-tabs-item--man, .index-categories-img-nav-header-scopes-tabs-item--woman {
        pointer-events: none;
        color: grey !important;
      }
    }
  }

  @include b(nav) {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include b(header) {
      display: flex;
      width: 100%;
      padding: 0 0 18px;
      margin: 0 0 16px;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: #dedede;
      flex-direction: column;

      @include u.below('sm') {

        padding: 0 0 10px;
      }
      @include b(scopes) {
        width: 100%;
        margin: u.size('unit-2x') 0;
        @include b(tabs) {
          padding: 0;
          margin: 0;
          list-style: none;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 0 u.size('unit');
          @include b(item) {
            color: #434343;
            background-color: u.var-color('grey-5');
            border: 1px solid u.var-color('grey-50');
            border-radius: u.size('unit');
            flex: 1;
            text-align: center;
            transition: background-color .25s ease-in-out, border-color .25s ease-in-out;
            @include m(active) {
              font-weight: 700;
              color: #fff;
              &.index-categories-img-nav-header-scopes-tabs-item--woman {
                border-color: #e085a3;
                background-color: #e085a3;
              }
              &.index-categories-img-nav-header-scopes-tabs-item--man {
                border-color: #4590cd;
                background-color: #4590cd;
              }
            }
            @include e(link) {
              cursor: pointer;
              user-select: none;
              position: relative;
              font-size: 17px;

              transition: color .25s ease-in-out;
              text-decoration: none;
              border-width: 0 0 3px;
              border-color: transparent;
              border-style: solid;
              @include u.mixins-text-height(u.size('unit-5x'));
              flex: 1;
              @include u.below('xs') {
                font-size: 13px;
              }

            }
          }
        }
      }
      @include e(blocks) {
        display: flex;
        flex: 1;
        position: relative;
        @include u.below('sm') {
          width: 100%;
          height: 26px;
          flex: auto;
        }
      }
      @include b(block) {
        display: flex;
        align-items: flex-end;
        width: 50%;
        &:first-child {
          margin: 0 12px 0 0;
        }
        transition: opacity .25s ease-in-out, visibility .25s ease-in-out;

        @include u.below('sm') {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
        }
        @include b(tabs) {
          display: flex;
          padding: 0;
          margin: 0;
          list-style: none;
          @include b(item) {
            &:not(:last-child) {
              margin: 0 16px 0 0;
            }
            @include e(link) {
              position: relative;
              padding: 0 0 18px;
              font-size: 13px;
              color: #434343;
              transition: color .25s ease-in-out, border-color .25s ease-in-out;
              text-decoration: none;
              border-width: 0 0 3px;
              border-color: transparent;
              border-style: solid;
              &:not(.index-categories-img-nav-header-block-tabs-item__link--active) {
                @include hover-supported {
                  color: $optwear-orange-middle;
                  border-color: currentColor;
                }
              }
              @include u.below('sm') {
                padding: 0 0 9px;
              }
              @include m(active) {
                color: $optwear-orange-strong;
                border-color: currentColor;
                font-weight: 700;
              }
            }
          }
        }
      }

      @include e(title) {
        margin: 0 0 u.size('unit-2x');
        font-size: 20px;
        font-weight: 700;
        @include u.below('sm') {
          margin: 0;
          font-size: 24px;
        }
      }
    }
    @include e(blocks) {
      display: flex;
      width: 100%;
      position: relative;
      height: 560px;
      @include u.below('lg') {
        height: 480px;
      }
      @include u.below('md') {
        height: 400px;
      }
    }
  }
  @include b(block) {
    width: 50%;
    background: #ebebeb;
    height: 100%;
    position: relative;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    @include u.below('sm') {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
    }
    &:first-child {
      margin: 0 12px 0 0;
      @include u.below('sm') {
        margin: 0;
      }
    }
    @include b(content) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
      opacity: 0;
      visibility: hidden;
      @include m(active) {
        opacity: 1;
        visibility: visible;
        z-index: u.z-index('default');
      }
      @include b(list) {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        list-style: none;
        @include b(item) {
          display: flex;
          overflow: hidden;
          border-radius: 5px;
          //animation: show-index-categories-img-item 1s ease-in-out backwards; //TODO показать Жене
          @include hover-supported {
            & .index-categories-img-block-content-list-item__picture {
              transform: scale(1.15);
            }

            & .index-categories-img-block-content-list-item__title {
              font-weight: 700;
            }
          }

          @include m(woman-boots) {
            width: 45%;
            height: calc(25% - 6px);
            background: #e085a3;
            animation-delay: .3s;
            order: 2;
            @include u.below('md') {
              width: calc(45% - 6px);
              height: 25%;
              margin: 0 0 6px;
              order: 3;
            }
            @include u.below('sm') {
              width: calc(50% - 6px);
            }
            @include u.below('xs') {
              width: calc(55% - 6px);
            }
          }
          @include m(man-boots) {
            width: 50%;
            height: calc(50% - 6px);
            background: #4590cd;
            animation-delay: .3s;
            @include u.below('lg') {
              width: 50%;
            }
          }
          @include e(label) {
            position: absolute;
            left: 6px;
            bottom: 6px;
            height: 30px;
            padding: 0 10px;
            background: rgba(#333, .7);
            color: #fff;
            font-size: 13px;
            line-height: 30px;
            border-radius: 5px;
            user-select: none;
          }
          @include e(title) {
            font-size: 20px;
            text-transform: uppercase;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: font-weight .25s ease-in-out;
          }
          @include m(full-height) {
            & .index-categories-img-block-content-list-item__img {
              max-width: initial;
              height: 100%;
            }
          }
          @include m(right) {
            & .index-categories-img-block-content-list-item__img {
              transform: none;
              top: auto;
              left: auto;
              bottom: 0;
              right: 0;
            }
          }

          @include m(offset) {
            transform: translate(-100%, 0);
          }

          @include m(woman-sweater) {
            width: 45%;
            height: 75%;
            margin: 0 6px 6px 0;
            background: #e085a3;
            animation-delay: .15s;
            order: 1;
            @include u.below('md') {
              width: 55%;
              height: 60%;
            }
            @include u.below('sm') {
              width: 50%;
            }
            @include u.below('xs') {
              width: 45%;
            }
          }
          @include m(girl-sweater) {
            width: 45%;
            height: calc(75% - 6px);
            margin: 0 6px 6px 0;
            background: #ecb2c5;
            order: 1;
          }
          @include m(girl-boots) {
            width: 45%;
            height: 25%;
            margin: 0 6px 0 0;
            background: #ecb2c5;
            order: 2;
          }
          @include m(girl-linen) {
            width: calc(27.5% - 6px);
            height: 25%;
            margin: 0 6px 6px 0;
            background: #ecb2c5;
            order: 3;
            @include u.below('xs') {
              height: 25%;
              width: calc(55% - 6px);
              margin: 0 0 6px;
            }
          }
          @include m(girl-jacket) {
            width: calc(55% - 6px);
            height: calc(75% - 6px);
            background: #ecb2c5;
            order: 4;
            @include u.below('xs') {
              height: calc(50% - 12px);
              margin: 0 0 6px;
            }
          }
          @include m(girl-hats) {
            width: calc(27.5% - 6px);
            height: 25%;
            margin: 0 0 6px;
            background: #ecb2c5;
            order: 5;
            @include u.below('xs') {
              height: 25%;
              width: calc(55% - 6px);
              margin: 0;
              transform: none;
            }
          }

          @include m(boy-jacket) {
            width: 45%;
            height: calc(70% - 6px);
            background: lighten(#4590cd, 20%);
            order: 1;
            margin: 0 6px 6px 0;
            @include u.below('xs') {
              height: calc(50% - 12px);
              width: 50%;
            }
          }
          @include m(boy-boots) {
            width: 45%;
            height: 30%;
            margin: 0 6px 0 0;
            background: lighten(#4590cd, 20%);
            order: 2;
            @include u.below('xs') {
              height: 25%;
              width: 50%;
              margin: 0 6px 6px 0;
            }
          }
          @include m(boy-linen) {
            width: calc(27.5% - 6px);
            height: 25%;
            margin: 0 6px 6px 0;
            background: lighten(#4590cd, 20%);
            order: 3;
            @include u.below('xs') {
              height: 25%;
              width: 50%;
              margin: 0 6px 0 0;
            }
          }
          @include m(boy-sweater) {
            width: calc(55% - 6px);
            height: calc(75% - 6px);
            background: lighten(#4590cd, 20%);
            order: 4;
            @include u.below('xs') {
              margin: 0 0 6px;
              width: calc(50% - 6px);
            }
          }
          @include m(boy-hats) {
            width: calc(27.5% - 6px);
            height: 25%;
            margin: 0 0 6px;
            background: lighten(#4590cd, 20%);
            order: 5;
            @include u.below('xs') {
              height: 25%;
              width: calc(50% - 6px);
              margin: 0;
              transform: none;
            }
          }

          @include m(woman-cosmetics) {
            width: calc(27.5% - 6px);
            height: calc(35% - 6px);
            margin: 0 6px 6px 0;
            background: #e085a3;
            animation-delay: .45s;
            order: 3;
            @include u.below('md') {
              width: 55%;
              height: calc(40% - 6px);
              order: 2;
              margin: 0;
            }
            @include u.below('sm') {
              width: 50%;
            }
            @include u.below('xs') {
              width: 45%;
            }
          }
          @include m(woman-jacket) {
            width: calc(55% - 6px);
            height: 65%;
            background: #e085a3;
            animation-delay: .75s;
            order: 4;
            @include u.below('md') {
              width: calc(45% - 6px);
              height: calc(50% - 12px);
              margin: 0 0 6px;
            }
            @include u.below('sm') {
              width: calc(50% - 6px);
            }
            @include u.below('xs') {
              width: calc(55% - 6px);
            }
          }
          @include m(woman-accessories) {
            width: calc(27.5% - 6px);
            height: calc(35% - 6px);
            margin: 0 0 6px;
            background: #e085a3;
            animation-delay: .6s;
            order: 5;
            @include u.below('md') {
              width: calc(45% - 6px);
              height: 25%;
              margin: 0;
              transform: none;
            }
            @include u.below('sm') {
              width: calc(50% - 6px);
            }
            @include u.below('xs') {
              width: calc(55% - 6px);
            }
          }
          @include m(man-jacket) {
            width: 50%;
            height: 50%;
            margin: 0 6px 6px 0;
            background: #4590cd;
            animation-delay: .15s;
            @include u.below('lg') {
              width: 50%;
            }
          }
          @include m(man-sweater) {
            width: calc(50% - 6px);
            height: 100%;
            background: #4590cd;
            animation-delay: .45s;
            @include u.below('lg') {
              width: calc(50% - 6px);
            }
          }

          @include e(link) {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
          }
          @include e(picture) {
            position: relative;
            width: 100%;
            height: 100%;
            transition: transform .25s ease-in-out;
          }
          @include e(img) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}

.index-categories-img-nav-header-scopes {
  @include m(active) {
    @include u.below('sm') {
      opacity: 1;
      visibility: visible;
      z-index: u.z-index('default');
    }
  }
}
