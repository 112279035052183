.pristroy-edit-contacts {
	width: 75%;
	border: 3px solid $optwear-blue-strong;
	background-color: #f7fcff;
	padding: 17px 22px 22px;
	&__row {
		display: flex;
		font-size: 16px;
		margin: 20px 0; ///
	}
	&__label {
		width: 221px;
	}
}
