@import "@Scss/old/common/_variables.scss";
.orser-summary__image {
	width: 152px;
	height: 152px;
	display: block;
	overflow: hidden;
}

.orser-summary__text-bold {
	font-weight: 700;
}

.orser-summary__container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.orser-summary__main {
	flex-grow: 2;
}

.orser-summary__aside {
	min-width: 230px;
	margin-left: 16px;
	border-left: 1px solid #e7e7e7;
	padding-left: 9px;
	@media (min-width: 1468px) {
		max-width: 432px;
		margin-left: 60px;
	}
}

.orser-summary__purchase-in-cart-status {
	border-width: 3px;
	padding: 20px;
	border-style: solid;
	border-color: $color-text;
	margin-bottom: 24px;

	&-0 {
		border-color: #84ff59;
	}

	&-2 {
		border-color: #d7e212;
	}

	&-5 {
		border-color: #c0e5fc;
	}

	&-6 {
		border-color: #893232;
	}
}

.orser-summary__aside-header {
	color: $color-text-weak;
	font-size: 1.2em;
}

.orser-summary__text-btn {
	color: $color-text-link;
	padding: 0;
}

.orser-summary__dots-container {
	position: relative;
	background: $optwear-white;

	&:before {
		content: "";
		position: absolute;
		bottom: 0.1rem;
		width: 100%;
		height: 0;
		line-height: 0;
		border-bottom: 2px dotted $color-background;
	}
}

.order-summary__btn {
	width: 100%;
	border-radius: 0;
	color: $color-text;
	border-width: 2px;
	border-style: solid;
	transition: all 0.2s;

	&-warning {
		border-color: rgba(255, 81, 81, 0.75);
		&:hover, &:focus {
			background: rgb(255, 81, 81);
			outline: none;
			color: $optwear-white;
		}
	}

	&-primary {
		border-color: rgba(0, 157, 254, 0.75);

		&:hover, &:focus {
			background: rgb(0, 157, 254);
			outline: none;
			color: $optwear-white;
		}
	}
}

.orser-summary__dots-label {
	background: inherit;
	display: inline;
	z-index: 1;
	padding-right: 0.2em;
	position: relative;
}

.orser-summary__dots-value {
	background: inherit;
	position: absolute;
	min-width: 4em;
	bottom: 0;
	right: 0;
	padding-left: 0.2em;
	text-align: right;
	z-index: 2;
}

.orser-summary__aside-content {
	margin: 10px 0 24px;
	line-height: 1.2em;
}

.orser-summary__icon {
	width: 20px;
	height: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: opacity 0.2s;
	margin: 3px -2px 0 5px;
	opacity: 0.4;
	font-size: 0;

	&:hover, &:focus {
		opacity: 1;
	}
}

.orser-summary__sum {
	text-align: right;
}

.orser-summary__footer {
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	display: -ms-flexbox;
	display: flex;
	text-align: right;
	border-top: 2px solid #1b1b1b;
	white-space: nowrap;
	padding-top: 16px;
	padding-right: 10px;
	-ms-flex-pack: end;
	justify-content: flex-end;
	@media (min-width: 1468px) {
		padding-right: 16px;
	}

	& > div {
		margin-left: 40px;
	}

	& > div:first-child {
		margin-left: 0;
	}
}

.orser-summary__text {
	font-size: 18px;
	color: #000;
	letter-spacing: -0.2px;
	line-height: 24px;
}

.orser-summary__status {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin: 8px;
}

.orser-summary__status-container {
	display: flex;
	justify-content: space-between;
}

.orser-summary__link:hover, .orser-summary__link:focus {
	text-decoration: none;
}

.orser-summary__name {
	margin-top: 10px;
}

.orser-summary__row {
	border-bottom: 1px solid #e7e7e7;
}

.orser-summary__purchase {
	border-bottom: 1px solid #e7e7e7;
	padding: 6px 0;
}

.orser-summary__purchase-date {
	float: right;
}

.orser-summary__row-inner {
	padding: 12px 0;
	color: #272727;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	@media (min-width: 1468px) {
		padding: 16px 0;
	}
}


.orser-summary__content {
	display: flex;
}

.orser-summary__col {
	padding: 6px 0;
	word-break: keep-all;
	white-space: nowrap;
	margin: 0 6px;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.orser-summary__content-inner {
	flex: 0 0 auto;
	margin: 0 0 0 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 240px;
	@media (min-width: 1468px) {
		& {
			margin-left: 16px;
			width: 320px;
		}
	}

}

.orser-summary__date {
	text-align: right;
}

.orser-summary__comment {
	word-break: break-all;
	max-width: 300px;
}

.orser-summary__text-weak {
	color: $color-text-weak;
	font-size: 0.8rem;
	font-weight: 300;
}
