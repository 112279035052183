@import "@Scss/old/common/_variables.scss";

a {
	text-decoration: none;
}


main a {
		color: #004d97;
		&:hover, &:focus {
				text-decoration: underline;
		}
}

.link-rewrite {
		a {
				&, &:hover, &:focus {
						text-decoration: none;
				}
		}
}

//main a, main a:link {
//	color: #004d97;
//
//}
//
//main a[href="#"] {
//	color: #004d97;
//	&:link,
//	&:hover,
//	&:visited,
//	&:focus {
//		color: #004d97;
//		text-decoration: none;
//	}
//}
//
//main a:hover, main a:focus {
//	text-decoration: underline;
//}

a.underline {
	border-bottom: 1px solid $optwear-blue-light;
	text-decoration: none;
	&:link,
	&:hover,
	&:visited,
	&:focus {
		text-decoration: none;
		border-bottom: 1px solid $optwear-blue-light;
	}
	&:hover, &:focus {
		color: $optwear-orange-middle;
		border-bottom: 1px dotted $optwear-blue-strong;
	}
}
