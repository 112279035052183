@use "src/assets/scss/utils" as u;

.gallery-thumbs-container__item {
  display: inline-block;

  a {
    display: inline-flex;
    text-align: center;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 3px;
    height: 67px;
    width: 67px;
    align-items: center;
    justify-content: center;
    float: left;
    margin: 1px 0 0 -1px;

    &:before {
      border: 4px solid #ffffff;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      z-index: u.z-index('default');
    }
  }

  &_active {
    &:before {
      border-color: $optwear-blue-light !important;
    }
  }

  img {
    display: block;
    max-width: 64px;
    max-height: 64px;
  }
}
