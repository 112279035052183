@use "src/assets/scss/utils" as u;
@import "../../../blocks/catalog/catalog-purchase-info/organizer";

.purchases {
  background-color: $optwear-white;
  padding-top: 0.85rem;

  &__tabs {
    width: 100%;

    li:last-child {
      float: right;

    }
  }

  &__stub {
    width: 100%;
    height: 549px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='549' viewBox='0 0 19496514 27317480'%3E%3Cg fill='%23F7F7F7'%3E%3Ccircle cx='1525960' cy='1525960' r='1500964'/%3E%3Cpath d='M3702357 24996h15284833v1050675H3702357zm0 1576012h8030155v1050675H3702357z'/%3E%3Crect width='15735062' height='19485464' x='3702358' y='3577257' rx='600389' ry='581298'/%3E%3Crect width='3652365' height='3502268' x='7629800' y='23790215' rx='600385' ry='600385'/%3E%3Crect width='3652365' height='3502268' x='11657406' y='23790215' rx='600385' ry='600385'/%3E%3Crect width='3652365' height='3502268' x='15559911' y='23790215' rx='600385' ry='600385'/%3E%3Crect width='3652365' height='3502268' x='3602254' y='23790215' rx='600385' ry='600385'/%3E%3C/g%3E%3C/svg%3E");
    animation-name: stub-bg;
    animation-timing-function: linear;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }

  &-list-index {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    &__item {
      flex-grow: 2;
      margin-right: 1em;
      display: flex;
      flex-direction: column;
      max-width: 400px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-item-list {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 1em;

    &__link {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      transition: opacity 0.25s;

      &:hover, &:focus {
        opacity: 0.8;
        outline: none;
      }
    }

    &__item {
      display: inline-block;
      margin-right: 0;
      overflow: hidden;
      border-radius: 12px;
      text-align: center;
      height: 70px;
      width: 70px;

      img {
        max-height: 100%;
        width: auto;
      }
    }

    &__more {
      text-decoration: none !important;
      cursor: pointer;
      transition: all 0.2s ease;
      height: 68px;
      padding: 0 5px;
      border: 1px solid $optwear-blue-light;
      font-size: 14px;
      line-height: 16px;
      color: $optwear-blue-light !important;
      float: left;
      text-align: center;
      border-radius: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover, &:focus, &:active {
        text-decoration: none;
        color: $optwear-orange-middle !important;
        border-color: $optwear-orange-middle !important;
      }

      strong {
        display: block;
        font-size: 18px;
      }
    }
  }

  &-header {
    position: relative;
    @include organizer-round-avatar;

    .organizer-avatar_link {
      position: absolute;
      left: 0;
    }

    .organizer-avatar {
      margin: 0;
    }

    &__link {
      color: #1a1a1a !important;
      border-bottom-color: #dcdcdc;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.2s ease;
      border-bottom-width: 1px;
      border-bottom-style: solid;

      &:hover, &:focus {
        text-decoration: none;
        outline: none;
        color: $optwear-orange-middle !important;
        border-bottom-color: $optwear-orange-middle;
      }
    }

    h4 {
      height: 50px;
      font-size: 18px;
      line-height: 25px;
      font-weight: 300;
      text-transform: none;
      overflow: hidden;
      margin: 0;
      padding-left: 77px;

    }
  }

  &-body {
    padding-left: 77px;
    flex: 2;
    display: flex;
    flex-direction: column;
  }

  &-item-big {
    position: relative;
    display: flex;
    transition: opacity 0.25s;
    margin: 0.82rem 0;
    border-radius: 12px;
    overflow: hidden;
    flex-grow: 2;

    &:hover, &:focus {
      opacity: 0.8;
      outline: none;
    }

    &__price {
      position: absolute;
      top: 10px;
      left: 10px;
      height: 30px;
      padding: 0 10px;
      background-color: $optwear-blue-light;
      border-radius: 2px;
      font-size: 13px;
      line-height: 30px;
      color: $optwear-white;
      font-family: $bold-font-family;
    }

    img {
      width: 100%;
      object-fit: scale-down;
      max-height: 340px;
      border-radius: 12px;
    }

  }

}
