/* OK, here's the good stuff. Start with fallback for browsers that don't support -webkit-line-clamp */
.line-clamp {
	position: relative;
	height: 2.4em;
	overflow: hidden;
}

.line-clamp:after {
	content: "";
	text-align: right;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 50%;
	height: 1.2em;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
}

/* Now add in code for the browsers that support -webkit-line-clamp and overwrite the non-supportive stuff */
@supports (-webkit-line-clamp: 2) {
	.line-clamp {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.line-clamp:after {
		display: none;
	}
}
