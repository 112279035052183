@use "src/assets/scss/utils" as u;
//@use '~@fancyapps/fancybox/dist/jquery.fancybox.css';

.fancybox-custom-horizontal {
  > .fancybox-inner {
    left: u.size('unit') * 20;
    right: 0;
    @include u.below('sm') {
      left: 0;
      bottom: u.size('unit') * 15;
    }

    .fancybox-content {
      border-radius: u.size('unit');
      overflow: hidden;
    }
  }

  > .fancybox-thumbs {
    left: 0;
    width: u.size('unit') * 20;
    background-color: u.var-color('grey-10');
    padding: 0;
    @include u.below('sm') {
      width: 100%;
      top: auto;
      height: u.size('unit') * 15;
    }

    .fancybox-thumbs__list {
      padding: u.size('unit') u.size('unit-2x') u.size('unit') u.size('unit');
      @include u.below('md') {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        align-items: center;
      }

      a {
        width: 100%;
        max-width: none;
        max-height: none;

        &:not(:last-child) {
          margin: 0 0 u.size('unit');
        }

        border-radius: u.size('unit-half');
        background-size: contain;
        background-color: u.var-color('grey-15');
        //padding: 2px;
        padding: 0 0 100%;
        transition: box-shadow;
        @include u.below('md') {
          min-width: 25%;
          width: 25%;
          padding: 0;
          height: 100px;
          //height: 100px;
          &:not(:last-child) {
            margin: 0 u.size('unit') 0 0;
          }
        }
        @include u.mixins-hover {
          box-shadow: inset 0 0 0 2px u.var-color('primary');
        }

        &:focus {
          &:before {
            content: none;
          }

          box-shadow: inset 0 0 0 2px u.var-color('primary');
        }

        &.fancybox-thumbs-active {
          box-shadow: inset 0 0 0 2px u.var-color('primary-dk');

          &:before {
            content: none;
          }

        }
      }
    }
  }
}
