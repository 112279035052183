.lot-nav {
	display: flex;
	justify-content: space-between;
	margin: 5px 0 20px;

}

.lot-nav-btn-group {
	background: $optwear-white;
	border: 1px solid $color-background;
	box-sizing: border-box;
	border-radius: 5px;
	text-align: center;
	height: 35px;
    white-space: nowrap;
	&__item {
		display: inline-block;
		vertical-align: top;
		font-size: 13px;
		box-sizing: border-box;
		height: 100%;
		line-height: 30px;
		position: relative;
		&:visited {
			color: #0000ff;
		}
		&_dir {
			&_prev {
				border-right: 1px solid $color-background;
				padding-right: 5px;
				&:before {
					content: "\2039";
					padding: 0 6px;
					color: #000000;
					font-size: 20px;
					position: relative;
					top: 1px;
				}
				&:hover, &:active, &:visited {
					text-decoration: none;
				}
			}
			&_next {
				padding-left: 5px;
				&:after {
					content: "\203A";
					padding: 0 6px;
					color: #000000;
					font-size: 20px;
					position: relative;
					top: 1px;
				}
				&:hover, &:active, &:visited {
					text-decoration: none;
				}
			}
		}
	}
}
