@use "src/assets/scss/utils" as u;

.TopicCatalog {
  border: 1px solid $color-background;
  border-radius: 5px;
  background: $optwear-white;
  font-size: u.size('text-md');
  @include u.below('md') {
    font-size: u.size('text-sm');
  }

  &_autoload_off {
    .TopicCatalogTable__label {
      text-align: center;
      margin: 20px auto;
    }

    .TopicCatalogTable__button {
      width: 98%;
      margin: 0 10px;
      box-sizing: border-box;
    }
  }

  &Controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    position: relative;
    box-sizing: border-box;
    //@include u.below('lg') {
    //    flex-direction: column;
    //		align-items: flex-end;
    //		justify-content: flex-start;
    //}

    .catalog-sort-types {
      order: 0 !important;
    }

    &Update {
      display: flex;

      &-button {
        @include button-reset;

        &--update, &:disabled {
          animation: rotate-center 2s linear infinite;

        }

        + .TopicCatalogControlsUpdateLast {
          margin: 0 u.size('unit');
        }

        &:disabled {
          svg {
            fill: #cccccc;
          }
        }
      }

      &Last {
        //padding-top: 6px;
        color: #cccccc;
        margin: 0 u.size('unit') 0 0;
        display: flex;
        align-items: center;

        &-place {
          margin: 0 0 0 u.size('unit');
        }
      }
    }

    &ViewMode {
      display: flex;
      align-items: center;
      @include u.below('xs') {
        margin: 0 0 0 auto;
      }

      &-btn {
        @include button-reset;

        &:not(:last-of-type) {
          margin: 0 u.size('unit') 0 0;
        }

        display: flex;
        align-items: center;
        outline: none;

        &:hover svg use,
        &:focus svg use,
        &--active svg use {
          color: #00a5da;
          fill: #00a5da; //
        }
      }
    }

    &__rightGroup {
      display: flex;
      @include u.below('sm') {
        position: absolute;
        top: 15px;
        right: 5px;
      }
      //@include u.below('xs') {
      //    width: 100%;
      //}
    }

    //& .catalog-sort-types {
    //	padding-top: 9px;
    //	padding-left: 10px;
    //
    //}
  }

  &Header {
    border-bottom: 1px solid #cccccc;
  }
}

.topic-catalog-list {
  margin: 0;
  list-style-type: none;
  padding: 0;

  &__item {
    margin: 0;
    font-style: italic;
    padding: 0;
    font-weight: bold;
    font-size: 120%;
    @include u.below('md') {
      font-size: u.size('text-md') !important;
    }
  }
}

.topic-catalog-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid $color-background;
  padding: 5px;
  @include u.below('md') {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.topic-catalog-footer__fastBtn {
  margin-left: 10px;
  margin-bottom: 1px;
  display: inline-block;
  border-bottom: 1px dotted;
  position: relative;
  top: -2px;
  left: 6px;
}

.topic-catalog-pagination-btn-group__item_js_next, .topic-catalog-pagination-btn-group__item_js_prev {
  @include u.below('xs') {
    display: none;
  }
}

.topic-catalog-on-page-selector {
  padding-top: 9px;
  padding-left: 10px;
  @include u.below('md') {
    padding: 5px 10px 0 10px;
    margin: 10px 0 0;
    order: 1;
  }
}

.topic-catalog-pagination {
  &-btn-group {
    margin: 0;
    list-style-type: none;
    padding: 0;
    display: flex;

    &__item {

      padding: 0;
      margin: 0;
      outline: none;
      color: $optwear-blue-light;

      .pagination__item-active &,
      .pagination__item:hover &,
      .pagination__item:focus &,
      .pagination__item:active & {
        color: $optwear-white;
        background-color: transparent;
      }

      &[disabled] {
        color: $color-background;
      }

      &_ellipsis {
        color: $color-background;

        &:hover,
        &:focus,
        &:active {
          color: $color-background;
          background-color: transparent;
        }
      }
    }

    & .pagination__item {
      border: none;
      @include u.below('xs') {
        &:first-child {
          padding: 1px 10px 1px 0;
        }
      }
    }
  }
}

.lotListList {
  .lot-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding: u.size('unit') 5px;
    box-sizing: border-box;
    @include u.below('xs') {
      flex-direction: column;
    }
  }

  .lot-row-part_pos_left {
    padding: 0;

    width: 200px;
    height: 200px;
    margin: 0 !important;
    @include u.below('xs') {
      width: 100%;
      height: auto;
    }

    .lot-image {
      height: 100%;
      width: 100%;
      max-width: none;
      max-height: none;
    }
  }

  .lot-row-part_pos_center {
    padding: 0;
    margin: 0 u.size('unit-4x') !important;
    @include u.below('sm') {
      margin: 0 u.size('unit-2x') !important;
    }
    @include u.below('xs') {
      margin: u.size('unit-2x') 0 !important;
    }
    flex: 1;
    @include u.below('xs') {
      width: 100%;
    }

    .lot-row__desc {
      padding: 0;
      width: 100%;
    }

    .lot-row-orders {
      margin: u.size('unit') 0 0;
    }
  }

  .lot-row-part_pos_right {
    padding: 0 u.size('unit-3x') 0 0;
    width: auto;
    @include u.below('sm') {
      padding: 0 u.size('unit') 0 0;
    }
    @include u.below('xs') {
      width: 100%;
    }
  }
}

.viewtopic-page {
  .TopicCatalogControlsUpdate {
    width: 100%;
    margin: u.size('unit') 0 0;
    @include u.below('md') {
      width: auto;
    }
    order: 99;
  }

  .topic-catalog-footer {

    nav {
      @include u.between('md', 'lg') {
        margin: u.size('unit') 0 0;
      }
    }
  }
}
