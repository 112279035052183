@use "src/assets/scss/utils" as u;

.catalog-lot-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  position: relative;
  height: 100%;
}

.catalog-container .catalog-lot-list {
  width: 100%;
}

.catalog-lot-list-favorites, .catalog-lot-list {
  list-style: none;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: u.size('unit-2x') u.size('unit-2x');
  margin: 0;
  padding: 0;

  @include u.below('lg') {
    grid-template-columns: repeat(4, 1fr);
  }

  @include u.below('md') {
    grid-template-columns: repeat(4, 1fr);
  }

  @include u.below('sm') {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: u.size('unit') u.size('unit');
  }

  @include u.below('xs') {
    grid-template-columns: repeat(2, 1fr);
  }

  .lot-card {
    width: 100%;
    padding: u.size('unit-half') u.size('unit') u.size('unit');
    @include u.below('md') {
      background-color: #f4f4f4;
    }
  }
}

.favorites-page, .resale-page {

  &-title {
    font-size: u.size('text-xxl');
    @include u.below('sm') {
      font-size: u.size('text-xl');
    }
  }

  .catalog-pagination {
    padding: u.size('unit') 0;
  }

  .lot-card-orgFee {
    @include u.below('sm') {
      display: none;
    }
  }

  .lot-card-desc {
    @include u.below('xs') {
      font-size: u.size('text-xs')
    }
  }

  .lot-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: none;
    //height: 70px;
    @include u.below('sm') {
      font-size: u.size('text-sm');
    }
  }

  .lot-card-mainPrice {
    @include u.below('sm') {
      font-size: u.size('text-md');
    }
  }

  .ForumListNav {
    @include u.below('xs') {
      width: 100%;
      display: flex;

      //.ForumListNav-link[rel="last"] {
      //		display: none;
      //}

      .ForumListNav-curPage, .ForumListNav-link, {
        flex: 1;
        text-align: center;
      }
    }
  }
}
