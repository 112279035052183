.lot-summary-featured-catalog {
	background: $optwear-white;
	padding: 15px;
	border: 1px solid $color-background;
	border-radius: 5px;
	font-size: 16px;
	opacity: 0;
	transition: opacity 0.5s;
	.lotCardList {
		border: none;
	}
	.lot-card, .lotCard {
		width: calc(100% / 8) !important;
	}
	.lotCard-orgFee {
		display: none;
	}
	.lotCardList .lotCard {
		border: none;
	}
}
