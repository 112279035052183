.register-form {
	//max-width: 420px;
		width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	&__label {
		font-weight: 700;
		display: block;
		padding: 5px 0;
		transition: all 0.25s;
	}
	&__input {
		&.js-ajax-loading + .error {
			position: relative;
			&:after {
				top: -37px;
				right: 0px;
				content: " ";
				display: block;
				position: absolute;
				margin: 10px;
				height: 16px;
				width: 16px;
				animation: rotate-center 0.8s infinite linear;
				border: 2px solid $optwear-blue-light;
				border-right-color: transparent;
				border-radius: 50%;
			}
		}

	}
	&__agree-label {
		display: inline-block;
		vertical-align: top;
		width: 400px;
		margin-top: -2px;
	}
	&__custom select{
		border: 1px solid rgba(0,0,0,.12);
		box-shadow: none;
		padding: 0 12px;
		font-size: 15px;
		line-height: normal;
		margin: 0;
		position: relative;
		border-radius: 2px;
		box-sizing: border-box;
		height: 32px;
		width: 100%;
		transition: .25s;
	}
}

@import "register-form-fio";
