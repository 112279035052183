@use "src/assets/scss/utils" as u;

.TopicPostHeader {
  line-height: 30px;
  position: relative;
  background: $color-topic-background;
  @include u.below('md') {
    line-height: initial;
  }

  &-Wrapper {
    @include TopicPostWrap;
    display: flex;
  }

  &-author {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    box-sizing: border-box;
    white-space: nowrap;
    //max-height: 30px;
    position: relative;
    @include u.above('md') {
      @include TopicPostWrap;
    }
    @include u.below('md') {
      grid-area: username;
      padding: 0;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      margin: 0 0 u.size('unit');
    }

    &-btn {
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 100%;
      //margin: 0 0 0 u.size('unit');
      //@include u.below('xs') {
      //		margin: 0 0 0 auto;
      //}
      svg {
        @include u.square(u.size('unit-half-2x'));
      }

      //&:hover, &focus {
      //		@include u.below('md') {
      //				+ .TopicPostHeader-author__dropdown {
      //						opacity: 1;
      //						visibility: visible;
      //				}
      //		}
      //}
    }

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      border: solid darken($color-background, 35%);
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, -100%) rotate(45deg);
      transition: opacity .2s ease-out, visibility .2s ease-out, transform .25s ease-in-out;
      bottom: -15px;
      outline: none 0;
      z-index: 9999;
    }

    @include u.above('md') {
      &:hover:before,
      &:focus:before {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, 0) rotate(45deg);
      }
      a {
        position: relative;
        font-size: 16px;
        max-width: 160px;
        text-overflow: ellipsis;
      }
    }

    &:hover, &focus {
      @include u.above('md') {
        .TopicPostHeader-author__dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-username {
      @include u.below('md') {
        //flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin: 0 u.size('unit') 0 0;
        font-size: u.size('text-md');
        span {
          //width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          text-align: left;
        }
      }
    }

    &--online {
      @include u.below('md') {
        line-height: 1.15;
      }
      display: flex;
      align-items: center;

      > span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:after {

        content: "";
        border-radius: 50%;
        min-width: 10px;
        width: 10px;
        height: 10px;
        display: block;
        background-color: $color-online;

        @include u.above('md') {
          //right: -15px;
          //top: 50%;
          //transform: translateY(-50%);
          margin: 0 0 0 u.size('unit');
        }
        @include u.below('md') {
          margin: 0 0 0 u.size('unit');
        }
      }

    }

    &__dropdown {


      text-align: left;
      box-sizing: border-box;
      z-index: 999;
      transition: opacity .15s ease-in-out, visibility .15s ease-in-out;

      @include u.above('md') {
        padding: 20px 0 0 0;
        visibility: hidden;
        opacity: 0;
        left: 0;
        top: 100%;
        position: absolute;
        background-color: #d9d9d9;
        width: 100%;
        border-top: 1px solid $color-background;
        box-shadow: -1px 3px 3px rgba(0, 0, 0, 0.2);
      }
      @include u.below('md') {

      }
    }

    &__fast-link {
      width: 100%;
      height: 100%;
      display: block;

      &:hover, &:focus {
        text-decoration: none;
        outline: none;
      }
    }

    &__fast-links {
      margin: 0;
      list-style-type: none;
      padding: 0;
      @include u.above('md') {

        font-size: 16px;
        li {
          line-height: 2.2em;
          border-bottom: 1px solid $color-background;

          &:hover, &:focus, &:focus-within {
            background-color: darken(#d9d9d9, 15%);
            outline: none;
          }

          a {
            margin: 0 5px;
          }
        }
      }
    }
  }

  &-date {
    &--indent {
      //@include u.below('xs') {
      //		margin-left: 58px;
      //}
    }

    @include u.below('md') {
      line-height: 1.15;
      //padding: 6px 0 0;
      color: u.var-color('text-lt');
      padding: u.size('unit-half') 0 0;

    }
    @include u.below('xs') {
      font-size: u.size('text-xs');
    }

    a {
      color: $color-text;
      font-family: $decor-font-family;
      font-size: 13px;
      @include u.below('md') {
        color: u.var-color('text-lt');
      }
      @include u.below('xs') {
        font-size: u.size('text-xs');
      }

      &-separator {
        margin: 0 5px;
      }
    }
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include TopicPostWrap;
    @include u.below('md') {
      height: 100%;
      width: auto;
      //align-items: flex-start;
      justify-content: flex-start;
      padding: 0 5px;
    }
    @include u.below('xs') {
      width: 100%;
      justify-content: flex-end;
    }
  }

  &PurchaseSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include u.below('md') {
      width: auto;
      align-items: flex-start;
    }

    &-left {
      text-align: left;
      line-height: 23px;
      max-width: 270px;
    }

    &-name {
      font-size: 15px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      display: flex;
      align-items: flex-start;
      max-height: 2em;
    }

    &-cat {
      line-height: 10px;
      text-align: center;
    }

    &-org {
      line-height: 16px;
      text-align: center;
    }

    &-status {
      margin: 0;
    }

    &-center {
      margin: auto 0;
    }
  }
}

//.memberlist-view-page {
//		.TopicPostHeader-author--online {
//				&:after {
//						@include u.below('md') {
//						    position: relative;
//								transform: none;
//								top: 0;
//								left: 0;
//						}
//				}
//		}
//}
