.register-form-fio {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&__item {
		display: flex;
		flex-direction: column;
		max-width: 205px;
	}
}
