.sp_them_stat {
	float: left;
	height: 35px;
	width: 35px;
}

.sp_them_stat1 {
	background: url("~@Assets/imageset-old/purchase_status/1.png") no-repeat center center;
}

.sp_them_stat2 {
	background: url("~@Assets/imageset-old/purchase_status/2.png") no-repeat center center;
}

.sp_them_stat3 {
	background: url("~@Assets/imageset-old/purchase_status/3.png") no-repeat center center;
}

.sp_them_stat4 {
	background: url("~@Assets/imageset-old/purchase_status/4.png") no-repeat center center;
}

.sp_them_stat5 {
	background: url("~@Assets/imageset-old/purchase_status/5.png") no-repeat center center;
}

.sp_them_stat6 {
	background: url("~@Assets/imageset-old/purchase_status/6.png") no-repeat center center;
}

.sp_them_stat7 {
	background: url("~@Assets/imageset-old/purchase_status/7.png") no-repeat center center;
}

.sp_them_stat8 {
	background: url("~@Assets/imageset-old/purchase_status/8.png") no-repeat center center;
}

.sp_them_stat9 {
	background: url("~@Assets/imageset-old/purchase_status/9.png") no-repeat center center;
}

.sp_them_stat10 {
	background: url("~@Assets/imageset-old/purchase_status/10.png") no-repeat center center;
}
