@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

$graycolor: #999999;
.pristroy-item {
  .lot-card-price {
    justify-content: space-between;
    display: flex;
    align-items: flex-end;
  }

  &-addr {
    display: flex;

    &__val {
      display: inline-block;
      max-width: 141px;
      height: 1em;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 40%;
        height: 12px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 100%);
      }
    }
  }

  &__originalPrice {
    text-decoration: line-through;
    color: $graycolor;
    font-size: 15px;
    padding-top: 19px;
  }

  &__date {
    color: $graycolor;
  }

  &__btn-writeMessage {
    border: 1px solid $color-background;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn-showPhone {
    background-color: $optwear-orange-middle;
    border: solid transparent;
    color: $optwear-white;

    &:hover, &:focus {
      background-color: $optwear-orange-strong !important;
    }
  }

  &-actions {
    &__btn {
      box-sizing: border-box;
      margin-bottom: 10px;
      border: 1px solid $color-background;
      width: 100%;
      position: relative;
      border-radius: 4px;
      transition: all 0.12s ease-out;
      height: 56px;
      padding: 0 18px;
      -webkit-appearance: none;

      &:hover, &:focus {
        background-color: lighten($color-background, 15%);
        text-decoration: none;
      }
    }

  }

  &__phone {
    height: 140px;
    padding: 18px;

    &-note {
      padding-top: 5px;
      color: #999;
    }

    &-alert {
      font-weight: 700;
    }
  }

  &__info {
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      fill: $graycolor;
    }
  }

}

.pristroy-item-catalog {
  .lot-card {
    width: calc(100% / 5);
  }
}

.pristroy-item-description {
  @include u.below('md') {
    margin: 0 0 0 u.size('unit-2x') !important;
  }
}

.pristroy-item-info {
  @include u.below('lg') {
    width: 100%;
    margin: u.size('unit-2x') 0 0 !important;
    flex: auto;
    display: flex;
    flex-direction: column;
  }

  .share {
    @include u.below('lg') {
      width: 100%;
      max-width: none;
      justify-content: center;
    }
  }

  .lot-summary-aside-card {
    @include u.below('lg') {
      order: -1;
    }
  }
}

.lot-summary.pristroy-item {
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: u.size('unit-2x');
  @include u.below('md') {
    flex-direction: row;
    padding: u.size('unit');
  }
  @include u.below('sm') {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .gallery__image-container {
    height: auto;
    @include u.below('sm') {
      padding-bottom: 50%;
    }
    @include u.below('xs') {
      padding-bottom: 100%;
    }
  }

  > .lot-summary__col {
    @include u.below('sm') {
      margin: u.size('unit-2x') 0 0 !important;
    }
  }
}
