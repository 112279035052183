.catalog-pagination {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #cccccc;
	padding: 5px;
	&__selector {
		padding-top: 9px;
		padding-left: 10px;
	}
}
