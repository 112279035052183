@use "src/assets/scss/utils" as u;

.calendar-messages {
  &-empty {
    font-size: u.size('text-md');
    @include u.below('sm') {
      font-size: u.size('text-sm');
    }
  }

  &__header {
    padding: 0 0 0 u.size('unit-2x');
    height: 24px;
    display: flex;
    align-items: center;
    @include u.between('md', 'lg') {
      padding: u.size('unit');
    }
    @include u.below('sm') {
      padding: u.size('unit');
    }

  }

  &__actions {
    transition: all 0.25s;
    //padding-left: 17px;
  }

  &__action {
    transition: all 0.25s;
    opacity: 1;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:last-of-type) {
      margin: 0 u.size('unit') 0 0;
    }

    &:hover {
      //background-color: $color-background;
      //border-radius: 50%;
      & svg path {
        fill: $optwear-blue-light;
      }
    }
  }

  &__action-input {
    display: flex;
    align-items: center;
    width: 30px;
  }

  &__action_hidden {
    opacity: 0;
  }
}

.calendar-messages-message {
  display: flex;
  margin: 10px 0;
  padding: 10px 0;
  transition: opacity 0.25s;
  align-items: center;

  &[data-new="0"] {
    opacity: 0.5;

    &:focus, &:hover {
      opacity: 1;
    }
  }

  &[data-new="0"] &__icon {
    opacity: 0.5;
  }

  @include u.below('sm') {
    padding: 0;
  }

  &__icon {
    display: block;
    //position: relative;
    //padding-left: 45px;
    @include u.square(25px);
    background-repeat: no-repeat;
    //background-position: 10px 5px;
    background-size: contain;
    background-position: center center;
    margin: 0 0 0 15px;
    box-sizing: border-box;
    ///images/events/3_color.png
    @include u.below('sm') {
      margin: 0;
      @include u.square(15px);
    }

    &_t_ {
      @for $i from 0 through 6 {
        &#{$i} {
          background-image: url("~@ForumImages/events/#{$i}_color.png");
        }
      }
    }

    &_done:after {
      content: "✔";
      color: green;
      position: absolute;
      top: -#{u.size('unit')};
      right: 0;
      font-weight: bold;
      font-size: u.size('text-md');
      @include u.below('sm') {
        position: relative;
        right: auto;
        top: u.size('unit-2x');
      }
    }

    &-block {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      @include u.below('sm') {
        grid-area: icon;
        align-items: flex-start;

      }
    }
  }

  &__header {
    font-family: "Lucida Grande", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 1.2em;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    @include u.below('sm') {
      grid-area: header;
    }

    .topictitle {
      cursor: pointer;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #004d97;
      @include u.mixins-hover {
        text-decoration: underline;
      }
    }
  }

  &__container {
    width: 100%;
    padding: u.size('unit-2x');
    box-sizing: border-box;
    @include u.between('md', 'lg') {
      padding: u.size('unit');
    }
    @include u.below('sm') {
      padding: u.size('unit');
    }
  }

  &__mark {
    //padding: 0 15px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    width: 30px;
    @include u.below('sm') {
      max-height: none;
      padding: 0;
      grid-area: mark;

    }
  }

  &__header-time {
    font-weight: normal;
    padding-top: 2px;
    color: $color-text-weak;
  }

  &__body {
    //min-width: 780px;
    width: 100%;
    box-sizing: border-box;
    margin: u.size('unit-2x') 0 0;


    > .content {
      word-break: break-word;

      img {
        max-width: 100%;
      }

      padding: u.size('unit-2x');
      border: 1px solid $optwear-blue-strong;
      @include u.below('md') {
        padding: u.size('unit');
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      > .postlink {
        font-size: 120%;
      }
    }

    & img {
      @include img-responsive;
      display: inline-block;
    }
  }
}

.calendar-messages-message-header {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  &::marker {
    display: none;
  }

  &:focus, &:active {
    outline: none;
  }

  & > summary:first-of-type {
    list-style-type: none;
  }

  @include u.below('sm') {
    display: grid;
    grid-template: "mark header" 30px "icon header" minmax(u.size('unit-4x'), auto) / 30px 1fr;
  }

  &-info {
    display: flex;
    align-items: baseline;

    > a {
      //max-width: 1;
      margin: u.size('unit') 0 0;
    }

    &-delimiter {
      &:before {
        content: ',';
        margin: 0 u.size('unit-half') 0 0;
      }

      @include u.between('md', 'lg') {
        display: none;
      }
      @include u.below('sm') {
        display: none;
      }
    }

    .calendar-messages-message__header-time {

    }

    @include u.between('md', 'lg') {
      flex-direction: column;
    }
    @include u.below('sm') {
      flex-direction: column;
    }
  }
}
