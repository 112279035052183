.lot-summary-label {
  &__hr {
    border-bottom: 1px solid $color-background;
    margin: 10px 5px;
  }
  &__header {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: normal;
    margin-left: 5px;
  }
}
