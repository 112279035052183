.lot-summary-aside-card {
	margin-bottom: 20px;
	border-radius: 3px;
	background-color: $optwear-white;
	box-shadow: 4px 4px 1px #eeeeee;
	border: 1px solid $color-background;
	font-family: "Helvetica neue", Helvetica, Verdana, sans-serif;
	&__inner {
		padding: 9px 11px;
		display: inline-block;
	}
	&__label {
		margin: 0;
		text-align: left;
	}
}
