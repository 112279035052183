@use "src/assets/scss/utils" as u;

.c-btn {
  display: inline-block;
  height: 40px;
  padding: 0 16px;
  border: 0;
  color: #fff;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  background-color: $optwear-blue-light;
  text-decoration: none;
  transition: background-color .25s ease-in-out;
  outline: 0;
  border-radius: 5px;
  cursor: pointer;

  @include hover-supported {
    background-color: $optwear-blue-strong;
  }

  @include m(orange) {
    background-color: $optwear-orange-middle;
    @include hover-supported {
      background-color: $optwear-orange-strong;
    }
  }
  @include m(fluid) {
    width: 100%
  }
}

.c-btn-ghost {
  display: inline-block;
  height: 40px;
  padding: 0 16px;
  color: #333;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  border: 1px solid $optwear-blue-light;
  transition: border-color .25s ease-in-out, background-color .25s ease-in-out, color .25s ease-in-out;
  outline: 0;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  @include m(xl) {
    height: 64px;
    line-height: 64px;
    font-size: 20px;
  }
  @include hover-supported {
    color: #fff;
    background-color: $optwear-blue-strong;
    border-color: $optwear-blue-strong;
  }
  @include m(orange) {
    border-color: $optwear-orange-middle;
    @include hover-supported {
      color: #fff;
      background-color: $optwear-orange-strong;
      border-color: $optwear-orange-strong;
    }
  }
  @include m(fluid) {
    width: 100%
  }
}

.c-select {
  width: 100%;
  height: 40px;
  padding: 0 (u.size('unit-4x') + u.size('unit-half-2x')) 0 u.size('unit-2x');
  margin: 0;
  border: 1px solid u.color('grey-25');
  box-sizing: border-box;
  background: #fff;
  font-size: 13px;
  line-height: 40px;
  outline: 0;
  appearance: none;
  border-radius: 5px;

  &:focus {
    border-width: 1px;
    border-style: solid;
    border-color: $optwear-blue-light;
  }

  @include e(wrapper) {
    position: relative;
    width: 100%;
  }

  &-icon {
    color: u.var-color('text-lt');
    pointer-events: none;
    display: flex;
    align-items: center;
    right: u.size('unit-2x');
    @include u.mixins-absolute-center-vertical;

    svg {
      @include u.square(u.size('unit-half-2x'))
    }
  }
}

.c-input {
  width: 100%;
  height: 40px;
  padding: 0 45px 0 12px;
  margin: 0;
  border: 1px solid u.color('grey-25');
  box-sizing: border-box;
  background: #fff;
  font-size: 13px;
  line-height: 40px;
  outline: 0;
  border-radius: 5px;

  &:focus {
    border-width: 1px;
    border-style: solid;
    border-color: $optwear-blue-light;
  }
}

.c-input-search {
  @include disable-default-reset-btn;

  &:placeholder-shown {
    & + .c-input-search-controls {
      & > .c-input-search-controls__reset {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &:-ms-input-placeholder {
    & + .c-input-search-controls {
      & > .c-input-search-controls__reset {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  width: 100%;
  height: 40px;
  padding: 0 94px 0 12px;
  margin: 0;
  border: 1px solid u.color('grey-25');
  box-sizing: border-box;
  background: #fff;
  font-size: 13px;
  line-height: 40px;
  outline: 0;
  border-radius: 5px;
  @include e(wrapper) {
    display: flex;
    position: relative;
    width: 100%;
  }

  &:focus {
    border-width: 1px;
    border-style: solid;
    border-color: $optwear-blue-light;
  }

  @include b(controls) {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    display: flex;
    padding: 0 10px 0 0;
    @include e(search) {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 24px;
      color: u.var-color('text-lt');
      outline: 0;
      padding: 0 5px;
      margin: 0 0 0 10px;
      background: transparent;
      border: 0;
      transition: .25s ease-in-out;
      @include hover-supported {
        color: $optwear-orange-strong;
      }
    }
    @include e(reset) {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 20px;
      line-height: 35px;
      color: u.var-color('text-lt');
      transition: .25s ease-in-out;
      padding: 0 5px;
      outline: 0;
      background: transparent;
      border: 0;
      @include hover-supported {
        color: $optwear-orange-strong;
      }
    }
  }
}

.c-label {
  font-size: 13px;
  margin: 0 0 8px;
}

.c-custom-select {
  width: 100%;
  position: relative;
  @include b(btn) {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0 45px 0 12px;
    margin: 0;
    border: 1px solid #cbcbcb;
    box-sizing: border-box;
    background: #fff;
    font-size: 13px;
    line-height: 40px;
    outline: 0;
    appearance: none;
    border-radius: 5px;

    &:focus {
      border-width: 1px;
      border-style: solid;
      border-color: $optwear-blue-light;
    }

    @include e(icon) {
      display: flex;
      align-items: center;
      &:before {
        font-family: 'icomoon' !important;
        font-size: 13px;
        pointer-events: none;
        //@extend .icon-angle-down:before;
      }
    }
  }
}
