@use "src/assets/scss/utils" as u;

@import "~noty/src/noty";
@import "~noty/src/themes/mint";

.noty_theme__mint.noty_bar {
  border-radius: 5px !important;
}

.noty_theme__mint.noty_type__information {
  background-color: u.var-color('secondary') !important;
}

.noty_theme__mint.noty_type__success {
  background-color: #72bf44 !important;
}

.noty_theme__mint.noty_type__warning {
  //background-color: u.var-color('warning') !important;
}

.noty_theme__mint.noty_type__error {
  //background-color: u.var-color('danger') !important;
}

.noty_theme__lena.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative;

  .noty_body {
    padding: 10px;
    font-size: 14px;
  }

  .noty_buttons {
    padding: 10px;
  }
}

.noty_theme__lena.noty_type__alert,
.noty_theme__lena.noty_type__notification {
  background-color: #fff;
  border-bottom: 1px solid #D1D1D1;
  color: #2F2F2F;
}

.noty_theme__lena.noty_type__warning {
  background-color: #FFAE42;
  border-bottom: 1px solid #E89F3C;
  color: #fff;
}

.noty_theme__lena.noty_type__error {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: u.size('unit');
  background-color: u.var-color('danger');
  color: #fff;
  height: u.size('unit-8x') * 2;

  .noty_body {
    text-align: center;
    font-size: u.size('text-lg');
  }
}

.noty_theme__lena.noty_type__info,
.noty_theme__lena.noty_type__information {
  background-color: #7F7EFF;
  border-bottom: 1px solid #7473E8;
  color: #fff;
}

.noty_theme__lena.noty_type__success {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: u.size('unit');
  background-color: #72bf44;
  color: #fff;
  height: u.size('unit-8x') * 2;

  .noty_body {
    text-align: center;
    font-size: u.size('text-lg');
  }
}

