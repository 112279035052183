@use "src/assets/scss/utils" as u;

.m-hidden-block {
  width: 100%;
  position: relative;
  transition: height .5s ease-in-out;
  height: 250px;
  overflow: hidden;
  flex-wrap: nowrap;

  &:after {
    content: '';
    position: absolute;
    z-index: u.z-index('default');
    @extend .above-sm !optional;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100px;
    background: linear-gradient(180deg, rgba(u.var-color('body-background-rgb'), 0) 0%, u.var-color('grey-10') 75px);
  }

  @include e(btn) {
    user-select: none;
    cursor: pointer;
    z-index: u.z-index('after-default');
    font-size: 15px;
    outline: none;
    color: #333;
    line-height: 25px;
    border: none;
    bottom: 0;
    background: none;
    @extend .above-sm !optional;
    @include absolute-center-horizontal;
  }
  @include m(show) {
    height: auto;
    @include u.below('sm') {

    }
    &:after {
      content: none;
    }
  }
}

@keyframes ldio-zspy1uxuof {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

.m-preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transform-origin: 0 0;
  background-color: rgba(#000, .5);
  z-index: u.z-index('preloader-low');

  &-hidden {
    opacity: 0;
    visibility: hidden;
    //z-index: -1;
    transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
  }

  &-fixed {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
    //height: calc(100vh - 221px);
    height: 100vh;
  }

  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  //&[data-module-preloader="cart"] {
  //		.m-preloader-container {
  //				top: 0;
  //				left: 0;
  //				transform: none;
  //				height: calc(100vh - 221px);
  //		}
  //}

  div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-zspy1uxuof 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:nth-child(1) {
      border-color: u.var-color('secondary')
    }

    &:nth-child(2) {
      border-color: u.var-color('primary');
      animation-delay: -0.5s;
    }
  }


}
