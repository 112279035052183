@use "src/assets/scss/utils" as u;

.categories-img {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    background-color: #cbcbcb;
  }

  &__part {
    width: 50%;

    &:first-child {
      margin-right: 1em;
    }

    .mb { // margin-bottom
      margin-bottom: 5px;
    }

    .mr {
      margin-right: 5px;
    }

    #girls {
      .categories-img__link {
        background-color: #d064a2;
      }

      .opHalf { // background opacity is 1/2 of origin opacity
        background-color: rgba(208, 100, 162, 0.5);
      }

      .opThreeQuarters { // background opacity is 75% of origin opacity
        background-color: rgba(208, 100, 162, 0.75);
      }
    }

    #womans {
      .categories-img__link {
        background-color: rgba(251, 186, 182, 1);
      }

      .opHalf { // background opacity is 1/2 of origin opacity
        background-color: rgba(251, 186, 182, 0.5);
      }

      .opThreeQuarters { // background opacity is 75% of origin opacity
        background-color: rgba(251, 186, 182, 0.75);
      }
    }

    #boys {
      .categories-img__link {
        background-color: #81b4dd;
      }

      .opHalf { // background opacity is 1/2 of origin opacity
        background-color: rgba(129, 180, 221, 0.5);
      }

      .opThreeQuarters { // background opacity is 75% of origin opacity
        background-color: rgba(129, 180, 221, 0.75);
      }
    }

    #mans {
      .categories-img__link {
        background-color: rgba(129, 180, 221, 1);
      }

      .opHalf { // background opacity is 1/2 of origin opacity
        background-color: rgba(129, 180, 221, 0.5);
      }

      .opThreeQuarters { // background opacity is 75% of origin opacity
        background-color: rgba(129, 180, 221, 0.75);
      }

    }
  }


  &__tab {
    color: #434343 !important;
    text-decoration: none;
    padding-bottom: 0.80rem;

    &:hover, &:focus {
      outline: none !important;
      text-decoration: none !important;
    }

    &_active {
      font-weight: bold;
      color: $color-text !important;
      text-decoration: none;
      border-bottom: 3px solid $color-text;
    }

    &_disabled {
      color: $color-text-weak !important;
      user-select: none;
      cursor: not-allowed;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  &__link-list {
    display: none;

    li {
      display: none;
    }

    &_active {
      display: block;

      li {
        display: inline-block;
      }
    }


  }

  &__link {
    background-color: white;
    flex-grow: 2;

    &_image {
      position: relative;
      //overflow: hidden;

      .categories-img__title {
        opacity: 0;
        position: absolute;
        transition: opacity 0.25s;
        color: $optwear-white;
        text-decoration: none;
        line-height: 2rem;
        text-align: left;
        background: rgba(0, 0, 0, 0.75);
        border-radius: 5px;
        display: inline;
        width: auto;
        padding: 0 10px;
        left: 20px;
        bottom: 20px;
      }

      &:hover, &:focus {

        .categories-img__title {
          opacity: 1;
        }
      }
    }

    a {
      width: 100%;
      height: 100%;
      display: block;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &-group {
      margin-right: 0.5em;
      flex-grow: 2;
      max-height: 516px;

      &:last-child {
        margin-right: 0;
      }

      ul {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &-text {
        flex-direction: row !important;
      }
    }

    &_text {
      width: 100%;
      height: auto;
      display: inline-block;
      transition: opacity 0.25s;

      &:first-child {
        margin-right: 0.5em;
      }

      a {
        width: 100%;
        height: 100%;
        line-height: 4.5em;
        display: block;
        text-align: center;
        vertical-align: middle;
        text-decoration: none;
        color: $optwear-white !important;
        font-size: 2em;

        &:hover, &:active, &:visited {
          text-decoration: none;
        }
      }

      &:hover, &:focus {
        outline: none;
        opacity: 0.85;
      }
    }


  }

  &__header {
    border-bottom: 1px solid darken($optwear-white, 13%);
    padding-bottom: 0.82rem;
    line-height: 1.5em;
    margin-bottom: 0.82rem;

    h2 {
      display: inline-block;
      margin: 0 1em 0 0;
    }

    ul {
      display: inline;
      margin: 0;

      li {
        display: inline-block;
        margin-right: 1em;

      }
    }
  }
}
