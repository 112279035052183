@use "src/assets/scss/utils" as u;

.selected-office {
  background: $optwear-white;
  padding: u.size('unit-2x');
  @include u.below('md') {
    padding: u.size('unit');
  }
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid $color-background;

  &__action {
    float: right;
    margin: 0 0 0 u.size('unit-4x');
    @include u.below('sm') {
      float: none;
      margin: u.size('unit-2x') 0;
    }
  }

  &__label {
    margin: 0 0 10px;
    font-size: u.size('text-lg');
    @include u.below('md') {
      font-size: u.size('text-md');
    }
  }

  &__desc {
    margin: 0;
    overflow: hidden;
    box-sizing: border-box;
    font-size: u.size('text-md');
    @include u.below('md') {
      font-size: u.size('text-sm');
      padding: u.size('unit');
    }
    @include u.below('sm') {
      width: 100%;
    }
  }

  .btn--selected-office {
    $btn-color: #747f7f;
    padding: 0 u.size('unit-2x');
    background: $btn-color;
    border: 1px solid $btn-color;
    color: $optwear-white;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 0;
    font-size: u.size('text-md');
    @include u.below('md') {
      font-size: u.size('text-sm');
    }
    text-align: center;
    vertical-align: middle;
    @include u.mixins-text-height(u.size('unit-5x'));

    &:hover, &:focus {
      background-color: darken($btn-color, 10%);
      outline: none;
    }

    &[disabled] {
      background-color: darken($btn-color, 10%);

      &:hover, &:focus {
        background-color: darken($btn-color, 10%);
      }
    }
  }

}
