@use "src/assets/scss/utils" as u;

.lotCard, .lot-card {
  display: flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  width: calc(100% / 3);
  @media (min-width: 1500px) {
    width: calc(100% / 4);
  }
  @media (min-width: 1700px) {
    width: calc(100% / 5);
  }
  border-top: 1px solid #cccccc;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  overflow: hidden;
  padding: 20px 10px 0 20px;

  #catalog & {
    flex-grow: 2;
    padding: 0 !important;
    @media (min-width: 700px) {
      margin-right: 20px;
    }
  }

  &:hover .lot-image__counter {
    opacity: 1;
  }

  &:hover &-btns {
    display: block;
  }

  &:hover {
    box-shadow: 0 0 7px 0 #f4f4f4;
    background: #f4f4f4;
  }

  &:hover &-descItemPropItemValueList__showAll {
    box-shadow: 0 0 30px 40px #f4f4f4;
  }

  &-descItemPropItem__label {
    display: inline-block;
    text-align: left;
    min-width: 1.25rem;
    cursor: pointer;
    transition: all .2s;
    margin-bottom: 0.375rem;
    margin-right: 0.625rem;

    &-title {
      margin: 0 !important;
      background: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      padding: 0.0625rem 0.3125rem;
    }

    &:hover &-title,
    &:hover &-title,
    &:focus &-title,
    input:checked + &-title {
      color: $optwear-white;
      background-color: $optwear-blue-strong;
      text-decoration: none;
    }
  }

  &-header {
    position: relative;
    padding-top: 15px;
  }

  &-price {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
  }

  &-mainPrice {
    font-weight: 400;
    font-size: 25px;
    text-align: right;
    line-height: 21px;
    color: #202020;
    white-space: nowrap;
    margin: 0;
  }

  &-orgFee {
    text-align: right;
    white-space: nowrap;
    color: $color-background;
  }

  &-crossLined {
    font-size: 12px;
    text-decoration: line-through;
    margin: u.size('unit') 0 0;
  }

  &List {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #cccccc;
    margin: 0;
    padding-left: 0;
    list-style-type: none;
  }

  &List & {
    box-shadow: none;
    border-top: 1px solid #cccccc;
  }

  &-desc {
    list-style: none;
    margin: 0;
    padding: 0;

    &Item {
      list-style: none;

      &--catalogLabel {
        font-size: 13px;
        max-height: 55px;
        color: #cccccc;
      }

      &--catalogLabel, &--purchaseLabel {
        display: block;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &PropItem {
        display: block;
        position: relative;

        &__label {
          color: rgba(0, 0, 0, .5);
        }

        &ValueList {
          list-style: none;
          margin: 0;
          padding: 0;
          white-space: nowrap;

          &__expanded {
            white-space: normal;
          }

          &__showAll {
            position: absolute;
            top: 1.5rem;
            right: 0;
            background-color: $optwear-blue-strong;
            font-size: 0.75rem;
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            padding: 0.0625rem 0.3125rem;
            border-radius: 3px;
            margin-bottom: 0;
            margin-top: 4px;
            box-shadow: 0 0 30px 40px rgba(255, 255, 255, 1);
          }
        }

        &ValueItem {
          display: inline-block;
          background: rgba(0, 0, 0, .1);
          color: rgba(0, 0, 0, .5);
          font-size: 0.75rem;
          text-transform: uppercase;
          text-align: center;
          padding: 0.0625rem 0.3125rem;
          border-radius: 3px;
          min-width: 1.25rem;
          cursor: pointer;
          transition: all .2s;
          margin-bottom: 0.375rem;
          margin-right: 0.625rem;
        }
      }
    }
  }

  &-ordersCounter {
    margin-top: 5px;
    text-align: center;

    .link {
      white-space: nowrap;
      color: #000000;
    }
  }

  &-hidden {
    display: none;
  }

  &:hover &-hidden {
    display: block;
  }

  &__fav-btn {
    position: absolute;
    right: 0;

    .lot-user-form__add-to-favorites {
      border: none;
      width: 30px;
      background-size: 20px;
      height: 30px;
      margin: 0;
      float: right;
      opacity: 0;

    }

    .lot-user-form__add-to-favorites_active {
      opacity: 1;
    }
  }

  &:hover .lot-card__fav-btn .lot-user-form__add-to-favorites {
    opacity: 1;
  }


  &-addOrder {
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-wrap: wrap;
    }

    &__small-btn {
      font-size: 20px !important;
    }


    &__amount {
      flex-grow: 2;
      margin-left: 5px;
      margin-right: 5px;

      .lot-user-form-quantity {
        min-width: 95px;
      }
    }

    &-btn {
      display: flex;
      flex-grow: 2;
      margin: 5px;
    }

    &__btn {
      padding: 0 u.size('unit');
      background-color: transparent;
      border-color: #2bc253;
      color: #2bc253 !important;
      width: 100%;

      &:hover {
        background-color: #72bf44;
        color: #fff;
        border-color: transparent;
      }
    }
  }

  .line-clamp:after {
    background: transparent;
    content: "";
  }

  .line-clamp-5 {
    height: auto;
    max-height: calc(1em * 1.2 * 5);
  }

}

.lot__favorites-delete {
  top: u.size('unit');
  right: u.size('unit');
  left: auto;
  opacity: 0;
  padding: 0;
  transition: all 0.25s;

  > button {
    padding: 0;
    color: u.var-color('danger');
    cursor: pointer;
    border: 0;
    display: flex;
    align-items: center;
  }


  svg {
    @include u.square(u.size('unit-2x'));
  }

  @include u.below('md') {
    opacity: 1;
  }
}

.lot-card:hover .lot__favorites-delete {
  opacity: 1;
}
