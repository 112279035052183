$topicPost-left-width: 204px;
.viewtopic-page {
		.Topic-container {
				> .TopicPost-purchase {
						.TopicPostHeader-info, .TopicPostHeader-author {
								@include u.above('md') {
										min-height: 90px;
										height: 90px;
								}
						}
				}
		}
}


@mixin TopicPostWrap {
		width: 100%;
		border-top: 1px solid $color-topic-background;
		border-bottom: 1px solid $color-topic-background;
		background-color: $color-topic-background;
		//box-shadow: -1px 3px 3px rgba(0, 0, 0, 0.2);
		//	box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}


.TopicPost {
		width: 100%;
		@include u.above('md') {
				background: #f7f7f7;
				&:nth-child(2n) {
						background: darken($optwear-white, 3%);
				}
		}
		@include u.below('md') {
				background: darken($optwear-white, 3%);
				&:not(:last-of-type) {
						margin: 0 0 30px;
				}
		}
		margin-bottom: 15px;
		//box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.2);
		display: grid;
		grid-template: "profile info" minmax(30px, auto) "profile body" auto "actions actions" minmax(0, auto) / 220px 1fr;
		@include u.below('md') {
				grid-template: "profile info" minmax(40px, auto) "body body" auto "actions actions" minmax(40px, auto) / 1fr auto;
		}
		@include u.below('xs') {
				overflow: hidden;
				grid-template: "info" auto "profile" minmax(40px, auto) "body" auto "actions" minmax(40px, auto) / 1fr;
		}
		
		&-grid {
				&-profile {
						grid-area: profile;
						border-right: 1px solid #cbcbcb;
						display: flex;
						flex-direction: column;
						align-items: center;
						box-sizing: border-box;
						@include u.below('md') {
								border-right: 0;
								//flex-direction: row;
								@include TopicPostWrap;
								//padding: 6px 10px 0 10px;
								////align-items: flex-start;
								//overflow: hidden;
								display: grid;
								grid-template: "avatar username" auto "avatar info" auto ". reviews_button" minmax(0, auto) / minmax(0, auto) 1fr;
								padding: u.size('unit') 5px;
						}
						@include u.below('sm') {
								grid-template: "avatar username" auto "avatar info" auto "reviews_button reviews_button" minmax(0, auto) / minmax(0, auto) 1fr;
						}
						@include u.below('xs') {
								padding: 0 5px u.size('unit') 5px;
						}
				}
				
				&-info {
						grid-area: info;
				}
				
				&-body {
						grid-area: body;
						overflow: hidden;
				}
				
				&-actions {
						grid-area: actions;
						align-self: flex-end;
				}
		}
		
		&-SPNewURL {
				position: relative;
				
				&Btn {
						position: absolute;
						left: 50%;
						top: 5%;
						filter: none;
						@include u.below('sm') {
						    width: 80%;
								top: 15%;
								padding: u.size('unit-2x');
						}
						@include u.below('xs') {
						    top: 25%;
						}
						transform: translate(-50%, 0);
						z-index: u.z-index('after-default');
						background: $optwear-white;
						padding: 50px;
						border-radius: 5px;
						
						&-label {
								display: block;
								text-align: center;
								font-size: 30px;
								@include u.below('sm') {
										font-size: u.size('text-lg');
								}
						}
						
						&-link {
								display: block !important;
						}
						
						&-hide {
								@include button-reset;
								text-align: center;
								margin: 0 auto;
								display: block;
								border-bottom: 1px dotted #cccccc;
						}
				}
				
				& .TopicPostMessage-body {
						filter: blur(5px);
						transition: filter 0.5s ease-out;
						@supports not ((filter:blur(5px)) or (-webkit-filter: blur(4px))) {
								visibility: hidden;
								& * {
										visibility: hidden;
								}
						}
				}
		}
		
		&Body {
				display: flex;
				@include u.below('sm') {
						flex-direction: column;
				}
		}
		
		&LeftBlock {
				//width: $topicPost-left-width;
				//float: left;
				//border-right: 1px solid $color-background;
				//border-left: 1px solid $color-topic-background;
				text-align: center;
				@include u.below('sm') {
						display: none;
				}
		}
		
		&Message {
				flex: 1;
				
				&-body {
						//max-width: 790px - 20px;
						overflow: hidden;
						word-break: break-word;
						
						.bbvideo {
								@include u.below('xs') {
								    width: 100% !important;
										height: auto !important;
										position: relative;
										overflow: hidden;
										&:before {
												content: '';
												display: block;
												padding-bottom: 50%;
										}
										
										iframe {
												position: absolute;
												top: 0;
												left: 0;
												width: 100% !important;
												height: 100% !important;
										}
								}
						}
						
						.SPTopic & {
								max-width: none;
						}
						
						padding: 5px;
						font-size: 16px;
						box-sizing: border-box;
						@include u.below('md') {
								width: 100%;
								
								padding: 10px 5px;
						}
						
						&--signature span:first-child {
								display: block;
						}
						
						& .Search {
								padding-top: 0;
								padding-bottom: 20px;
								
								&-box {
										background: $optwear-white
								}
						}
						
						img {
								max-width: 100%;
								height: auto;
								display: inline-block;
						}
				}
				
				.SPTopic &-body {
						//width: $optwear-container-width -$topicPost-left-width - 20px;
						width: 100%;
				}
		}
		
		&RightBlock {
				box-sizing: border-box;
				padding: 5px 10px;
				//border-right: 1px solid #d9d9d9;
				font-size: 14px;
				//margin-left: 206px;
				flex: 1;
				text-align: right;
				//margin-right: 10px;
				@include u.below('sm') {
						margin: 0;
				}
		}
		
}

.ucp-table {
		.TopicPost {
				display: block;
		}
}

#Search-box-input--forum {
		letter-spacing: 1px;
		font-size: 20px;
		padding: 0 u.size('unit-3x') 0 u.size('unit');
		@include u.below('xs') {
				font-size: u.size('text-md');
		}
}

@import "TopicPostHeader";
@import "TopicPostProfile";
@import "TopicPostFooter";
