@import "@Scss/old/common/_variables.scss";
.catalog-display-types {
	list-style: none;
	margin: 0;
	&__type {
		padding: 0;
		border: 0;
		font: inherit;
		color: inherit;
		background-color: transparent;
		cursor: pointer;
		outline: 0;
		display: inline-block;
		svg use {
			color: $color-background;
			fill: $color-background;
		}
		&-active {
			cursor: default;
			& svg use {
				color: #00a5da;
				fill: #00a5da;
			}
		}
	}
}
