@use "src/assets/scss/utils" as u;

.TopicQuickReply {
  &Actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    @include u.below('md') {
      //flex-direction: column;
      //align-items: flex-end;

      //justify-content: flex-end;
    }

    .btn {
      &:not(:last-of-type) {
        margin: 0 10px 0 0;
      }

      @include u.below('sm') {
        &:not(:last-of-type) {
          margin: 0 0 10px;
        }
        width: 100%;
      }
    }
  }

  &__multi-buttons {
    display: inline-flex;
    width: 60px;
    justify-content: space-between;
    align-items: center;
  }

  &__add-image {
    cursor: pointer;
    height: 24px;
    float: right;
    @include u.below('md') {
      padding: 10px;
    }
  }

  &-title {
    text-align: center;
    font-size: 20px;
    margin: 0 0 25px;
  }

  &Textarea {
    margin-bottom: 10px;
    position: relative;
    border: 1px solid $color-background;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    @include u.below('md') {
      margin-top: 16px;
    }

    &-input {
      resize: vertical;
      border: none;
      padding: 0 8px;
      //margin-bottom: 2px;
      @include u.below('md') {
        height: 300px;
      }
      @include u.below('xs') {
        height: 200px;
      }

      &:focus {
        outline: none;
      }

      transition: box-shadow 0.5s;
    }

    &:focus-within {
      border: 1px solid $optwear-blue-light;
    }

    & > .dz-drag-hover {
      box-shadow: $color-online 0 0 8px;
    }
  }

  &-submit, &-preview {
    float: right;

  }

  &-submit {
    @include u.below('sm') {
      order: -1;
    }
  }

  &-preview {
    //margin-right: 5px;
  }

  &-signature {
    display: inline-block;
    //padding-top: 10px;
    width: 100%;
    margin: 0 0 10px 5px;
  }

  &-buttons {
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button:not(:last-child) {
      margin: 0 10px 0 0;
    }

    @include u.below('sm') {
      width: 100%;
    }
    @include u.below('xs') {
      margin: 0;
    }
  }

  &Checkboxes {
    float: right;
    display: inline-block;
    position: relative;
    margin: 0 15px 0 0;
    color: #ccc;
    @include u.below('sm') {
      &:not(:last-of-type) {
        margin: 0 0 10px;
      }
    }

    &-notify {
      fill: $color-background;
      @include button-reset;
      outline: none;
      display: flex;
      align-items: center;

      &:focus, &:active, &:focus-within, &:hover {
        outline: none;
      }

      svg {
        //position: absolute;
        //top: -3px;
        //left: -50px;
        height: 32px;
        width: 32px;
      }

      &Checkbox {
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
        opacity: 0;
        z-index: 999;

        &:checked ~ button.TopicQuickReplyCheckboxes-notify {
          color: $optwear-blue-strong;
        }
      }
    }
  }
}

.TopicQuickReply__smiles, .TopicQuickReplyButtonsTextColor {
  cursor: pointer;
}

//.ucp-posting-page {
//		.TopicQuickReplyActions {
//				flex-wrap: wrap;
//				justify-content: flex-end;
//
//				.TopicQuickReply-signature {
//						padding: 0 0 0 5px;
//						margin: 0 0 15px;
//						width: 100%;
//
//				}
//
//				.btn {
//						margin: 0 10px 0 0;
//
//						&:last-of-type {
//								margin: 0;
//						}
//				}
//		}
//}

.viewtopic-page {
  .TopicQuickReply-preview {
    @include u.below('sm') {
      margin: 2px 0 !important;
    }
  }

  .TopicQuickReplyCheckboxes {
    @include u.below('sm') {
      width: 100%;
      order: 3;
      margin: 0;

      .TopicQuickReplyCheckboxes-notifyCheckbox {
        width: 100%;
        height: 100%;
      }

      .TopicQuickReplyCheckboxes-notify {

        svg {
          @include u.square(u.size('unit-3x'));
        }

        box-sizing: border-box;
        display: inline-block;
        padding: 0.5em 1em;
        margin: 2px 0;
        border: solid 1px #a7abaf;
        font-family: "Futura PT Book", "Arial", sans-serif;
        text-align: center;
        border-radius: 5px;
        text-decoration: none;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        user-select: none;
        background-image: none;
        transition: all 0.25s;
        width: 100%;
        color: white;
        background-color: #a7abaf;
      }
    }
  }

}

@import "TopicQuickReplyButtonsBB";
@import "TopicQuickReplyButtonsText";
@import "TopicQuickReply__smiles";
@import "TopicQuickReply-images";
