.pristroy-edit {
	&-actions {
		display: flex;
		width: 100%;
		.btn {
			margin-left: 10px;
			width: 137px;
		}
	}
	&__row {
		display: flex;
		font-size: 16px;
		margin: 20px 0;
		align-items: baseline;
		&_heigth {
			align-items: flex-start;
			.pristroy-edit__label {
				width: 210px;
			}
		}
	}
	&__label {
		width: 221px;
		line-height: 20px;
		padding-top: 5px;
		color: #4a4a4a;
		display: inline-block;
		white-space: pre-line;
	}
	&__empty {
		font-size: 14px;
	}
	.lot-row__price {
		display: flex;
		align-items: center;
	}
	.lot-card-crossLined {
		padding-left: 5px;
	}
	.lot-row {
		box-shadow: none;
		&:hover, &:focus {
			box-shadow: none;
		}
	}
	.lot-row-part_pos_center {
		justify-content: space-between;
	}
	.lot-row-part_pos_right {
		padding-left: 40px;
		padding-right: 0;
	}
	.pristroy-item {
		&__info {
			float: right;
		}
		&__btn-group {
			display: flex;
			flex-direction: column;
			min-width: 165px;
			.btn {
				width: 100%;
			}
		}

		&__desc {
			max-height: 50px;
			overflow: hidden;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				bottom: -2px;
				width: 100%;
				left: 0;
				height: 10px;
				background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
			}
		}
		&-lifetime {
			display: block;
			width: 150px;
			height: 9px;
			border-radius: 5px;
			border: 0;
			overflow: hidden;
			background-color: rgba(102, 102, 102, 0.30);
			margin-bottom: 5px;
			&-fill {
				display: block;
				height: 100%;
				&_green {
					background-color: #97cf26;
				}
				&_yellow {
					background-color: #ffb020;
				}
				&_red {
					background-color: rgba(255, 97, 99, .3);
				}
			}
		}
	}
	.pristroy-item__date {
		color: $color-text;
	}
	.lot-row__desc {
		margin: 0;
	}
	.line-clamp {
		height: 1.2em;
	}
	.lot-row__top {
		display: flex;
		justify-content: space-between;
		.btn {
			max-height: 30px;
		}
	}
}

@import "tabs";
@import "header/header";
@import "item";
@import "top";


.resale-edit-page {
		.pristroy-lots {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 16px 16px;
				
				@include u.below('sm') {
						grid-template-columns: repeat(3, 1fr);
				}
				
				@include u.below('xs') {
						grid-template-columns: repeat(2, 1fr);
				}
		}
		
		.lot-row {
				display: flex;
				flex-direction: column;
				float: left;
				width: 100%;
				background-color: #fff;
				margin: 0 !important;
				
				.lot-row-part_pos_left {
						width: 100%;
						padding: 0 !important;
				}
				
				.lot-row-part_pos_center {
						margin: 0 0 u.size('unit') !important;
						width: 100%;
						padding: u.size('unit') u.size('unit') 0 u.size('unit');
						box-sizing: border-box;
				}
				
				.lot-row-part_pos_right {
						width: 100%;
						margin: 0 !important;
						align-items: flex-end;
						box-sizing: border-box;
						padding: 0 u.size('unit') !important;
						
						.pristroy-item-addr {
								flex-direction: column;
						}
						
						.pristroy-item-addr__val {
								height: auto;
						}
						
						.lot-row__top {
								display: flex;
								flex-direction: column;
						}
						
						.btn--blueBorder {
								box-sizing: border-box;
						}
				}
				
				.lot-image {
						max-width: none;
						min-width: 0;
						width: 100%;
						position: relative;
						.image {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								object-fit: contain;
								font-family: "object-fit: contain;";
								width: 100% !important;
								height: 100% !important;
						}
				}
		}
}

.pristroy-edit-actions {
		margin: 0 0 u.size('unit-2x');
}

.pristroy-edit-top__image {
		.lot-image {
				position: relative;
		}
		.image {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				object-fit: contain;
				font-family: "object-fit: contain;";
				width: 100% !important;
				height: 100% !important;
		}
}