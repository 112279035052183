@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.faq {
  margin: u.size('unit-2x') 0 0;
  @include u.below('sm') {
    margin: u.size('unit') 0 0;
  }
}

.faq-container {
  display: flex;
  @include u.below('sm') {
    flex-direction: column;
    align-items: stretch;
  }

}

.faq__nav {
  width: 30%;
  @include u.below('md') {
    width: 25%;
  }
  @include u.below('sm') {
    width: 100%;
    margin: 0 0 u.size('unit-2x');
  }
}

.faq__nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  @include u.below('sm') {
    display: flex;
  }

  li {
    @include u.below('sm') {
      flex: 1;
      display: flex;
      align-items: center;

    }
  }
}

.faq__nav ul li a {
  display: block;
  padding: 15px 25px 15px 0;
  border-right: 3px solid u.var-color('grey-10');
  text-align: right;
  text-decoration: none;
  color: #1a1a1a;
  @include u.below('sm') {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: u.size('unit-2x') u.size('unit-half');
    border-right: none;
    border-bottom: 3px solid u.var-color('grey-10');
    text-align: center;
  }
}

.faq__nav ul li a:hover {
  color: $optwear-blue-strong;
  border-bottom-color: $optwear-blue-light;
  border-right-color: $optwear-blue-strong;
}

.faq__nav ul li.faq__nav-active a {
  color: $optwear-orange-middle;
  border-color: $optwear-orange-middle;
}

.faq__container {
  flex: 1;
  margin: 0 0 0 u.size('unit-4x');
  @include u.below('md') {
    margin: 0 0 0 u.size('unit-2x');
  }
  @include u.below('sm') {
    margin: 0;
  }

  img {
    @include img-responsive;

  }
}

.faq__container details {
  padding: 25px 0;
  border-bottom: 1px dotted #ccc;
}

.faq__container details summary {
  text-transform: uppercase;
  position: relative;
  padding-left: 10px;
}

.faq__container details summary:focus {
  outline: none;
}

.faq__container details summary:before {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #999;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  line-height: 0;
  content: '';
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.faq__container details summary:hover {
  color: $optwear-blue-light;
}

.faq__container details summary:after {
  position: absolute;
  top: -1px;
  color: $optwear-white;
  transform: rotate(90deg);
  left: 5px;
  content: '❯';
  font-size: 14px;

}

.faq__container details[open] summary {
  color: $optwear-blue-light;
}

.faq__container details[open] summary:after {
  transform: none;

}

.faq__container details[open] summary:before {
  background-color: $optwear-blue-light;

}

.faq__container details summary:hover .faq__container details summary:before {
  background-color: $optwear-blue-light;
}

.faq__inner {
  padding: u.size('unit-2x') 0 0 0;
  font-size: 15px;
  line-height: 24px;

  p {
    margin: 0;
  }

  @include u.below('sm') {

  }
}

.faq-page {
  &-title {
    font-size: u.size('text-xxl');
    @include u.below('sm') {
      font-size: u.size('text-xl');
    }
  }
}
