@use "src/assets/scss/utils" as u;

.lot-summary {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: stretch;
  width: auto;
  margin: 0 auto;
  position: relative;
  background: $optwear-white;
  padding: 15px;
  border: 1px solid $color-background;
  border-radius: 5px;
  font-size: 16px;
  @include u.below('md') {
    flex-flow: column nowrap;
    position: relative;
    padding: 40px 15px 15px;
  }

  &__col {
    position: relative;
    flex-grow: 1;
    flex-basis: 60px;

    & + & {
      margin-left: 17px;
      @include u.below('md') {
        margin: 20px 0 0;
        position: initial;
      }
    }

    &_size {
      &_3 {
        flex-grow: 3;
      }

      &_6 {
        flex-grow: 6;
      }

      &_auto {
        flex-grow: 2000;
      }
    }

  }

  @media all and (min-width: 1230px) {
    &__col {
      flex-basis: 80px;
    }
  }

  @media all and (min-width: 1336px) {
    &__col {
      flex-basis: 84px;
    }
  }

  .lot__tag-group {
    top: 10px;
    left: 0;
  }
}

.lot-summary-main {
  font-family: "Helvetica neue", Helvetica, Verdana, sans-serif;
}

@import "lot-summary-label";
@import "lot-summary-price";
@import "lot-summary-description";
@import "lot-summary-large-description";
@import "aside-card/aside-card";
@import "info/info";
@import "features/features";
@import "featured-catalog/featured-catalog";
