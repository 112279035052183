@use "src/assets/scss/utils" as u;

.index-mobile-banner {

  background: #fff;
  margin: 0 0 30px;
  border-radius: 5px;
  @include e(content) {
    flex-direction: row;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid #dedede;
    @include u.below('sm') {
      flex-direction: column;
    }
  }
  @include e(block) {
    display: flex;
    width: auto;
    @include u.below('xs') {
      width: 100%;
    }
    @include m(end) {
      margin: 0 0 0 auto;
    }
  }
  @include b(info) {
    display: flex;
    flex-direction: column;
    @include u.below('sm') {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      width: 100%;
    }
    @include u.below('xs') {
      flex-direction: column;
      align-items: initial;
      justify-content: initial;
    }
    padding: 0 32px 0 0;
    @include e(title) {
      font-size: 32px;
      color: #333;
      margin: 0;
      display: flex;
      flex-direction: column;
      @include u.below('lg') {
        font-size: 30px;
      }
      @include u.below('md') {
        font-size: 28px;
      }
      @include u.below('sm') {
        min-width: 40%;
        display: inline;
        padding: 0 16px 0 0;
      }
      @include u.below('xs') {
        min-width: none;
        display: flex;
        padding: 0;
      }
    }
    @include e(block) {
      display: flex;
      flex-direction: column;
    }
    @include e(description) {
      font-size: 12px;
      color: #999;
      margin: 8px 0 16px;
      display: flex;
      flex-direction: column;
      @include u.below('sm') {
        display: inline;
      }
    }
    @include b(subscribe-mobile) {
      display: flex;
      @include u.below('md') {
        margin: 0;
      }
      @include e(link) {
        display: flex;
        &:not(:last-child) {
          margin: 0 16px 0 0;
        }
      }
      @include e(image) {
        height: 50px;
        width: auto;
        @include u.below('lg') {
          height: 45px;
        }
      }
    }
  }
  @include b(graphic) {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding: 32px 0;
    width: 100%;
    @include e(rect) {
      width: 600px;
      height: 240px;
      border-radius: 5px;
      @include u.below('lg') {
        width: 500px;
        height: 200px;
      }
      @include u.below('md') {
        width: 400px;
      }
      background-color: $optwear-orange-strong;
      display: flex;
      align-items: center;
    }
    @include e(picture) {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      height: 100%;
      width: 250px;
      box-sizing: content-box;
      @include u.below('lg') {
        width: 200px;
      }
      @include u.below('sm') {
        width: 150px;
      }
    }
    @include e(img) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
    }
    @include e(title) {
      @include u.below('lg') {
        font-size: 22px;
      }
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include u.below('lg') {
        padding: 0 200px 0 8px;
      }
      width: 100%;
      height: 100%;
      padding: 0 250px 0 16px;
      text-align: center;
    }
  }
}


