@import "@Scss/old/common/_variables.scss";
.lot {
		&-ryd {

				padding: 5px 0;
				&:not(:first-of-type) {
						border-top: 1px solid $color-text;
				}
				&__name {
						padding-left: 5px;
				}
				
				&__pos {
						&_empty {
								color: #aa0000;
						}
				}
				
				&__filledTitle {
						&_yes {
								padding-left: 5px;
						}
				}
		}
		
		&-image {
				position: relative;
				display: block;
				
				&:before {
						display: block;
						width: 100%;
						padding-top: 100%;
						content: "";
				}
				
				&--missing {
						display: block;
						max-height: none;
						border: 0;
						background: #f7f7f7 url("~@Assets/imageset-old/missing-photo.svg") center center no-repeat;
				}
				
				&-popupBtn {
						display: block;
						text-align: center;
						position: absolute;
						top: 50%;
						left: 50%;
						z-index: 1;
						box-sizing: border-box;
						width: 160px;
						height: 30px;
						border: 1px solid #c9c9c9;
						transform: translate(-50%, -50%);
						opacity: 0;
						visibility: hidden;
						padding: 0;
						background: rgba(255, 255, 255, 0.7);
						color: #2d2d2d;
						cursor: pointer;
						transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
						
						&:hover {
								background: rgba(255, 255, 255, 0.9) !important;
						}
				}
				
				&__counter {
						opacity: 0;
						position: absolute;
						bottom: 5px;
						left: 5px;
						font: 12px/1 Arial, sans-serif;
						text-align: center;
						color: #fff;
						display: flex;
						align-items: center;
						transition: 0.25s;
						
						&-digit {
								padding: 3px 5.5px 1px;
								border-radius: 10px;
								background-color: rgba(0, 0, 0, .4);
								vertical-align: bottom;
						}
				}
				
				
				&:hover &-popupBtn {
						opacity: 1;
						visibility: visible;
				}
		}
		
		&-title {
				display: block;
				display: -webkit-box;
				overflow: hidden;
				max-height: 72px;
				margin-bottom: 10px;
				text-overflow: ellipsis;
				font-size: 18px;
				line-height: 24px;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
		}
		
		&__tag {
				&-content {
						font-size: 13px;
						line-height: 24px;
						display: flex;
						height: 24px;
						padding: 0 8px;
						color: $optwear-white;
						border-radius: 2px;
						margin-bottom: 3px;
						box-shadow: -1px 3px 3px rgba(0, 0, 0, 0.2);
				}
				
				&-label {
						vertical-align: middle;
						display: block;
						min-width: 70px;
						text-align: center;
				}
				
				&_color_sale &-content {
						background-color: #29ad2e;
				}
				
				&_color_hit &-content {
						background-color: desaturate(#ff0000, 15%);
				}
				
				&_color_new &-content {
						background-color: desaturate(#0000ff, 15%);
				}
		}
		
		&__tag-group {
				position: absolute;
				top: 20px;
				left: 20px;
				z-index: 2;
		}
		
		&__favorites-delete {
				position: absolute;
				left: 250px;
				top: 20px;
				font-size: 20px;
				z-index: 2;
				color: grey;
		}
		
		&__attention-message {
				margin-bottom: 20px;
				padding: 10px 30px;
				
				&_red {
						background-color: #ff0000;
						color: $optwear-white;
						
						a:hover,
						a:focus,
						a:active {
								color: $optwear-white;
						}
				}
		}
}

.topic-post-orders-app-error {
		width: 300px;
		margin: 0 auto;
		padding-bottom: 20px;
		
		&__icon {
				width: 300px;
				height: 300px;
				fill: darken($color-background, 40%);
		}
		
		&__label {
				margin-top: 0;
				text-align: center;
		}
		
		&__errOutput {
				margin: 20px auto;
				width: calc(100% - 10px);
				resize: none;
				overflow: hidden;
				border-radius: 3px;
				padding: 5px;
				font-family: "Lucida Console", Monaco, monospace;
		}
		
		.btn {
				width: 100%;
		}
}

.lot-card-descItemPropItemValueList {
		> .lotCard-descItemPropItem__label, > .lot-card-descItemPropItem__label {
				position: relative;
				> .visuallyhidden {
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
				}
		}
}

@import "lot-card/lot-card";
@import "lot-row/lot-row";
@import "lot-old/lot-old";
@import "lot-table/lot-table";
@import "lot-empty/lot-empty";
@import "Lot--loading";

/* Карточка товара */
@import "lot-nav/lot-nav";
@import "lot-summary/lot-summary";
@import "lot-user-form/lot-user-form";
