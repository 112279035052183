@use "src/assets/scss/utils" as u;

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button {
  font-family: 'Roboto', Arial, sans-serif;
}

.svg-icon {
  fill: currentColor;
  width: 24px;
  height: 24px;
}

.m-icon-button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #333;
}

button {
  background-color: transparent;
}


.mobile-modal {
  @include u.below('md') {
    z-index: u.z-index('modal');
    box-sizing: border-box;
    padding: u.size('unit-8x') 0 u.size('unit');
    &.open {
      opacity: 1;
      visibility: visible;
      height: 100%;
      transition: opacity u.time('default') ease-out, visibility u.time('default') ease-out, height .5s step-start;
      //overflow-y: scroll;
      background-color: rgba(#000, .5);

      > .mobile-modal-wrapper {
        transform: translateY(0);
        transition: transform .25s ease-in-out;
      }
    }
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    height: 0;
    visibility: hidden;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: [full-start] minmax(0, 1fr) [content-start] #{u.size('page-lg')} [content-end] minmax(0, 1fr) [full-end];
    align-content: flex-end;
    @include u.below('lg') {
      grid-template-columns: [full-start] minmax(0, 1fr) [content-start] #{u.size('page-md')} [content-end] minmax(0, 1fr) [full-end];
    }
    @include u.below('md') {
      grid-template-columns: [full-start] #{u.size('unit')} [content-start] calc(100% - 16px) [content-end] #{u.size('unit')} [full-end];

    }

    > * {
      grid-column: content;
      //padding: 0 var(--grid-container-gutter);
    }
    transition: opacity u.time('default') ease-out .15s, visibility u.time('default') ease-out .15s, background-color u.time('default') ease-out .15s, height 1s step-end;
    @include u.mixins-block(wrapper) {
      display: flex;
      flex-direction: column;
      padding: u.size('unit-2x');
      background: u.var-color('white');
      border-radius: u.size('unit');
      overflow: hidden;
      transform: translateY(100%);
      transition: transform .25s ease-in-out;
      text-align: left;
      @include u.mixins-box-shadow-md(6);
      font-size: u.size('text-md');
      line-height: u.size('unit-5x');
      color: #333;
      @include u.below('sm') {
        padding: u.size('unit');
        font-size: u.size('text-sm');
      }
      a {
        color: #333;
      }
      &:not(:first-of-type) {
        margin: u.size('unit-2x') 0 0;
      }
      @include u.mixins-block(auto) {
        width: auto;
        margin: 0 auto;
        @include u.below('sm') {
          margin: 0;
        }
      }
      > *:not(.mobile-modal-close) {
        overflow-y: auto;
      }
    }
  }

  &-close {
    display: none;
    justify-content: center;
    background-color: u.var-color('grey-10');
    border-radius: u.size('unit');
    @include u.mixins-text-height(u.size('unit-5x'));
    border: none;
    color: u.var-color('secondary');
    margin: u.size('unit') 0 0;
    @include u.below('md') {
      display: flex;

    }
  }
}


@import "~normalize-scss/sass/normalize/import-now";
@import "~pretty-checkbox/src/pretty-checkbox.scss";
//@import 'lib';
//@import "../modules/fonts";
////@import "common/fonts";
//@import "common/variables";
//@import "common/a";
//@import "common/mixins";
//@import "common/global";
//@import "common/StandaloneMessage";
//@import "common/bbcode/bbcodeSpoiler.scss";
//@import "common/helpers";
////@import "blocks/headerSlim/SiteHeader";
////@import 'blocks/headerSlim/site-header';
//@import "blocks/headerSlim/Search";
//@import "pages/index/index";
//@import "blocks/list-purchases/list-purchases";
//@import "blocks/login/LoginAction";
//@import "blocks/login/LoginActionStandalone";
//@import "blocks/main/main";
////@import "blocks/SiteFooter/SiteFooter";
////@import "blocks/SiteFooter/site-footer";
//@import "blocks/Jumpbox/Jumpbox";
//@import "blocks/catalog/catalog-sort-types/catalog-sort-types";
//@import "blocks/catalog/catalog-display-types/catalog-display-types";
//@import "blocks/Lot/Lot";
//@import "blocks/pristroy/pristroy-item";
//@import "blocks/quantity-input/quantity-input";
//@import "blocks/gallery/gallery";
//@import "blocks/share/share";
//@import "blocks/calendar/calendar";
//@import "common/keyframes";
//@import "blocks/breadcrumbs/breadcrumbs";
//@import "blocks/forum-table-row/row";
//@import "blocks/bbcode/bbcode";
//@import "blocks/ucp/ucp";
//@import "blocks/forum-search/forum-search";
//@import "blocks/user-page/user-page";
//@import "blocks/register/register";
//@import "blocks/contact-us/contact-us";
//@import "blocks/diagnose/diagnose";
//@import "blocks/about-teaser/about-teaser";
////@import "pages/faq/faq";
//@import "pages/orser/orser";
//@import "pages/brands/brands";
.post-compose-error {
  font-size: 19px;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

@import "./sp";

@each $point, $value in u.$breakpoints {
  .below-#{$point} {
    @include u.below($point) {
      display: none !important;
    }
  }
}

@each $point, $value in u.$breakpoints {
  .above-#{$point} {
    @include u.above($point) {
      display: none !important;
    }
  }
}


.bb-content-scale {
  @include u.below('md') {
    font-size: 90%;
  }
  @include u.below('sm') {
    font-size: 80%;
  }
  @include u.below('sm') {
    font-size: 70%;
  }
}

.TopicPostMessage-body-scale {
  @include u.below('lg') {
    position: relative;
    &.scale {
      &:before {
        z-index: -1;
        opacity: 0;
        visibility: hidden;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      transition: opacity .25s ease-in-out .25s, visibility .25s ease-in-out .25s;
      z-index: u.z-index('before-header');
    }
  }
}

body {
  font-family: Roboto, Arial, sans-serif;
}


.substrate {
  padding: u.size('unit-2x');
  background: u.var-color('white');
  border-radius: u.size('unit');
  overflow: hidden;
  @include u.mixins-box-shadow-md(6);

  &:not(:first-of-type) {
    margin: u.size('unit-2x') 0 0;
  }

  @include u.below('sm') {
    padding: u.size('unit');
  }
}

.top-ads-block {
  width: 100%;
  margin: 0 0 u.size('unit-2x');
  @include u.below('md') {
    //margin: 0 0 u.size('unit-2x');
    display: none;
  }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.grid-full {
  grid-column: full
}

.sp-event-new-calendar-page {
  .TopicQuickReplyTextarea-input {
    width: 100%;
    box-sizing: border-box;
  }

  .fields1.message {

    > table {
      .pmlist {
        @include u.below('sm') {
          display: flex;
          flex-direction: column;
        }

        > td:nth-child(2) {
          @include u.below('sm') {
            display: flex;
            flex-direction: column;
          }
        }
      }


      tr {
        @include u.below('sm') {
          width: 100%;
          display: flex;
          flex-direction: column;

          input[type='text'], textarea {
            width: 100% !important;
          }
        }

        td {
          @include u.below('sm') {
            width: 100% !important;
          }
        }
      }
    }

  }
}

.reputation-page {
  .tablebg {
    @include u.below('xs') {
      thead {
        display: none;
      }

      display: flex;
      flex-direction: column;

      tbody {
        display: flex;
        flex-direction: column;

        tr {
          display: flex;
          flex-direction: column;

          > td {
            &:first-child {
              text-align: left;
              margin: 0 0 u.size('unit');

              &:before {
                content: 'От';
                font-size: u.size('text-md');
                color: #000;
                margin: u.size('unit') 0 u.size('unit-half');
                width: 100%;
                display: none;
                @include u.below('sm') {
                  display: block;
                }
              }
            }

            &:nth-child(2) {
              margin: 0 0 u.size('unit');

              &:before {
                content: 'Комментарий';
                font-size: u.size('text-md');
                color: #000;
                margin: u.size('unit') 0 u.size('unit-half');
                width: 100%;
                display: none;
                @include u.below('sm') {
                  display: block;
                }
              }
            }

            &:nth-child(3) {
              margin: 0 0 u.size('unit');

              &:before {
                content: 'Сообщения';
                font-size: u.size('text-md');
                color: #000;
                margin: u.size('unit') 0 u.size('unit-half');
                width: 100%;
                display: none;
                @include u.below('sm') {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ui-widget {
  font-family: Roboto, Arial, sans-serif !important;
}

.ForumListTable-lastMessage {
  font-size: 13px;
}

.catalog-old-page {
  .lot-card {
    width: calc(100% / 3) !important;
    margin-right: 0 !important;
    flex-grow: unset !important;
  }

  .lot-card__fav-btn {
    z-index: u.z-index('default');
  }

  .js-sticky-bottom {
    top: 0 !important;
  }

  .lot-card__fav-btn {
    @include u.below('md') {
      button {
        opacity: 1;
      }
    }
  }

  .lot-card {
    //overflow: initial;
  }
}

.ForumListTable-forumRow {
  @include u.below('sm') {
    border-bottom: 1px solid #c0c8d0;
  }

  td {
    @include u.below('sm') {
      border-width: 0;
    }
  }
}

.topicdetails {
  font-size: u.size('text-sm');
}

.lot-user-form__submit-wrapper {
  @include u.below('sm') {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 16px 8px;
    z-index: u.z-index('default');
    box-sizing: border-box;
  }
}

.lot-summary-large-description__container {
  overflow: hidden;
}

.lot-user-form-attr-list__item {
  position: relative;

  > .visuallyhidden {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ui-dialog.ui-widget.ui-widget-content {
  @include u.below('sm') {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}

.btn__error {
  background-color: u.var-color('danger') !important;
  color: #fff !important;
  border: none !important;
  transition: background-color .25s ease-in-out !important;
  @include u.mixins-hover {
    background-color: u.var-color('danger-dk') !important;
    color: #fff !important;
  }
}

.lot-user-form__error {
  background-color: u.var-color('danger') !important;
  transition: background-color .25s ease-in-out !important;
  @include u.mixins-hover {
    background-color: u.var-color('danger-dk') !important;
    color: #fff !important;
  }
}

.TopicPostFooter-button__postButtons {
  padding: 0 u.size('unit-half') !important;
}

//select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
//		@include u.below('md') {
//				font-size: 16px;
//		}
//}

.highslide-controls {
  @include u.below('md') {
    display: flex;
    justify-content: center;
  }

  .highslide-move {
    display: none !important;
  }

  .highslide-close {
    @include u.below('md') {
      display: none !important;
    }
  }
}

.shop-cart-page {
  body {
    font-size: 100%;
  }

  .highslide-wrapper {
    div[id^='hsId'] {
      @include u.below('md') {
        opacity: 1 !important;
        visibility: visible !important;
      }


    }
  }
}

#pr_layer {
  left: u.size('unit') !important;
}

html {
  &.document-block {
    height: 100vh;
    overflow: hidden;
  }
}

.reputation_body-page {
  textarea#comment {
    @include u.below('sm') {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}

.Login-formFields.LoginCaptcha {
  display: flex;
  flex-direction: column;

  .LoginCaptcha-code {
    margin: u.size('unit') 0 0;
  }
}

.lot-summary-description-text {
  .bbvideo {
    width: 100% !important;
    height: auto !important;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      padding-bottom: 50%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.index-banners {
  position: relative;
  width: 100%;
  margin: u.size('unit-2x') 0 0;
  min-height: 80px;

  @include u.below('md') {
    min-height: 30px;
  }

  @include u.below('md') {
    margin: u.size('unit') 0 0;
  }

  &-link {
    display: flex;
    align-items: flex-start;
    width: 100%;
    border-radius: u.size('unit');
    overflow: hidden;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &-desktop {
    @include u.below('xs') {
      display: none;
    }
  }

  &-mobile {
    @include u.above('xs') {
      display: none;
    }
  }

  &-pagination {
    visibility: hidden;
    display: flex;
    position: absolute;
    left: 50%;
    bottom: u.size('unit');
    justify-content: center;
    width: 80%;
    z-index: u.z-index('slider-button');
    @include u.below('lg') {
      width: 90%;
      pointer-events: none;
      bottom: u.size('unit-half');
      height: u.size('unit-half');
      background-color: rgba(0, 0, 0, .25);
      border-radius: u.size('unit-half');
      overflow: hidden;

      .swiper-pagination-progressbar-fill {
        background-color: rgba(u.get-color-value('primary'), .75);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: scale(0);
        transform-origin: left top;
      }
    }
    transform: translate(-50%, 0);

    .swiper-pagination-bullet {
      flex-shrink: 0;
      display: inline-block;
      @include u.mixins-box-shadow-md(3);
      @include u.square(u.size('unit-half-2x'));
      margin: 0 u.size('unit');
      @include u.below('lg') {
        width: 6px;
        height: 6px;
      }
      background-color: rgba(#fff, .5);
      cursor: pointer;
      border-radius: 100%;
      transition: background .25s ease-in-out;

      &.swiper-pagination-bullet-active {
        background-color: rgba(u.get-color-value('primary'), .75);
      }
    }
  }

  &-btn {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    display: flex;
    padding: u.size('unit-2x');

    svg {
      @include u.square(u.size('unit-4x'))
    }

    &-prev {
      left: u.size('unit');
    }

    &-next {
      right: u.size('unit');
    }
  }

  &--slider {
    .index-banners-pagination {
      visibility: visible;
    }

    .index-banners-btn {
      @include u.mixins-hover() {
        .index-banners-btn {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

}

.o_state-review {
  background-color: u.var-color('primary-lt');
  color: #fff;
}
