.lot-summary-info-purchase {
	&__progress {
		display: block;
		height: 100%;
		color: $optwear-white;
		background-color: rgb(43, 194, 83);
		position: relative;
		overflow: hidden;

		&_color_yellow > & {
			background: #ecd71d;
		}

		&_color_red > & {
			background: #a13232;
		}

		&-wrapper {
			height: 20px;
			margin: 5px 0;
			position: relative;
			overflow: hidden;
			background: $color-background;
			box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
		}

		&-value {
			padding-left: 10px;
			vertical-align: middle;
		}
	}

	&__users-counter {
		word-break: keep-all;
		white-space: nowrap;
	}

	&__rules:before, &__forum-topic:before {
		transition: opacity 75ms linear;
		opacity: 0.7;
		filter: alpha(opacity=70);
		float: left;
		content: "";
		width: 24px;
		height: 24px;
	}

	&__rules:before {
		background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 17H4v2h10v-2zm6-8H4v2h16V9zM4 15h16v-2H4v2zM4 5v2h16V5H4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
	}

	&__forum-topic:before {
		background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"/></svg>');
	}

	&__catalogs {
		padding-left: 10px;
		max-width: 250px;
		overflow: hidden;
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			.loader {
				margin-top: 30px;
			}
		}
	}
}
