@import "@Scss/old/common/_variables.scss";
.diagnose {
	font-family: "Lucida Console", Monaco, monospace;
	position: relative;
	outline: 0;
	box-sizing: border-box;
	padding: 5px;
	height: 400px;
	width: 100%;
	overflow: auto;
	color: #c0c0c0;
	background: #000000;
	white-space: pre-wrap;
	word-wrap: break-word;
	resize: none;
	z-index: 999;
}
