@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.register {
  font-family: $second-font-family;

  &.message,
  & + .message {
    //max-width: 420px;
    margin: 10px auto;
  }

  &__title {
    text-align: center;
  }

  &__submit {
    width: 100%;
  }

  .error {
    display: block;
    color: #f44e4e;
    padding-top: 4px;
    padding-bottom: 10px;
  }

  .message {
    max-width: 420px;
    padding: 10px;
    margin-bottom: 10px;
  }
}

@import "register-form";

.register-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include u.below('xs') {
    width: 100%;
  }
}
