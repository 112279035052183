.gallery__main-image {
	max-height: 100%;
	&-wrapper {
		height: 400px;
		cursor: pointer;
			@include u.below('md') {
			    height: auto;
			}
	}
}
