.ajax-status {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
		min-height: 75vh;
}

.cssload-container {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}

.cssload-container div {
	animation: cssload-container 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 32px 32px;
}

.cssload-container div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: $optwear-blue-light;
	margin: -3px 0 0 -3px;
}

.cssload-container div:nth-child(1) {
	animation-delay: -0.036s;
}

.cssload-container div:nth-child(1):after {
	top: 50px;
	left: 50px;
}

.cssload-container div:nth-child(2) {
	animation-delay: -0.072s;
}

.cssload-container div:nth-child(2):after {
	top: 54px;
	left: 45px;
}

.cssload-container div:nth-child(3) {
	animation-delay: -0.108s;
}

.cssload-container div:nth-child(3):after {
	top: 57px;
	left: 39px;
}

.cssload-container div:nth-child(4) {
	animation-delay: -0.144s;
}

.cssload-container div:nth-child(4):after {
	top: 58px;
	left: 32px;
}

.cssload-container div:nth-child(5) {
	animation-delay: -0.18s;
}

.cssload-container div:nth-child(5):after {
	top: 57px;
	left: 25px;
}

.cssload-container div:nth-child(6) {
	animation-delay: -0.216s;
}

.cssload-container div:nth-child(6):after {
	top: 54px;
	left: 19px;
}

.cssload-container div:nth-child(7) {
	animation-delay: -0.252s;
}

.cssload-container div:nth-child(7):after {
	top: 50px;
	left: 14px;
}

.cssload-container div:nth-child(8) {
	animation-delay: -0.288s;
}

.cssload-container div:nth-child(8):after {
	top: 45px;
	left: 10px;
}

@keyframes cssload-container {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
