@use "src/assets/scss/utils" as u;

.gallery__image-container {
  border: 1px solid #cccccc;
  background-color: white;
  border-radius: 3px;
  width: 400px;
  height: 400px;
  overflow: hidden;
  vertical-align: middle;
  @include u.below('md') {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    position: relative;
  }

  &_shadow {
    position: relative;
    float: left;
    border: 1px solid #dddddd;
    background: #ffffff;

    &:before {
      position: absolute;
      z-index: -2;
      content: "";
      bottom: 15px;
      left: 5px;
      width: 40%;
      height: 10%;
      box-shadow: 0 15px 6px rgba(0, 0, 0, 0.3);
      transform: rotate(-4deg);
    }
  }
}
