@use "src/assets/scss/utils" as u;

.advices-section {
  &__title {
    text-align: center;
    font-size: 35px;
    font-family: $bold-font-family;
  }

}

@import "index-section";
@import "categories-img";
@import "features";
@import "index-categories";
@import "index-seo-text";
@import "beginner";
@import "purchases";
@import "index-mobile-banner";

@import "index-categories-img";
@import "index-purchases-widget";
