.TopicPostFooter {
		display: flex;
		@include TopicPostWrap;
		box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
		&-no-auth {
			.TopicPostFooterPostActions {
					border: none;
			}
				.TopicPostFooter-button {
						min-height: u.size('unit');
				}
		}
		.TopicPostRightBlock {
				margin-right: 0;
		}
		
		@include u.below('md') {
				display: flex;
		}
		@include u.below('sm') {
				border-bottom: 0;
		}
		//min-height: 30px;
		
		&-button {
				min-height: 35px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				@include u.below('sm') {
						.TopicPostFooter-button--userAction {
								flex: 6;
						}
						.TopicPostFooter-button--postAction {
								flex: 2;
						}
						
						
						&--no-delete {
								.TopicPostFooter-button--userAction {
										flex: 8;
								}
								.TopicPostFooter-button--postAction {
										flex: 0;
								}
						}
						
				}
				

				
				&__profile {
						justify-content: center;
				}
				
				&--hoverable {
						@include btnHoverable;
						display: inline-block;
						@include u.above('sm') {
								color: darken($color-background, 35%) !important;
						}
						height: 35px;
						line-height: 35px;
						border-right: 1px solid $color-background;
						
						&:hover, &:focus, &:active {
								text-decoration: none;
						}
						
						&--warn:hover,
						&--warn:active {
								background-color: desaturate(#ff0000, 25%);
								color: white !important;
						}
				}
				
				&--userAction {
						display: inline-block;
						border-left: 1px solid $color-background;
						@include u.below('sm') {
								border-left: 0;
						}
				}
				
				&__postButtons {
						color: #727272 !important;
						padding: 3px 15px;
						display: inline-block;
						line-height: 30px;
						
						&:hover {
								text-decoration: none;
								color: $optwear-white !important;
								background-color: #727272;
								-webkit-transition: all .15s ease-in-out;
								transition: all .15s ease-in-out;
						}
						
						&:last-child {
								padding-right: 20px;
						}
				}
		}
}

.TopicPostFooterProfileActions {
		@include u.below('md') {
				display: none;
		}
}

.TopicPostFooterPostActions {
		margin: 0 0 0 219px;
		border-left: 1px solid #cbcbcb;
		padding: 0 5px;
		box-sizing: border-box;
		@include u.below('md') {
				margin: 0;
				border-left: none;
		}
		@include u.below('sm') {
				padding: 0;
				overflow: hidden;
		}
}

.TopicPostFooter-button--postAction, .TopicPostFooter-button--userAction {
		@include u.below('sm') {
				display: flex;
				flex: 9;
				a, .TopicPostFooter-button__postButtons {
						background: #a7abaf;
						@include u.mixins-text-height(40px);
						margin: 0;
						padding: 0;
						display: flex;
						flex: 1;
						align-items: center;
						border: none;
						justify-content: center;
						border-radius: 0;
						color: #fff;
						
						svg {
								top: auto !important;
								path {
										fill: #fff !important;
								}
						}
						
						border-right: 1px solid #d9d9d9;
						&:hover, &:focus {
								background-color: #a7abaf;
						}
				}
		}
}

.TopicPostFooter-button--userAction {
		@include u.below('sm') {
				order: -1;
		}
}

.TopicPostFooter-button--postAction {
		@include u.below('sm') {
				a:last-child {
						border-right: 0;
				}
		}
}