@import "@Scss/old/common/_variables.scss";
@import "@Scss/old/common/_mixins.scss";
.quantity-input {
	display: flex;
	position: relative;
	vertical-align: middle;
	border: 1px solid $color-background;
	border-radius: 5px;
	height: 30px;
	background-color: $optwear-white;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	&__button {
		@include button-reset;
		padding: 5px;
		&:first-child {
			padding-left: 10px;
		}
		&:last-child {
			padding-right: 10px;
		}
		&_minus {
			vertical-align: text-bottom;
		}
		&:focus,
		&:active,
		&:hover {
			outline: none;
		}
		&[disabled] &-label {
			color: $color-text-weak;
		}
	}

}

input[type=number].quantity-input__input {
	margin: 0;
	padding: 0;
	width: calc(100% - 50px);
	border: none;
	display: block;
	height: 100%;
	text-align: center;
	&:focus,
	&:active {
		outline: none;
		border: 1px solid $optwear-orange-middle;
	}
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&:invalid {
		outline: 1px solid #ff0000;
	}
}
