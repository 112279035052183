@use "src/assets/scss/utils" as u;

.features {
  background-color: $index-bg-color;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      padding-left: calc(40px + 1em);
      max-width: 30%;

      a {
        text-decoration: none;
        color: $optwear-blue-light;
        transition: all 0.25s;

        &:link, &:visited {
          color: $optwear-blue-light;

        }

        &:hover, &:focus, &:active {
          outline: none;
          color: darken($optwear-blue-light, 15%);
          border-bottom: 1px solid $optwear-blue-light;
        }
      }
    }
  }

  &__icon {
    position: relative;

    &:before {
      position: absolute;
      display: block;
      content: '';
      left: 0;
      top: 10px;
      width: 40px;
      height: 40px;
    }

  }

  &__icon_secure:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-38 0 512 512' width='40'%3E%3Cpath d='M435 139v-16c0-29-23-52-51-54-59-3-104-22-143-60h-1a33 33 0 0 0-44 0h-1c-39 38-84 57-143 60-28 2-51 25-51 54v16c-2 60-3 135 22 203 14 37 34 70 62 96a324 324 0 0 0 133 74 37 37 0 0 0 12-2c49-17 90-41 121-72 27-26 48-59 62-96 25-68 24-143 22-203zm-50 193a248 248 0 0 1-169 150c-85-30-139-79-165-150-23-63-22-131-20-192v-17c0-12 10-23 23-24a234 234 0 0 0 162-69l4 1a234 234 0 0 0 162 68c13 1 23 12 23 24v17c2 60 3 129-20 192zm0 0'/%3E%3Cpath d='M218 128a128 128 0 1 0 0 256 128 128 0 0 0 0-256zm0 226a98 98 0 1 1 0-196 98 98 0 0 1 0 196zm0 0'/%3E%3Cpath d='M255 216l-60 60-16-16a15 15 0 0 0-21 21l27 27a15 15 0 0 0 21 0l70-70a15 15 0 0 0-21-22zm0 0'/%3E%3C/svg%3E");
  }

  &__icon_items:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' viewBox='0 0 201 201'%3E%3Cpath d='M129 25a29 29 0 0 0-52-13 3 3 0 1 0 6 4c4-6 11-9 18-9 11 0 20 8 22 19a3 3 0 0 0 6-1zM179 63l-11-17-2-2h-10a3 3 0 0 0 0 7h8l6 10H32l6-10h3a3 3 0 0 0 0-7h-5l-3 2-10 17a3 3 0 0 0 3 5h150a3 3 0 0 0 3-5z'/%3E%3Cpath d='M162 32l-2-2-47-11a3 3 0 0 0-4 3l-4 15a3 3 0 1 0 7 1l3-11 40 9-7 27a3 3 0 1 0 7 1l7-30v-2zM102 40l-3-27a3 3 0 0 0-3-3l-57 7c-2 0-4 2-3 4l5 44a3 3 0 1 0 6-1l-4-40 50-6 3 22a3 3 0 0 0 6 0z'/%3E%3Cpath d='M129 64l-6-29a3 3 0 0 0-4-2L64 46c-2 1-3 2-3 4l3 14a3 3 0 1 0 7-1l-2-11 48-12 6 25a3 3 0 1 0 6-1z'/%3E%3Cpath d='M179 65c0-2-1-4-3-4H26c-2 0-4 2-4 4l-7 133a3 3 0 0 0 4 3h164a3 3 0 0 0 3-3l-7-133zM22 195l7-127h144l6 127H22z'/%3E%3Cpath d='M126 75a10 10 0 1 0 0 21 10 10 0 0 0 0-21zm0 14a3 3 0 1 1 0-7 3 3 0 0 1 0 7zM75 75a10 10 0 1 0 0 21 10 10 0 0 0 0-21zm0 14a3 3 0 1 1 0-7 3 3 0 0 1 0 7z'/%3E%3Cpath d='M126 85a3 3 0 0 0-3 5v18a22 22 0 0 1-44 0V89a3 3 0 0 0-7 0v19a29 29 0 0 0 58 0V89c0-2-2-4-4-4z'/%3E%3C/svg%3E");
  }

  &__icon_delivery:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1' viewBox='0 0 491 491'%3E%3Cpath d='M402 323a45 45 0 0 0-46 45c0 12 5 23 14 32a45 45 0 0 0 77-32c-1-25-21-45-45-45zm0 70c-14 0-26-11-26-25s12-25 26-25c13 0 24 11 24 25s-11 25-24 25zm11-220c-2-2-4-3-7-3h-51c-5 0-10 5-10 10v82c0 6 5 10 10 10h82c5 0 10-4 10-10v-54c0-3-2-6-4-8l-30-27zm14 79h-62v-62h38l24 22v40zm-270 71a45 45 0 0 0-45 45c0 12 5 23 13 32a45 45 0 0 0 77-32c0-25-20-45-45-45zm0 70c-13 0-25-11-25-25s12-25 25-25c14 0 25 11 25 25s-11 25-25 25zm-66-58H71v-26c0-6-5-10-10-10s-10 4-10 10v36c0 6 4 10 10 10h30c5 0 10-4 10-10s-5-10-10-10zm50-54c0-6-4-10-10-10H10c-5 0-10 4-10 10s5 10 10 10h121c6 0 10-5 10-10zM30 245h122c5 0 10-4 10-10 0-5-5-10-10-10H30c-5 0-10 4-10 10 0 5 5 10 10 10zm21-46h121c6 0 10-4 10-10s-4-10-10-10H51c-6 0-10 5-10 10s4 10 10 10zm436-13l-71-60-7-2h-84V88c0-5-4-10-10-10H61c-6 0-10 5-10 10v73c0 6 4 10 10 10s10-4 10-10V98h234v237h-82c-5 0-10 5-10 10s5 10 10 10h122c6 0 10-4 10-10s-4-10-10-10h-20V144h81l65 54-1 137h-10c-5 0-10 5-10 10s5 10 10 10h20c6 0 10-4 10-10l1-151c0-3-1-6-4-8z'/%3E%3C/svg%3E");
  }
}
