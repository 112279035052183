@use "src/assets/scss/utils" as u;

.ucp-posting-to {
  position: relative;
  margin-left: 0;
  flex-grow: 2;
  overflow: hidden;
  @include u.below('md') {
    width: 100%;
    box-sizing: border-box;
  }

  &__inputBlock {
    position: relative;
    //padding-bottom: 3px;
    min-height: 32px;
    @include u.below('md') {
      flex-wrap: wrap;
      display: flex;
      //min-height: 32px;
      align-items: center;
      padding: 5px 0;
    }

    &_loading:after {
      top: 0;
      right: 25px;
      content: " ";
      display: block;
      position: absolute;
      margin: 10px;
      height: 16px;
      width: 16px;
      animation: rotate-center 0.8s infinite linear;
      border: 2px solid $optwear-blue-light;
      border-right-color: transparent;
      border-radius: 50%;
    }

  }

  .token {
    background-color: $optwear-white;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    margin: 7px 5px 0 0;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.12);
    @include u.below('md') {
      display: flex;
    }

    &_title {
      display: inline-block;
      vertical-align: top;
      color: #55677d;
      font-size: 12.5px;
      line-height: 18px;
      padding: 4px 6px 3px 11px;
      text-transform: none;
      transition: all 0.25s;
      white-space: nowrap;

      a:hover, a:focus {
        text-decoration: none;
        opacity: 0.8;
      }
    }

    &_del {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      padding: 4px 5px;
      margin-left: 2px;
      opacity: 0.6;
      filter: alpha(opacity=60);
      border: 0;
      width: 18px;
      transition: opacity 0.2s ease;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      background-color: $optwear-white;

      &:hover, &:focus {
        font-weight: 700;
        outline: none;
      }
    }
  }

  &_active {
    border-bottom: 1px solid #e7e8ec;
    background-color: $optwear-white;
    padding-left: 10px;

    .token {
      background-color: #dae2ea;
      border: none;

      &_del {
        border: none;
        background-color: transparent;
      }
    }
  }

  &__multiselect {
    display: flex;
    flex-wrap: wrap;
  }

  &.has_selection &__multiselect {
    display: inline;
    vertical-align: top;
  }

  &__textarea {
    font-size: 13px;
    outline: none;
    box-shadow: none;
    border: none;
    line-height: 18px;
    box-sizing: border-box;
    color: $color-text;
    border-left: 20px solid transparent;
    border-left-width: 0;
    background: none;
    position: relative;
    margin: 0;
    vertical-align: top;
    //top: 2px;
    padding: 15px 0;
    display: inline-block;
    width: 100%;
    resize: none;
    height: 48px;
    overflow: hidden;
    //@include u.below('md') {
    //    padding: 10px 0 5px 0;
    //		height: 100%;
    //}
  }

  &-suggestions {
    border-bottom: 1px solid #e7e8ec;
    background-color: $optwear-white;
    position: relative;
    margin-left: 87px;
    @include u.below('md') {
      margin: 0;
    }

    &__control {
      margin: 0 0 0 10px;
      pointer-events: none;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__item {
      display: flex;
      padding: 10px 20px;
      align-items: center;
      border-top: 1px solid #e7e8ec;
      transition: opacity 0.25s;
      cursor: pointer;
      @include u.below('md') {
        padding: 5px 10px;
      }

      &:hover, &:focus {
        border-top: 1px solid #e7e8ec;
        background: #f0f2f5;
        outline: none;
      }

      img {
        display: block;
        overflow: hidden;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        object-fit: cover;
      }

      .pretty .state label {
        &:after, &:before {
          top: 0;
        }
      }

      &_disabled {
        opacity: 0.5;
      }
    }

    &__username {
      margin: 0;
      //flex-grow: 2;
      flex: 1;
      font-weight: 300;
      display: flex;
      overflow: hidden;
      width: 1px;

      &-wrapper {
        width: 100%;
        overflow: hidden;
      }

      &-link {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;

        a {
          pointer-events: none;
        }
      }
    }

    &__avatar {
      min-width: 66px;
      @include u.below('md') {
        min-width: 42px;
      }
      @include u.below('xs') {
        display: none;
      }
    }

    &__group {

      background-color: darken(#eaebec, 25%);
      color: $optwear-white;
      border-radius: 5px;
      float: right;
      margin: 0 0 0 auto;

      span {
        padding: 2px 6px;
      }

      @include u.below('md') {
        font-size: 14px;
      }
    }
  }
}


.posting-page {

  @include u.below('sm') {
    * {
      box-sizing: border-box;
    }

    input.inpt {
      width: 100% !important;
    }

    textarea[name='poll_option_text'] {
      width: 100% !important;
    }
  }

  .TopicQuickReplyCheckboxes {
    @include u.below('sm') {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0 0;
      background-color: #a7abaf;
      border-color: #a7abaf;

      .TopicQuickReplyCheckboxes-notify {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: #fff;
        }
      }
    }

  }

  .message {
    @include u.below('sm') {
      > table {

        tbody {
          display: flex;
          flex-direction: column;
          width: 100%;


          input.text {
            width: 100% !important;
          }

          > tr {
            display: flex;
            flex-direction: column;
            width: 100%;

            &:nth-child(2) {
              margin: u.size('unit') 0 0;

              * {
                box-sizing: border-box;
              }
            }

            > td {
              width: 100%;
            }
          }
        }
      }
    }

  }

  .tablebg.ucp {
    @include u.below('sm') {


      tr:nth-child(2) {
        table.tablebg {

          .btn.btn--bluePlain {
            width: 100%;
            margin-top: u.size('unit-2x');
          }

          > tbody {
            display: flex;
            flex-direction: column;
            width: 100%;

            > tr {
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: u.size('unit');
              box-sizing: border-box;

              td {
                display: flex;
                width: 100%;
              }

              table {
                width: 100%;

                @include u.below('xs') {
                  .gensmall {
                    display: inline;
                  }
                }

                .quotetitle {
                  margin: 10px 0 0;
                  box-sizing: border-box;
                }

                td {
                  padding: 0;
                }

                .quotecontent {
                  margin: 0 0 10px;
                  box-sizing: border-box;
                }
              }
            }

            > tr:first-child {
              display: none;
            }

          }
        }
      }
    }
  }
}
