.lot-summary-price {
  .lot-user-form__item-title {
    padding-top: 20px;
  }
  &__text {
    font-size: 28px;
    font-weight: 700;
    margin-right: 5px;
    color: $optwear-orange-strong;
    &__unit {
      display: inline-block;
      font-size: 14px;
      color: #999999;
    }
  }
}
