@import "common/variables";
@import "lib/noty/noty";
@import "lib/dcmegamenu";
@import "lib/date_input.scss";
@import "lib/highslide";
@import "lib/fancybox";
@import "lib/drift-zoom";
//@import "lib/custom-theme/jquery-ui/jquery-ui";
@import "lib/select2";
@import "lib/spectrum";
@import "lib/jwhelp";
.select2 {
	.selection:focus {
		outline: none;
	}
	.select2-selection:focus {
		outline: none;
		border: 1px solid $optwear-blue-strong;
	}
}

@import "lib/megamenu";


