@use "src/assets/scss/utils" as u;

.TopicQuickReplyButtonsText {
  background-color: #efefef;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: baseline;
  font-family: $decor-font-family;

  &Size {
    display: inline-block;
    margin: u.size('unit');
  }

  &Color {
    .btn {
      height: 26px;
      width: 26px;
      padding: 0;
      margin: 0;
      transition: all 0.25s;

      &:hover {
        outline: none;
        transform: rotate(90deg);
        border-radius: 0;
        margin: 0;
      }
    }
  }

}
