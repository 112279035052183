@use "src/assets/scss/utils" as u;

.ucp-navbar {
  //max-width: 240px;
  //min-width: 240px;
  padding: 8px 0;
  width: 100%;
  overflow-x: hidden;

  @include u.above('md') {
    font-size: 14px;
  }
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  user-select: none;
  margin-bottom: 20px;
  @include u.below('md') {
    display: flex;
    max-width: none;
    flex: 1;
    order: 1;
    padding: 0;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
  }

  &__icon {
    margin: 0 8px 0 0;
    color: #333;
  }

  &__item-count {
    //position: absolute;
    //right: 0;
    //top: 0;
    padding: 0 0 0 10px;
    margin: 0 0 0 auto;
    color: #333;
    @include u.below('md') {
      display: flex;
      //position: absolute;
      //bottom: -10px;
      //font-size: 12px;
      //color: #fff;
      //padding: 0;
      //margin: 0;
    }
  }

  &__title {
    display: block;
    text-align: center;
    border-bottom: 3px solid #f2f2f2;
    padding-bottom: 5px;
    @include u.below('md') {
      display: none;
    }

    &_selected {
      font-weight: 500;
      //font-family: $bold-font-family;
    }

    a:hover, a:active, a:focus {
      text-decoration: none;
    }
  }

  &__items {

    @include u.below('md') {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }

    > .ucp-navbar__item {
      &--inbox, &--success {
        @include u.below('md') {
          .ucp-navbar__icon {
            display: none;
          }
          line-height: u.size('unit-4x');
          padding: 0;
          display: flex;
          border: 1px solid $optwear-blue-strong;
          border-radius: 5px;
          flex: 1;
          &.ucp-navbar__item_selected {
            background-color: $optwear-blue-strong;

            > .link {
              color: #fff;
            }
          }
          > .link {
            padding: 0 5px;
            justify-content: center;
            color: $optwear-blue-strong;

            .ucp-navbar__item-count {
              padding: 0;
              color: currentColor;
              margin: 0 0 0 5px;

              &:before {
                content: '(';
              }

              &:after {
                content: ')';
              }

            }
          }
        }
      }

      @include u.below('md') {


        &--inbox {
          margin: 0 8px 0 0;
        }

      }
    }

  }

  &__items, &__sub-items {
    list-style: none;
    margin: 0;
    padding: 0;

    &_additional {
      border-top: 3px solid #f2f2f2;
      padding-top: 2px;
      @include u.below('md') {
        border: none;
        padding: 0;
      }
    }
  }

  &__item {
    line-height: 30px;
    position: relative;
    padding: 0 8px;
    @include u.below('md') {
      padding: 0;
      line-height: u.size('unit-5x');
    }

    &-hidden {
      &-btn {
        margin: 0 0 0 8px;
      }

      &-list {
        list-style: none;
        padding: 0;
        margin: 0;

      }

      //> ul {
      //		list-style: none;
      //		padding: 0;
      //		margin: 0;
      //		@include u.below('md') {
      //				position: absolute;
      //				opacity: 0;
      //				visibility: hidden;
      //				transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
      //		}
      //}

    }

    &:hover, &:focus-within, &:focus, &_selected {
      background-color: #f2f2f2;
      @include u.below('md') {
        font-weight: 700;
        background-color: initial;
      }
      //@include u.below('md') {
      //		background-color: $optwear-orange-middle;
      //}

    }

    &_selected {
      color: $optwear-blue-strong;
      //font-family: $bold-font-family;
    }

    .link {
      //padding-left: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover, &:active, &:focus {
        text-decoration: none;
      }
    }
  }

}
