@use "src/assets/scss/utils" as u;

@import "../../common/mixins";
@import "~foundation-sites/scss/foundation";
@import '@Scss/old/common/_variables.scss';

@include foundation-reveal;
//@import "catalog-sort-types/catalog-sort-types";
@import "catalog-container/catalog-container";
@import "catalog-lot-list/catalog-lot-list";
@import "catalog-pagination/catalog-pagination";
//@import "catalog-display-types/catalog-display-types";
//@import "catalog-purchase-info/catalog-purchase-info";
@import "catalog-ryd/catalog-ryd";
@import "catalog-flters";
@import "catalog-animations";
@import "catalog-modal";
@import "catalog-breadcrumbs";
.catalog {
  font-size: 16px;
  width: 100%;


  .lot-summary-large-description {

    margin: 20px 44px 0;
    background: #fff;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: calc(100% - 108px);

    &__container {
      p {
        font-size: 0.925rem;
        line-height: 1.225rem;
      }
    }
  }

  .suggested-words {
    display: inline-block;
    margin: 0 10px 10px 0;
    border: 1px solid #d5d5d5;
    transition: border-color .5s;
    padding: 10px;

    &:hover {
      border-color: #a2a2a2;
    }
  }

  [type=checkbox] + label, [type=radio] + label {
    margin: 0 !important;
  }

  .blocked {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  h1 {
    font-size: 20px;
  }

  .total-length {
    white-space: nowrap;
    padding-right: 15px;

    span {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .vertical-filter-list {

    color: #767676;
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    padding: 5px 0;
    position: relative;
    text-transform: uppercase;
    width: auto;
    margin-right: 11px;

    &__title {
      color: #767676;
      font-size: 12px;
      position: absolute;
      top: 10px;
    }

    & > ul {
      list-style: none;
      margin: 0;
      padding: 0 0 0 90px;

      li {
        color: #272727;
        display: inline-block;
        margin-right: 15px;
      }
    }

    label {
      position: relative;
      display: inline-block;

      &:after {
        content: 'X';
        font-size: 11px;
        display: block;
        position: absolute;
        right: 0;
        width: 17px;
        height: 17px;
        top: 3px;
        color: #505050;
        background: #e8e8e8;
        border-radius: 9px;
        text-align: center;
        cursor: pointer;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin-right: 1em;
          display: inline-block;
        }
      }
    }

    input[type=checkbox] {
      opacity: 0;
      margin: 0;
      width: 17px;
      margin-left: 10px;
    }
  }

  .ForumListNav-wrapper {
    display: flex;
    align-items: center;

    ul {
      list-style: none;
      margin: 0;
      line-height: 1;
      padding: 0;

      li {
        display: inline-block;
      }
    }
  }

  .ForumListNav {
    padding: 0;
    margin: 0;
    border-right: 0;
    display: flex;
    border-left: none !important;

    a, &-curPage {
      text-decoration: none;
      float: left;
      display: inline-block;
      margin: 0;
      padding: 1px 10px;
      line-height: 33px;
      color: #000;


      @include u.above('md') {
        border: none !important;
      }

      &:focus,
      &:active,
      &:hover {
        background-color: rgba(#d8d8d8, 0.2);
      }

      &:link {
        @include u.above('md') {
          text-decoration: none;
          color: #000;
          border: none !important;
        }
      }
    }

    &-link {
      font-size: 18px;
      border: none !important;
      transition: border-color .3s;

      &_btn {

        height: 37px;
        display: block;

        &:last-child {
          margin-right: 0 !important;
        }
      }
    }

    &-curPage {
      font-size: 18px;
      position: relative;
      display: inline-block;
      font-weight: bold;
      @include u.below('sm') {
        font-size: u.size('text-sm');
      }

      &:focus,
      &:active,
      &:hover {
        background-color: $optwear-white;
      }
    }

    &__goto-page {
      position: relative;
      display: inline;

      &-oneliner {
        display: flex;
      }

      .ForumListNav-link {
        float: left;
        display: inline-block;
        margin: 0;
        padding: 1px 10px;
        line-height: 33px;
      }

      svg {
        position: relative;
        top: 4px;
      }
    }

    &__goto-page-popup {
      z-index: u.z-index('default');
      position: absolute;
      bottom: 0;
      background: $optwear-white;
      padding: 5px;
      border: 1px solid $color-background;

      .inpt {
        min-width: 106px;
        margin-right: 5px;
      }
    }
  }

  .pretty .state label {
    word-break: break-word;
    white-space: normal;
    padding-left: 1.5rem;
    text-align: left;
    text-indent: 0;
    line-height: 1.2rem;

    &:after, &:before {
      top: calc(50% - 8px);

    }
  }

  .pretty {
    margin-right: 2em;
    padding: 0.5rem 0;
  }

  .filters-list {
    .pretty .state label {
      white-space: normal;

      &:after, &:before {
        top: 5px;
      }
    }
  }

  &-actions {
    margin-top: 20px;

    .btn {
      width: 100%;
    }
  }

  .pretty input:checked ~ .state.p-success-o label:before {
    border-color: $optwear-blue-light;
  }

  .pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
    background-color: $optwear-blue-light !important;
  }

  &__label {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
    line-height: 45px;
  }

  .breadcrumbs {
    li:before, ul li:before {
      content: "";
      padding: 0;
    }

    a, &-item:last-child a {
      color: $color-background;
      font-weight: 300;
      transition: 0.25s color;

      &:hover, &:focus {
        color: $color-text-link-hover;
        text-decoration: none;
      }
    }
  }

  .lot-card {
    border-top: none;
  }

  .Search-box {
    padding: 0;
    width: 292px;
    display: flex;
    align-items: center;

    input[type="search"] {
      border: none;
      box-shadow: none;
    }

    &-clear {
      display: block;
      top: 5px;
      left: calc(100% - 55px);
      font-size: 1.8em;

      &:focus {
        outline: none;
        color: $optwear-blue-strong;
      }
    }
  }

  .slider:before {
    background-color: transparent;
  }

  .slider {
    height: 5px;
    margin: 10px 15px;
    margin-left: 0;

  }

  .slider-handle {
    width: 20px;
    height: 20px;
    top: 3px;
    left: 0;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #cdcdcd;
    transition: background 100ms ease-in, border-color 100ms ease-in;
    box-shadow: none;

    &:hover, &:focus {
      border-color: $optwear-blue-light;
      outline: none;
    }
  }

  .slider-fill {
    height: 5px;
    background-color: $optwear-blue-light;
  }

  &-catalogs-list {
    border: none;
    padding: 1em 0 0 0 !important;
    overflow-y: auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__btn-list {
      display: flex;
      justify-content: space-between;
      margin: 1em 0;
      position: absolute;
      min-width: 100%;
      bottom: -50px;
      background: rgb(228, 228, 228);
      border-radius: 5px;
      padding: 1em 10px;
    }

    &__controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__container {
      position: relative;
      border: none;
      padding: 0 10px 20px 20px;
      overflow-y: auto;
    }

    &__item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 7px;
      position: relative;

      label {
        line-height: 1.2;
      }

      input {
        margin-bottom: 0;
      }
    }

    &-props > li:first-child {
      border-top: 2px solid #ccc;
      padding-top: 0.875em;
      margin-top: 0.875em;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: rgba(0, 0, 0, 0);
      -webkit-border-radius: 100px;
    }

    &::-webkit-scrollbar:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }

    &::-webkit-scrollbar-thumb:vertical {

      background: rgba(0, 0, 0, 0.5);
      -webkit-border-radius: 100px;
      background-clip: padding-box;
      border: 2px solid rgba(0, 0, 0, 0);
      min-height: 10px;
    }

    &::-webkit-scrollbar-thumb:vertical:active {
      background: rgba(0, 0, 0, 0.61);
      -webkit-border-radius: 100px;
    }

    &__title {
      font-weight: bold;
      margin: 0;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__item-total {
      color: #5f6368; // color-text-weak
      font-size: 0.75rem;
      position: absolute;
      right: 5px;
    }
  }

  .main {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
  }
}

.left {
  display: inline-block;
  width: 25%;
  min-height: 1px;
  vertical-align: top;
}

.left-item {
  border-top: 2px solid #cccccc;
  padding-top: 20px;
  padding-bottom: 20px;
}

.catalog-meta {
  &__label {
    margin-top: 2px;
    font-size: 14px;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    outline: none;

    &:focus {
      color: red;
    }
  }

  &-list {
    padding: 0 15px;
    max-height: 500px;
    overflow-y: auto;
  }

}

.reset-btn {
  display: inline-block;
  vertical-align: middle;
}

.search-filter-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 1em 0;

  &:hover .filter-title, &:focus .filter-title {
    color: #1779ba;
  }

  &_icon {
    transform: rotate(-90deg);
    transition: all 0.25s;
    fill: #000;

    &-opened {
      transform: none;

      path {
        fill: #1779ba;
      }
    }
  }
}

.filter-title {
  font-weight: bold;
  line-height: 18px;
  color: #272727;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.catalog-meta-description-list {
  margin: 0;
  padding: 0;
  margin-left: 10px;
}

.link {
  text-decoration: none;

  &:focus, &:hover {
    color: red;
  }
}

.catalog-meta-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.catalog-category {
  &__list {
    list-style: none;
    padding: 0;
  }

  &__label {
    margin-top: 0;
  }

  &-content {
    margin-left: 10px;
  }
}

.catalog-category--selected {
  display: block;
  color: black;
  font-weight: bold;
}

.catalog-filter__btn {
  border: 1px solid $color-background;
  border-radius: 5px;

  &_color {
    &-black {
      background-color: #000000;
    }

    &-red {
      background-color: #ff0000;
    }

    &-green {
      background-color: #00ff00;
    }

    &-blue {
      background-color: #0000ff;
    }
  }
}

.search-filter-details {
  input[type=text] {
    width: 100%;
    margin-bottom: 1em;
    height: 1.6rem;
  }

  button {
    color: #1779ba;
    transition: all 0.25s;

    &:focus, &:hover {
      outline: none;
      color: #ff0000;
    }
  }
}

.showMoreBtn {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 50px;
  line-height: 42px;
  color: #666 !important;
  border: 1px solid rgba(0, 0, 0, .2);
  background-clip: padding-box;
  transition-property: border-color, border-width, background-color;
  background-color: transparent;
  transition: .1s ease-out;
  overflow: hidden;
  z-index: 9999999;

  &:hover, &:focus, &:active {
    color: #666 !important;
    border: 1px solid rgba(0, 0, 0, .5);
    outline: none;
  }

  &:active {
    background-color: rgba(0, 0, 0, .05);
  }
}

.catalog-filters-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lot-card-addOrder-btn[data-v-fea1343c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px
}

.lot-user-form__add-to-favorites[data-v-fea1343c] {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}

.lot-card-price[data-v-fea1343c] {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2
}

.lot-card-content[data-v-fea1343c] {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2
}

.lot-card-content[data-v-fea1343c], .lot-card-content form[data-v-fea1343c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.lot-card-content form[data-v-fea1343c] {
  -webkit-box-flex: 3;
  -ms-flex-positive: 3;
  flex-grow: 3
}

.lot-card-content form .lot-card-desc[data-v-fea1343c] {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2
}

.lot-card-addOrder__btn {
  margin: 0;
}

.load-more {
  display: flex;
  width: calc(100% - 40px);
  margin-top: 20px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #ccc;
}

