.lot-user-form-attr-list {
	padding: 0;
	margin: 0;
}

.lot-user-form-attr-list__item {
	float: left;
	list-style: none;
	margin-bottom: 3px;
	margin-right: 3px;
	&-title {
		float: left;
		overflow:hidden;
		border: 3px solid #e9e9e9;
		line-height: 28px;
		height: 30px;
		padding: 0 10px;
		color: #333333;
		border-radius: 3px;
		background-color: #ffffff;
		text-align: center;
	}
	&:hover &-title,
	&:hover &-title,
	&:focus &-title,
	input:checked + &-title {
		border: 3px solid $optwear-orange-middle;
		border-radius: 3px;
		padding: 0 10px;
		text-decoration: none;
	}
}
