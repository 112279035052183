@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.search-forum-page {
  .forum-search {
    th {
      background: #2c90be;
      color: $optwear-white;
      padding: 10px 0;
    }

    p {
      margin: 0;
    }

    .tablebg {
      background-color: $color-background;
      @include u.below('sm') {
        background-color: transparent;
        tr {
          &:first-child {
            display: none;
          }

          border: 1px solid u.var-color('grey-50');
          margin: 0 0 u.size('unit');
        }
      }
    }
  }
}

.search-forum-results-page {

  input[name="add_keywords"] {
    @include u.below('sm') {
      box-sizing: border-box;
      width: 100%;
    }

    + .btnlite {
      @include u.below('sm') {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  .titles:first-child {
    @include u.below('sm') {
      margin: 0 0 u.size('unit');
    }
  }

  .ForumListAdsContent .tablebg {
    background-color: $color-background;

    > tbody {
      @include u.below('sm') {
        background-color: transparent;

        .postbody {
          word-break: break-all;
        }

        img {
          max-width: 100%;
        }

        > tr {
          &:first-child {
            display: none;
          }

          border: 1px solid u.var-color('grey-50');
          margin: 0 0 u.size('unit');

          > td {
            height: auto !important;
            width: 100% !important;
            text-align: left;

            .topictitle {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
