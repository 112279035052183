@use "src/assets/scss/utils" as u;

.index-categories {
  width: 100%;
  margin: 0 0 u.size('unit-4x');
  @include wrapper;
  @include e(content) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @include b(nav) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include b(block) {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    @include b(list) {
      display: flex;
      flex-direction: column;
      margin: 0;
      list-style: none;
      padding: 0 8px 0 0;
      @include u.below('md') {
        display: none;
      }
      @include b(item) {
        &:not(:last-child) {
          margin: 0 0 8px;
        }
        @include e(link) {
          line-height: 1.25;
          font-size: 13px;
          color: #333;
          transition: color .25s ease-in-out;
          @include hover-supported {
            color: $optwear-orange-strong;
          }
        }
      }
    }
    @include b(heading) {
      margin: 0 0 18px;
      @include e(title) {
        margin: 0;
        font-weight: 600;
        color: #000;
      }
    }
  }
}
