@import "@Scss/old/common/_variables.scss";
.row1, .row2, .row3 {
	padding: 4px
}

.row3 {
	background-color: #c0c8d0;
}

.row1 {
	background-color: #ececec
}

.row2 {
	background-color: #dce1e5
}
