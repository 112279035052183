@import "@Scss/old/common/_variables.scss";
@import "@Scss/old/common/_mixins.scss";
$index-bg-color:  #ebebeb;
@import "advices/advices";
//@import "./IndexTabs/IndexTabs";
@import '~swiper/swiper';
@import '~swiper/components/lazy/lazy';
@import '~swiper/components/navigation/navigation';
@import '~swiper/components/effect-fade/effect-fade';

@import "./modules/index-top";
@import "./advices/index-welcome";
@import "./advices/index-features";

//.index-container {
//	max-width: $optwear-container-width;
//	margin: 0 auto;
//	&_bg {
//		background-color: $index-bg-color;
//	}
//}


.index-page-old {
  width: 100%;
  grid-column: full;
}
