@use "src/assets/scss/utils" as u;
@import "../../common/variables";

#office-widget {
  font-size: 16px;
  font-family: $roboto-font-family;
  width: 100%;
  line-height: 1.4em;
}

.office-widget {
  &__map-container {
    min-height: inherit;
    width: inherit;
    position: relative;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 u.size('unit-3x');
    flex-wrap: wrap;
    @include u.below('sm') {
      margin: 0 0 u.size('unit-2x');
    }

  }

  &__btn-header {
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &:last-child {
      margin: 0 0 0 u.size('unit-2x');
      @include u.below('md') {
        margin: 0 0 0 u.size('unit');
      }
    }

    &:hover, &:focus {
      color: $color-accent;
    }

    &:focus {
      outline: none;
    }
  }

  &__btn-active {
    svg path {
      fill: $color-accent;
    }

    color: $color-accent;
  }

  &__btn-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 0 u.size('unit-4x');
    @include u.below('md') {
      margin: 0 0 0 u.size('unit-2x');
    }
    @include u.below('sm') {
      justify-content: flex-end;
      margin: 0 0 u.size('unit');
      width: 100%;
    }

  }

  &__splash {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: inherit;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 0;
    background: $optwear-white url("~@Assets/imageset-old/map-splash.jpg") center center no-repeat no-repeat;
    background-size: cover;

    &-label {
      background: $optwear-white;
      border: 1px solid $color-background;
      padding: 5px;
    }
  }

  &__search {
    flex-grow: 2;
    @include u.below('sm') {
      width: 100%;
      order: 3;
    }
  }
}


.office-select__container {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  height: u.size('unit-10x') * 7;
  flex-wrap: wrap;
  @include u.below('md') {
    height: u.size('unit-10x') * 5;
  }
  @include u.below('sm') {
    height: auto;
  }
}

.office-map__container {
  flex: 1;
  height: 100%;
  @include u.below('sm') {
    height: u.size('unit-10x') * 5;
    flex: auto;
    width: 100%;
  }
}

@import "selected-office";
@import "office-list";
@import "office-banners";


