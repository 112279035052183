@use "src/assets/scss/utils" as u;
@import "src/assets/scss/old/common/_variables.scss";
@import "src/assets/scss/old/common/_mixins.scss";

%slider-btn-purchases-list {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  border: none;
  background: rgba(#fff, 0.3);
  color: #333;
  font-size: 24px;
  outline: none;
  cursor: pointer;
  z-index: u.z-index('slider-button');
  transform: translate(0, -50%);

  &.swiper-button-disabled {
    color: grey;
    opacity: .75;
  }

  @include u.below('md') {
    display: none;
  }
}

.index-purchases-list {

  * {
    font-family: Roboto, sans-serif;
  }

  $item-height: 274px;
  $item-height-md: 258px;
  $item-height-sm: 314px;
  $item-height-xs: 354px;
  $grey: #e7e7e7;
  width: 100%;
  margin: 0 0 u.size('unit-4x');
  @include b(search) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 u.size('unit-2x');
    border-bottom: 1px solid u.color('grey-15');
    position: relative;

    &-clean {
      cursor: pointer;
      font-size: 16px;
      margin: 0 0 u.size('unit') auto;
      color: u.color('primary');
      display: none;
      border: none;
      background: none;

      &:hover {
        color: u.color('primary-dk');
      }

      &--visible {
        display: flex;
      }
    }

    @include b(field) {
      margin: 0 0 u.size('unit');

      &__input {
        border: 1px solid u.color('turquoise-lt');
        font-size: 16px;
      }

    }
    @include b(filter) {
      display: flex;
      width: 100%;
      @include u.below('sm') {
        flex-direction: column;
      }

      &__field {
        border: 1px solid u.color('turquoise-lt');
        font-size: 16px;
      }

      &-success {
        margin: 0 0 u.size('unit');
        background-color: u.var-color('primary') !important;
        color: #fff !important;
      }
      &-more {
        @include u.above('sm') {
          display: none;
        }
        display: flex;
        align-items: center;
        width: 100%;

        &-icon {
          margin: 0 u.size('unit-2x') 0 0;
          display: flex;
          align-items: center;
        }

        &-count {
          margin: 0 0 0 auto;
          background-color: u.var-color('secondary');
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          @include u.square(u.size('unit-3x'));
          color: #fff;

          &--hidden {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
      &-modal {
        @include u.above('sm') {
          width: calc(50% - #{u.size('unit')});
          > .mobile-modal-wrapper {
            width: 100%;
          }
        }

        &-wrapper {
          @include u.above('sm') {
            width: 100%;
            display: flex;
          }
        }
      }
      @include e(block) {
        display: flex;
        flex-direction: column;
        flex: 1;
        &:not(:last-child) {
          margin: 0 16px 0 0;
          @include u.below('md') {
            margin: 0 8px 0 0;
          }
          @include u.below('sm') {
            margin: 0 0 16px;
          }
        }
      }
    }
  }
  @include b(result) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: u.size('unit-3x') 0 0;

    @include e(btn-more) {
      margin: 16px 0 0;
    }
  }
  @include b(skeletons) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    @include b(item) {
      width: 100%;
      padding: 16px;
      height: $item-height + 32;
      @include u.below('md') {
        height: $item-height-md + 16;
        padding: 8px;
      }
      @include u.below('sm') {
        height: $item-height-sm + 16;
      }
      @include u.below('xs') {
        height: $item-height-xs + 16;
      }
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 5px;
      margin: 0 0 16px;
      border: 1px solid u.color('turquoise-lt');
      &:last-child {
        margin: 0;
      }
      @include e(background) {
        $margin16in5: ((16 / 5) - 16) + px;
        $margin8in5: ((8 / 5) - 8) + px;
        $margin16in4: ((16 / 4) - 16) + px;
        $margin8in4: ((8 / 4) - 8) + px;
        $margin16in3: ((16 / 3) - 16) + px;
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 100%;
        background-image: radial-gradient(circle 32px, $grey 100%, transparent 0),
        linear-gradient(#fff 100%, transparent 0),
        linear-gradient($grey 100%, transparent 0),
        repeating-linear-gradient(90deg, $grey, $grey calc(20% + #{$margin16in5}), #fff calc(20% + #{$margin16in5}), #fff calc((20% + #{$margin16in5}) + 16px)),
        linear-gradient(90deg, $grey 100%, transparent 0),
        linear-gradient(90deg, $grey 100%, transparent 0);


        @include u.below('lg') {
          background-image: radial-gradient(circle 32px, $grey 100%, transparent 0),
          linear-gradient(#fff 100%, transparent 0),
          linear-gradient($grey 100%, transparent 0),
          repeating-linear-gradient(90deg, $grey, $grey calc(20% + #{$margin8in5}), #fff calc(20% + #{$margin8in5}), #fff calc((20% + #{$margin8in5}) + 8px)),
          linear-gradient(90deg, $grey 100%, transparent 0),
          linear-gradient(90deg, $grey 100%, transparent 0);
        }
        @include u.below('md') {
          background-image: radial-gradient(circle 24px, $grey 100%, transparent 0),
          linear-gradient(#fff 100%, transparent 0),
          linear-gradient($grey 100%, transparent 0),
          repeating-linear-gradient(90deg, $grey, $grey calc(25% + #{$margin16in4}), #fff calc(25% + #{$margin16in4}), #fff calc((25% + #{$margin16in4}) + 16px)),
          linear-gradient(90deg, $grey 100%, transparent 0),
          linear-gradient(90deg, $grey 100%, transparent 0);
          background-size: 48px 48px,
          100% 8px,
          100% 64px,
          60% 128px,
          60% 32px,
          calc(40% - 32px) 176px;
          background-position: 0 0,
          64px 14px,
          64px 0,
          0 80px,
          left 0 bottom 0,
          right 0 bottom 0;
        }
        @include u.below('sm') {
          background-image: radial-gradient(circle 24px, $grey 100%, transparent 0),
          linear-gradient(#fff 100%, transparent 0),
          linear-gradient($grey 100%, transparent 0),
          repeating-linear-gradient(90deg, $grey, $grey calc(25% + #{$margin8in4}), #fff calc(25% + #{$margin8in4}), #fff calc((25% + #{$margin8in4}) + 8px)),
          linear-gradient(90deg, #fff 100%, transparent 0),
          linear-gradient(90deg, $grey 100%, transparent 0);
          background-size: 48px 48px,
          100% 8px,
          100% 64px,
          100% 128px,
          100% 16px,
          100% 88px;
          background-position: 0 0,
          64px 14px,
          64px 0,
          0 80px,
          left 0 bottom 40px,
          left 0 bottom 0;
        }
        @include u.below('xs') {
          background-image: radial-gradient(circle 24px, $grey 100%, transparent 0),
          linear-gradient(#fff 100%, transparent 0),
          linear-gradient($grey 100%, transparent 0),
          repeating-linear-gradient(90deg, $grey, $grey calc(33.3333% + #{$margin16in3}), #fff calc(33.3333% + #{$margin16in3}), #fff calc((33.3333% + #{$margin16in3}) + 16px)),
          linear-gradient(90deg, $grey 100%, transparent 0),
          linear-gradient(90deg, $grey 100%, transparent 0),
          linear-gradient(90deg, $grey 100%, transparent 0),
          linear-gradient(90deg, $grey 100%, transparent 0);
          background-size: 48px 48px,
          100% 8px,
          100% 64px,
          100% 128px,
          100% 24px,
          100% 24px,
          100% 24px,
          100% 40px;
          background-position: 0 0,
          64px 14px,
          64px 0,
          0 72px,
          0 208px,
          0 240px,
          0 272px,
          left 0 bottom 0;
        }
        background-size: 64px 64px,
        100% 8px,
        100% 64px,
        60% 128px,
        60% 32px,
        calc(40% - 32px) 184px;
        background-position: 0 0,
        80px 14px,
        80px 0,
        0 88px,
        left 0 bottom 0,
        right 0 bottom 0;
        background-repeat: no-repeat;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(100deg, rgba(#fff, 0), rgba(#fff, .3) 50%, rgba(#fff, 0) 80%);
          background-size: 50px 100%;
          background-position: -125% 0;
          background-repeat: no-repeat;
          animation: skeleton-shine 2s infinite;
          @include u.below('sm') {
            animation-duration: 1s;
          }
        }
      }
    }
  }
  @include b(item) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: $item-height + 32;


    @include u.below('md') {
      height: $item-height-md + 16;
      padding: 8px;
    }
    @include u.below('sm') {
      height: $item-height-sm + 16;
    }
    @include u.below('xs') {
      height: $item-height-xs + 16;
    }
    padding: 16px;
    margin: 0 0 16px;

    &:last-of-type {
      margin: 0;
    }
    border: 1px solid u.color('turquoise-lt');
    overflow: hidden;
    box-sizing: border-box;
    background: #fff;
    color: #333;
    border-radius: 5px;

    & + .index-purchases-list-skeletons {
      .index-purchases-list-skeletons-item:first-child {
        margin: u.size('unit-2x') 0;
      }
    }

    @include m(show-catalogs) {
      height: auto;
      .index-purchases-list-item-content {
        height: auto;
      }
      .index-purchases-list-item-catalogs:after {
        content: none;
      }
      .index-purchases-list-item-catalogs {
        @include u.below('sm') {
          height: auto;
        }

        .index-purchases-list-item-catalogs-list-item__link {
          white-space: normal;
        }
      }
    }
    &:last-child {
      margin: 0;
    }


    @include b(header) {
      display: flex;
      width: 100%;
      margin: 0 0 24px;
      height: 64px;
      @include u.below('md') {
        margin: 0 0 16px;
      }
      @include u.below('xs') {
        margin: 0 0 8px;
      }
      @include e(avatar) {
        width: 64px;
        height: 64px;
        @include u.below('md') {
          width: 48px;
          height: 48px;
        }
        @include m(empty) {
          background-color: $grey;
        }
        display: inline;
        border-radius: 100%;
        background-size: cover;
        background-position: center center;
        margin: 0 16px 0 0;
        transition: opacity .25s ease-in-out;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          transition: opacity .25s ease-in-out;
          background: rgba(#fff, .125);
          opacity: 0;
          z-index: u.z-index('default');
        }
        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
      @include b(title) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        margin: 0;
        min-width: 0;
        @include e(organizer) {
          color: $optwear-blue-light;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
          font-size: 13px;
          transition: color .25s ease-in-out;
          margin: 0 0 8px;
          &:hover {
            color: $optwear-blue-strong;
          }
        }
        @include e(text) {
          color: #333;
          line-height: 1.25;
          max-height: 40px;
          font-size: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          transition: color .25s ease-in-out;
          &:hover {
            color: $optwear-orange-strong;
          }
        }
      }
    }


    @include b(content) {
      display: flex;
      width: 100%;
      height: 184px;

      @include u.below('md') {
        height: 176px;
      }
      @include u.below('sm') {
        flex-wrap: wrap;
      }
    }

    @include b(catalogs) {
      width: 40%;
      height: 100%;
      position: relative;
      overflow: hidden;
      padding: 0 0 0 32px;

      @include u.below('md') {
        width: 35%;
      }
      @include u.below('sm') {
        width: 100%;
        height: 40px;
        padding: 16px 0 0;
        border-top: 1px solid #e7e7e7;
      }
      @include b(list) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        @include b(item) {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          &:not(:last-child) {
            margin: 0 0 8px;
          }
          @include e(link) {
            white-space: nowrap;
            color: #333;
            font-size: 14px;
            line-height: 18px;
            transition: color .25s ease-in-out;
            @include hover-supported {
              color: $optwear-orange-strong;
            }
          }
        }
      }
      @include e(btn-show) {
        user-select: none;
        cursor: pointer;
        z-index: u.z-index('slider-button');
        font-size: 15px;
        outline: none;
        color: #333;
        line-height: 24px;
        height: 24px;
        width: 100%;
        text-align: center;
        border: none;
        bottom: 0;
        background: none;
        display: none;
        @include absolute-center-horizontal;
        @include u.below('sm') {
          display: block;
          background-color: #fff;
          height: u.size('unit-5x');
          line-height: u.size('unit-5x');
        }
      }
      &:after {
        content: '';
        position: absolute;
        z-index: u.z-index('default');
        width: 100%;
        bottom: 0;
        left: 0;
        height: 96px;
        pointer-events: none;
        background: linear-gradient(180deg, rgba(#fff, 0), #fff 72px);
        display: none;
        @include u.below('sm') {
          display: block;
          height: u.size('unit-5x');
          background: #fff;
        }
      }
      @include m(out-block) {
        &:after {
          display: block;
        }
        .index-purchases-list-item-catalogs__btn-show {
          display: block;
        }
      }
    }
    @include b(info) {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 32px;
      color: #333;
      @include u.below('xs') {
        height: 88px;
        flex-direction: column;
        align-items: initial;
      }
      @include b(readiness) {
        display: flex;
        align-items: flex-end;
        width: 200px;
        margin: 0 16px 0 0;

        @include u.below('md') {
          width: 150px;
        }
        @include u.below('xs') {
          width: 200px;
          margin: 0 0 8px;
          line-height: 24px;
          height: 24px;
          align-items: center;
        }
        @include e(label) {
          margin: 0 8px 0 0;

        }
        @include b(block) {
          position: relative;
          width: 100%;
          height: 16px;
          overflow: hidden;
          background: lighten(#333, 25%);
          border-radius: 5px;
          @include e(percent) {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            height: 100%;
            @include m(red) {
              background-color: $optwear-red;
            }
            @include m(yellow) {
              background-color: $optwear-yellow;
            }
            @include m(green) {
              background-color: $optwear-green;
            }
          }
          @include e(percent-text) {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            transform: translate(-50%, -50%);
          }
        }
      }
      @include b(status) {
        display: flex;
        height: 100%;
        margin: 0 16px 0 0;
        @include u.below('xs') {
          margin: 0 0 8px;
          line-height: 24px;
          height: 24px;
        }
        @include e(text) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          white-space: nowrap;

          @include u.below('md') {
            justify-content: flex-end;
          }
          @include u.below('xs') {
            justify-content: initial;
            align-items: center;
            flex-direction: row;
          }
        }
        @include e(label) {
          @include u.below('sm') {
            margin: 0 16px 0 0;
          }
        }
        @include e(icon) {
          display: flex;
          align-items: flex-end;
          &:before {
            content: '';
            width: 32px;
            height: 32px;
            margin: 0 8px 0 0;
            background-position: center center;
            background-size: cover;
            @include u.below('md') {
              width: 24px;
              height: 24px;
            }
          }
          @include m(1) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/1.png");
            }
          }

          @include m(2) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/2.png");
            }
          }

          @include m(3) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/3.png");
            }
          }

          @include m(4) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/4.png");
            }
          }

          @include m(5) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/5.png");
            }
          }

          @include m(6) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/6.png");
            }
          }

          @include m(7) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/7.png");
            }
          }

          @include m(8) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/8.png");
            }
          }

          @include m(9) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/9.png");
            }
          }

          @include m(10) {
            &:before {
              background-image: url("~@Assets/imageset-old/purchase_status/10.png");
            }
          }
        }
      }
      @include b(members) {
        @include u.below('xs') {
          line-height: 24px;
          height: 24px;
        }
      }
    }
    @include b(lots) {
      width: 100%;
      margin: 0 0 24px;

      @include u.below('md') {
        margin: 0 0 16px;
      }
      @include u.below('xs') {
        margin: 0 0 8px;
      }
      @include e(btn-next) {
        right: 0;
        @extend %slider-btn-purchases-list;
      }
      @include e(btn-prev) {
        left: 0;
        @extend %slider-btn-purchases-list;
      }
      @include e(empty) {
        width: 100%;
        height: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $grey;
        text-align: center;
      }
      @include b(block) {
        position: relative;
        height: 128px;
        //padding: 0 8px;
        @include e(image) {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 100%;
          max-height: 100%;
          transform: translate(-50%, -50%);

        }
      }
      @include e(container) {
        @include m(empty) {
          width: 100%;
        }
      }
    }
    @include e(first-block) {
      display: flex;
      flex-direction: column;
      width: 60%;

      @include u.below('md') {
        width: 65%;
      }
      @include u.below('sm') {
        width: 100%;
      }
    }
  }

  @include b(empty) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: $item-height + 32;

    @include u.below('md') {
      height: $item-height-md + 16;
      padding: 8px;
    }
    @include u.below('sm') {
      height: $item-height-sm + 16;
    }
    padding: 16px;
    margin: 16px 0;
    border-width: 1px;
    border-style: solid;
    border-color: #dedede;
    overflow: hidden;
    box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    @include e(subtitle) {
      margin: 0 0 24px;
      color: #333;
      font-size: 16px;
    }
    @include e(title) {
      margin: 0 0 12px;
      color: #333;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.list-purchases {
  vertical-align: middle;

  &__header {
    background: $optwear-blue-gradient;
    line-height: 40px;
    color: $optwear-white;
  }

  tr:nth-child(2n) td,
  tr:nth-child(2n) .catalogs-list__item-collapser-text {
    background-color: darken($optwear-white, 3%);
  }

  &-container {
    width: 100%;

    [class^="sp_them_stat"] {
      padding: 16px;
      display: inline-block;
      margin-right: 15px;
    }

    .purchases__purchase {
      padding-left: 13px;
      max-width: 435px;
      box-sizing: border-box;

      &_fullwidth {
        max-width: 100%;
      }
    }

    th {
      font-size: 20px;
      padding: 5px;
    }
  }

  &__name {
    font-size: 20px;
  }

  &__status {
    display: flex;
    align-items: center;
  }

  &-container tr:nth-child(2n-1) .catalogs-list__item-collapser-text {
    background-color: #edeef0;
  }

  tr {
    td {
      border-bottom: 1px solid #ccc;
      padding: 10px 5px;
    }
  }

  tr:last-child td {
    border: 0;
  }

  time {
    white-space: nowrap;
  }

  &__filter {
    display: inline-block;
    border: 1px solid #aaa;
    border-radius: 4px;
    min-height: 32px;

    &[multiple] {
      max-height: 32px;
    }
  }

  &__filters {
    border: none;

    label {
      display: inline-block;
      width: 293px;
    }
  }

  .loader-horizontal {
    overflow: hidden;
    width: 100%;
    height: 50px;
  }
}

.index-purchases-list-search-filter-modal {
  .index-purchases-list-search-filter__label {
    @include u.below('sm') {
      line-height: 1.4 !important;
    }
  }
}

.index-purchases-list-search-filter {
  > .index-purchases-list-search-filter__block {
    @include u.below('sm') {
      margin: 0 0 u.size('unit');
    }
  }
}
