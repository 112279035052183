@use "src/assets/scss/utils" as u;

.calendar {
  [role="tablist"] {
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
    top: 1px;
  }


  [role="tablist"] li {
    display: flex;


  }

  [role="tablist"] {
    [role="presentation"] {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;

      font-weight: bold;
      box-shadow: 4px -4px 6px 0 #ccc;
      overflow: hidden;

      a {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;

      }

      @include u.above('xs') {
        &:not(:last-of-type) {
          margin: 0 u.size('unit-half') 0 0;
        }
      }
      @include u.below('xs') {
        flex: 1;
      }
    }
  }

  [role="tablist"] a {
    text-decoration: none;
    padding: 0.5rem 1em;
    font-size: 1.1rem;

    width: 100%;
    color: #333;
    box-sizing: border-box;
    @include u.below('md') {
      font-size: u.size('text-md');
    }
    @include u.below('sm') {
      font-size: u.size('text-sm');
    }
    @include u.below('xs') {
      @include u.mixins-text-height(u.size('unit-5x'));
      text-align: center;
      padding: 0 u.size('unit');
      font-size: u.size('text-xs');
    }
  }

  [role="tablist"] [aria-selected] {
    background: u.var-color('body-background');
    border-bottom: 0;
    //position: relative;
    //top: 2px;
    //border-top-left-radius: 7px;
    //border-top-right-radius: 7px;
    //font-size: 1.3rem;
    //color: #333;
    //font-weight: bold;
    //box-shadow: 4px -5px 6px 0px #a7abaf;
  }


  [role="tabpanel"] {
    padding: 1.5rem;
    border-radius: 0 0 7px 7px;
    box-shadow: 4px 4px 14px 0 #5f6368;
    box-sizing: border-box;
    @include u.below('md') {
      padding: u.size('unit');
    }
  }

  //[role="tabpanel"] * + * {
  //	margin-top: 0.75rem;
  //}


  //@media (max-width: 550px) {
  //
  //	[role="tablist"] li, [role="tablist"] a {
  //		display: block;
  //		position: static;
  //	}
  //
  //	[role="tablist"] a {
  //		border: 2px solid #222 !important;
  //	}
  //
  //	[role="tablist"] li + li a {
  //		border-top: 0 !important;
  //	}
  //
  //	[role="tablist"] [aria-selected] {
  //		position: static;
  //	}
  //
  //	[role="tablist"] [aria-selected]::after {
  //		content: '\0020⬅';
  //	}
  //
  //	[role="tabpanel"] {
  //		border-top: 0;
  //	}
  //
  //}
}
