.ForumListTable-HiddenLinksBtn {
	text-align: center;
	padding: 5px 5px;
	float: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 300px;
	min-width: 178px;
	border-right: 1px solid;
	background: rgba(0, 0, 0, 0.24);
	@include u.below('sm') {
	    width: 100%;
			max-width: none;
			justify-content: flex-start;
			border: none;
			box-sizing: border-box;
			svg {
					margin: 0 10px 0 0;
			}
	}
	&:hover,
	&:active,
	&:focus {
		background: rgba(0, 0, 0, 0.16);
		cursor: pointer;
	}
}

.ForumListTable-HiddenLinks {
		display: none;
	z-index: 1;


		@include u.above('md') {
				background: #eaebec;
				position: absolute;
				min-width: 160px;
				box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
				padding: 5px;
				top: 40px;
				left: 0;
				text-align: left;
				border: 2px solid #1d809b;
		}
		@include u.below('md') {
		    display: flex;
				flex-direction: column;
				box-sizing: border-box;
				
				a {
						@include u.mixins-text-height(u.size('unit-5x'));
				}
		}
}

.ForumListTable-HiddenLinks--show {
	display: flex;
	flex-direction: column;
	font-size: 18px;
}

.ForumListTable-HiddenLinks a {
	border-top: 1px solid white;
	color: #1d809b;
	padding: 5px;

	&:first-child {
		border-top: none;
	}

	&:active,
	&:focus,
	&:hover {
		color: #1d809b;
		background: rgba(0, 0, 0, 0.08);
	}
}
