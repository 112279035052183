@use "src/assets/scss/utils" as u;
@import "src/assets/scss/old/common/_variables.scss";


.ucp {
  &-ads {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    @include u.below('md') {
      display: none;
    }
  }

  &-attention {
    @include u.below('md') {
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;
    }
  }

  &-attention-title {
    font-size: 32px;
    margin: 0;
    flex: 1;
    overflow: hidden;
    @include u.below('xs') {
      font-size: 24px;
    }

    span {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-attention-btn {

    @include u.above('md') {
      margin-bottom: 20px;
    }

    a {
      display: block;
      width: 100%;
    }
  }

  &-ad {
    display: block;
    margin-top: 10px;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  th {
    background: $optwear-blue-strong;
    color: $optwear-white;
    padding: 10px 5px;
    @include u.below('md') {
      box-sizing: border-box;
    }
  }

  p {
    margin: 0;
  }

  .row1, .row2, .row3 {
    padding: 0;
  }


  .tablebg {
    background-color: $color-background;
  }

  .btnlite {
    font: inherit;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    margin: 2px 0;
    padding: .5em 1em;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    color: #a7abaf !important;
    background-color: $optwear-white;
    border-color: #a7abaf;
    //font-family: "Lucida Grande", "Trebuchet MS", Helvetica, Arial, sans-serif;
    text-transform: none;
    line-height: 20px;

  }

  .btnmain {
    font: inherit;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    margin: 2px 0;
    padding: .5em 1em;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    color: $optwear-white !important;
    background-color: $optwear-blue-strong;
    border-color: $optwear-blue-strong;
    //font-family: "Lucida Grande", "Trebuchet MS", Helvetica, Arial, sans-serif;
    text-transform: none;
    line-height: 20px;
  }

  .helpline {
    display: none;
  }

  .ucp-folder-message td {
    border-bottom: 1px solid $color-background;
    padding: 10px 0;
    @include u.below('md') {
      padding: 0;
      border: none;
    }
  }

  .postbody {
    //max-width: 900px;
    word-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
    width: 100%;

    img {
      @include img-responsive;
      display: inline-block;
    }
  }

  &-container {
    padding-top: 0;
    display: flex;
    //max-width: $optwear-container-width;
    flex-wrap: nowrap;
  }

  &-layout {
    &-left {
      width: 275px;
      display: flex;
      flex-direction: column;
      @include u.below('md') {
        width: 100%;
      }
    }

    &-right {
      //max-width: 940px;
      //flex-grow: 2;
      margin-left: 20px;
      flex: 1;
      @include u.below('lg') {
        margin-left: 10px;
      }
      @include u.below('md') {
        margin-left: 0;
      }
    }
  }

  &-table {
    border: none;
    border-radius: 2px;
    font-size: 15px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }

  .register-form__label {
    display: inline-block;
  }

  .create-message__text {
    @include u.below('md') {
      display: none;
    }
  }

  .create-message__btn {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #28a745;
    border-color: #28a745;
    font-size: 17px;
    padding: 5px;
    margin: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    @include u.below('md') {
      @include u.square(48px);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        @include u.square(32px);
      }
    }
    @include u.below('xs') {
      @include u.square(40px);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        @include u.square(24px);
      }
    }
  }

  .posting-subject {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include u.below('md') {
      flex-direction: column;
      align-items: flex-start;
    }

    input {
      margin-left: 10px;
      @include u.below('md') {
        margin-left: 0;
      }
    }
  }
}

.cat {
  line-height: 34px;
  margin: 0;
  padding: 0;
  border: 0;
  height: 34px;
  background-color: #2c90be;
  text-indent: 4px;
  color: $color-text-invert !important;
  @include u.below('sm') {
    height: auto;
    text-indent: 0;
  }

  a {
    color: $color-text-invert !important;
  }

  h4, h4 a {
    color: $color-text-invert;
  }
}

.ucp-posing-icons {
  display: flex;
  align-items: center;

  .register-form__label {
    min-width: 87px;

  }

  @include u.below('md') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.ucp-message-footer {

  &__sortmsg {
    display: flex;
    @include u.above('lg') {
      align-items: center;
      justify-content: center;
    }
    @include u.below('lg') {
      flex-direction: column;
    }
  }

  &__nav {
    @include u.below('md') {
      display: flex;
      flex-direction: column;
    }
  }
}


.ucp-container {
  @include u.below('md') {
    flex-direction: column;
  }
}

.tablebg-footer {
  .ucp-message-footer__nav {
    @include u.below('md') {
      select {
        margin: 0 0 10px;
      }
    }
  }
}

.tablebg-header {
  tbody {
    tr {
      @include u.below('lg') {
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        tr {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          @include u.below('xs') {
            align-items: flex-start;
          }

          td:first-child {
            .ucp-message-footer__nav {
              padding: 0;
              flex-direction: row;

              @include u.below('xs') {
                justify-content: flex-start;
                flex-wrap: wrap;
              }

              .gensmall {
                margin: 0 10px 0 0;
              }
            }
          }

          //td:first-child {
          //		display: flex;
          //		justify-content: flex-start;
          //}

          td:last-child {
            order: -1;
            margin: 0 0 10px;
          }
        }
      }
    }
  }

  &-content-flow-begin {
    @include u.below('lg') {
      width: 100%;
    }
  }
}

.ucp {
  td.row3 {
    padding: 10px 5px;
    @include u.below('md') {
      padding: 5px;
    }
  }
}

@import "viewfolder";
@import "navbar";
@import "-yandex-header";
@import "zebra";
@import "-posting-to";

.ucp-posting {
  &-hello {
    @include u.below('md') {
      display: none;
    }
  }
}

.ucp-main-drafts-page {
  .tablebg {
    tbody {
      @include u.below('sm') {
        display: flex;
        flex-direction: column;
        tr:first-child {
          th {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            justify-content: center;
          }
        }
        .drafts-description {
          display: none;
        }

        .drafts-headers {
          display: none;
        }

        .drafts-empty {
          td {
            display: flex;
            width: 100%;
            justify-content: center;
            box-sizing: border-box;
          }
        }

        .drafts-edit {
          &-back {
            padding: 10px 5px;
          }

          &-header {
            .post {
              width: 100% !important;
              box-sizing: border-box;
            }
          }

          &-description {
            .gensmall {
              display: none;
            }

            table {
              width: 100%;

              .drafts-edit-textarea {
                width: 100%;
                display: flex;
                box-sizing: border-box;

                .post {
                  width: 100%;
                }
              }
            }
          }

          &-item {
            display: flex;
            flex-direction: column;

            &:not(:last-of-type) {
              margin: 0 0 15px;
            }

            padding: 5px 5px 0 5px;
            box-sizing: border-box;

            &-label {
              width: 100% !important;
              margin: 0 0 5px;
              background-color: transparent;
            }

            &-text {
              width: 100%;
            }
          }

        }

        .drafts-item {
          width: 100%;
          display: grid;
          grid-template: "checkbox template template" auto "actions actions date" auto / 30px auto auto;

          td {
            box-sizing: border-box;
          }

          td:nth-child(2) {
            grid-area: template;
            overflow: hidden;

            .topictitle, .gensmall {
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          td:nth-child(3) {
            grid-area: actions;
            text-align: left;
          }

          td:first-child {
            grid-area: date;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
          }

          td:last-child {
            grid-area: checkbox;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .cat {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding: 5px;
          align-items: center;
          box-sizing: border-box;
          line-height: 1;
          height: auto;

          input {
            line-height: 30px;
            padding: 0 15px;
            height: 30px;
          }
        }
      }
    }
  }
}

.ucp-pm-options-page {
  .options-rules {

  }


  .options-param {
    @include u.below('sm') {
      padding: 5px 0;
      &:not(:last-of-type) {
        margin: 0 0 10px;
      }
      .row1, .row2, .row3 {
        background-color: #ececec;
      }
      select, input {
        width: 100% !important;
        box-sizing: border-box;
      }
      > td {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 5px;
        box-sizing: border-box;

        &:not(:last-child) {
          margin: 0 0 10px;
        }
      }
    }

    &-option {

      > td.options-param-option-item {
        @include u.below('sm') {
          display: grid;
          grid-template: 'radio label' auto / 30px 1fr;

          &-control {
            grid-template: 'radio label' auto 'radio control' auto / 30px 1fr;
          }

          .radio {
            grid-area: radio;
            align-self: center;
            width: auto !important;
          }
          .label {
            grid-area: label;
            align-self: center;
          }
          .control {
            grid-area: control;
          }
        }

        .radio {
          @include u.above('sm') {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }

  .options-item {
    @include u.below('sm') {
      background-color: #ececec;
      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }

    tbody {
      @include u.below('sm') {
        display: flex;
        flex-direction: column;
        .options-title {
          width: 100%;
          display: flex;
          justify-content: center;

          th {
            width: 100%;
          }
        }
      }
    }
  }

  .options-description {
    @include u.below('sm') {
      border: 1px solid $optwear-blue-strong;
      padding: 5px;
    }
  }

  .options-defined-rules {
    td.options-defined-rules-else {
      @include u.below('sm') {
        margin: 0 0 20x;
      }
    }
  }
}

.ucp-pm-viewmessage-page {
  .viewmessage-move {
    @include u.below('sm') {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-select {
        margin: 0 0 5px !important;
      }
    }
  }

  .viewmessage-footer-actions {
    &-indent {
      @include u.below('sm') {
        margin: 0 0 u.size('unit-3x') !important;
      }
    }

    &-block {
      @include u.below('sm') {
        display: flex;
        flex-direction: column;
      }
    }

    &-first {
      @include u.below('sm') {
        order: -1;
      }
    }

    @include u.below('sm') {
      padding: 0 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;
      .btn {
        box-sizing: border-box;
        width: 100%;
        margin: 0 0 10px;
      }
    }
  }

  .viewmessage-table {
    @include u.below('sm') {
      display: flex;
      flex-direction: column;
      tbody {
        display: flex;
        flex-direction: column;
        width: 100%;

        > tr {
          width: 100%;
          display: grid;
          grid-template: auto auto / 1fr;

          &:not(:last-of-type) {
            margin: 0 0 10px;
          }

          > td {
            width: 100%;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.ucp-history {
  @include u.below('sm') {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.tablebg-ucp-table {
  tbody {
    @include u.below('sm') {
      display: flex;
      flex-direction: column;
      > tr {
        width: 100%;
        display: flex;
        flex-direction: column;

        > td {
          width: 100%;
          background-color: transparent;
        }
      }
    }
  }

  .tablebg-ucp-item {
    & > td {
      box-sizing: border-box;
      padding: 10px 5px;
      @include u.below('sm') {
        padding: 0 5px;
        input, select, textarea {
          box-sizing: border-box;
          width: 100%;
        }
      }
    }

    @include u.below('sm') {
      box-sizing: border-box;
      padding: 10px 0;
      background-color: #fff;
      &:not(:last-of-type) {
        margin: 0 0 u.size('unit-2x');
      }
    }

    &-label {
      @include u.below('sm') {
        padding: 0 5px u.size('unit') 5px;
        > .ucp-table-description {
          margin: 5px 0;
        }
      }
    }

    &-title {
      @include u.below('sm') {
        margin: 0 !important;
        padding: 0 !important;
        td, th {
          width: 100%;
          text-align: center;
          box-sizing: border-box;
          padding: 10px 5px;
          background-color: $optwear-blue-strong;
          color: #fff;
        }
      }
    }

    &-param {
      display: flex;
      align-items: center;

      td:first-child, &-input {
        display: flex;
        align-items: center;
        margin: 0 u.size('unit') 0 0;
        @include u.below('sm') {
          width: 30px;
          justify-content: flex-start;
          margin: 0;
          .radio {
            width: auto !important;
          }
        }
      }

      td:last-child, &-text {
        @include u.below('sm') {
          flex: 1;
        }
      }

      @include u.below('sm') {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }

    &-buttons {
      @include u.below('sm') {
        align-items: center;
      }

      input {
        &:not(:last-of-type) {
          margin: 0 u.size('unit') 0 0;
        }

        @include u.below('sm') {
          &:not(:last-of-type) {
            margin: 0 0 u.size('unit');
          }
        }
      }
    }

    &-editor-buttons {
      .btnbbcode {
        @include u.below('md') {
          width: auto !important;
          box-sizing: border-box;
          padding: 10px !important;
        }
        @include u.below('sm') {
          input, select, textarea {
            width: auto !important;
          }
        }
      }
    }
  }
}

.search-forum-page {
  .tablebg-ucp-table {
    tbody {
      @include u.below('md') {
        display: flex;
        flex-direction: column;
        > tr {
          width: 100%;
          display: flex;
          flex-direction: column;

          > td {
            width: 100%;
            background-color: transparent;

            &.tablebg-ucp-item {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }

    .tablebg-ucp-item {
      & > td {
        @include u.below('md') {
          padding: 0 5px;
          input, select, textarea {
            box-sizing: border-box;
            width: 100%;
          }
        }
      }

      @include u.below('md') {
        box-sizing: border-box;
        padding: 10px 0;
        background-color: #fff;
        &:not(:last-of-type) {
          margin: 0 0 u.size('unit-2x');
        }
      }

      &-label {
        @include u.below('md') {
          padding: 0 5px u.size('unit') 5px;
          > .ucp-table-description {
            margin: 5px 0;
          }
        }
      }

      &-title {
        @include u.below('md') {
          margin: 0 !important;
          padding: 0 !important;
          td, th {
            width: 100%;
            text-align: center;
            box-sizing: border-box;
            padding: 10px 5px;
            background-color: $optwear-blue-strong;
            color: #fff;
          }
        }
      }

      &-param {
        td:first-child, &-input {
          @include u.below('md') {
            width: 30px;
            justify-content: flex-start;
            margin: 0;
            .radio {
              width: auto !important;
            }
          }
        }

        td:last-child, &-text {
          @include u.below('md') {
            flex: 1;
          }
        }

        @include u.below('md') {
          flex-direction: row;
          flex-wrap: nowrap;
        }
      }

      &-buttons {
        @include u.below('md') {
          align-items: center;
        }

        input {
          @include u.below('md') {
            &:not(:last-of-type) {
              margin: 0 0 u.size('unit');
            }
          }
        }
      }
    }
  }
}

.tablebg-ucp-membership {
  &-buttons {
    .cat {
      @include u.below('sm') {
        display: flex;
        flex-direction: column;

      }

      .genmed {
        @include u.below('sm') {
          color: #000;
        }
      }
    }
  }

  &-param {
    @include u.below('sm') {
      display: flex;
      align-items: center;
      margin: 10px 0;
      input {
        width: auto !important;
      }
      &-input {
        width: 30px;
        display: flex;
        justify-content: flex-start;
      }
      &-text {
        flex: 1;
        text-align: left;
      }
    }

    &-input {

      @include u.below('sm') {
        + .tablebg-ucp-membership-param-text {
          display: block;
        }
      }
    }

    &-text {
      display: none;
    }

    &.tablebg-ucp-membership-param-empty {
      margin: 0;

      .tablebg-ucp-membership-param-text {
        display: none;
      }
    }
  }
}

.ucp-table-description {
  @include u.below('sm') {
    display: block;
    box-sizing: border-box;
    border: 1px solid #1d809b;
    padding: 5px;
  }
}

.ucp-layout-left-no-message {
  @include u.below('md') {
    flex-direction: row;
    flex-wrap: nowrap;
    .ucp-attention {
      width: auto;
      flex: 1;
    }
    .ucp-navbar {
      flex: none;
      width: auto;
    }
  }

}


.bbcode-font-size {
  float: right;
  margin: u.size('unit') 0;
  @include u.below('sm') {
    margin: u.size('unit');
  }
  //display: flex;
  //width: 100%;
  //justify-content: flex-end;
  //margin: u.size('unit-2x') 0 0;
  //@include u.below('md') {
  //		margin: u.size('unit') 0 0;
  //
  //		flex-direction: column;
  //}

}

