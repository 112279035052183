@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.search_query {
  line-height: 28px;
  height: 28px;
  width: 260px;
  border: 1px solid #dfdfdf;
  border-right: 0 !important;
  background: $optwear-white;
  float: left;
  text-indent: 7px;
}

.Search {
  padding-top: 17px;
}

.Search-box {
  position: relative;
  width: auto;
  overflow: hidden;
  height: 33px;
  padding: 0 0 0 15px;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background-color: $optwear-white;
  @include u.below('xs') {
    @include u.mixins-text-height(u.size('unit-3x'));
    padding: 0;
  }

}

.Search-box-input, #Search-box-input {
  background: $optwear-white;
  outline: 0;
  position: absolute;
  top: 0;
  display: block;
  width: calc(100% - 15px);
  height: 100%;
  padding: 11px 10px 11px 0;
  line-height: 18px;
  font-size: 13px;
  border: 0;
}

.Search-button {
  padding: 0;
  width: 37px;
  height: 32px;
  background: transparent;
  z-index: u.z-index('default');
  position: absolute;
  right: 0;
  border: 0 none;
  box-sizing: content-box;
  @include u.below('xs') {
    @include u.mixins-text-height(u.size('unit-3x'));
  }

  &:after {
    position: relative;
    width: 13px;
    height: 13px;
    border-radius: 20px;
    color: #48484a;
    border: 2px solid #494949;
    content: "";
    display: block;
    left: 7px;
    bottom: 3px;
  }

  &:before {
    display: block;
    content: "";
    position: relative;
    border: 1px solid #494949;
    width: 6px;
    top: 15px;
    left: 20px;
    transform: rotate(45deg);
  }
}

.Search-box-clear {
  background: none;
  font-size: 20px;
  border: none;
  position: relative;
  display: none;
  left: calc(100% - 63px);
  top: 4px;

}

.SearchSuggestWords {
  height: 16px;
  overflow: hidden;
  margin-top: 4px;

  &-Word {
    position: relative;
    float: left;
    padding: 0 10px;
    line-height: 16px;
    font-size: 12px;
    color: #666666;

    &:last-child {
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;

      &:before {
        content: none;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      top: 2px;
      content: "";
      display: inline-block;
      width: 1px;
      height: 14px;
      font-size: 0;
      overflow: hidden;
      background-color: #e9e9e9;
    }
  }

}
