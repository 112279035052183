$load-color: #e3e3e3;
.lotCard.lotCard--loading:hover .lotCard-inside {
	box-shadow: none;
	background: transparent;
}

.lotCard.lotCard--loading {
	min-height: 300px;
}

.lotCard--loading {
	.image {
		width: 230px;
		height: 150px;
		display: block;
		background-color: $load-color;
	}

	.lotCard-header {
		width: 230px;
		height: 33px;
	}
	.lot-title {
		width: 230px;
		height: 18px;
		background-color: $load-color;
	}
	.lotCard-content {
		width: 230px;
	}
	.lotCard-mainPrice {
		width: 44px;
		height: 21px;
		background-color: $load-color;
	}
	.lotCard-orgFee {
		width: 121px;
		height: 21px;
		background-color: $load-color;
	}

}
