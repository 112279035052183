@use "src/assets/scss/utils" as u;

$this-month-color: #757575;
.calendar-mini, .monthyear-mini, .dayheaders-mini, .days {
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-align: center;
}

.calendar-mini {
  &__container {
    border-right: 1px solid $color-background;
    width: 230px;
    padding-right: 20px;
    @include u.below('md') {
      width: 100%;
      padding: 0;
      border: none;
      margin: 0 0 u.size('unit-4x');
    }
  }

  &__this-month {
    font-size: 12px;
    font-weight: 400;
    color: $this-month-color;
    text-align: left;
    padding-left: 10px;
  }

  &__month-changer {
    text-decoration: none;
    font-size: 18px;
    color: $this-month-color;

    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }

  td {
    height: 2.5em;
    cursor: default;
    vertical-align: middle;
    border-radius: 9px;

    &.today {
      border: 1px solid $color-background;
    }

    &.select_day {
      background-color: $optwear-blue-light;
      color: $optwear-white;

    }

    &:hover, &:focus {
      background-color: $color-background;
    }

    &.notaday, &.calendar-mini__this-month {
      background-color: transparent;
      color: $this-month-color;

      &:focus, &:hover {
        background-color: transparent;
        color: $this-month-color;
      }
    }
  }
}

.monthyear-mini td {
  font-weight: bold;
}

.dayheaders-mini td {
  color: #757575;
  font-size: 10px;
  vertical-align: middle;
}
