@use "src/assets/scss/utils" as u;

.ForumListNav {
  float: right;
  padding: 0;
  margin: 0;
  border-left: 1px solid #d8d8d8;
  border-right: 0;
  margin-left: auto;

  @include u.below('md') {
    margin: 10px 0 0 auto;
    align-self: flex-end;
  }
  @include u.below('xs') {
    align-self: flex-start;
    width: 100%;
    display: flex;
  }

  a, &-curPage {
    text-decoration: none;
    float: left;
    display: inline-block;
    margin: 0;
    padding: 1px 10px;
    line-height: 36px;
    height: 34px;
    color: $optwear-white;
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    background-color: $optwear-white;
    @include u.below('xs') {
      flex: 1;
      text-align: center;
      @include u.mixins-text-height(u.size('unit-3x'));
    }
  }

  &-link {
    font-size: 18px;
    @include u.below('sm') {
      font-size: u.size('text-sm');
    }
    @include u.below('xs') {
      flex: 1;
      text-align: center;
    }

    &[rel="last"] {
      //@include u.below('xs') {
      //    display: none;
      //}
    }

    &:focus,
    &:active,
    &:visited,
    &:link {
      text-decoration: none;
      color: $optwear-blue-light;
    }

    &:focus,
    &:active,
    &:hover {
      background-color: $optwear-blue-strong;
      color: $optwear-white;
    }

  }

  &-pages {
    @include u.below('xs') {
      display: none !important;
    }
  }

  &-curPage {
    font-size: 18px;
    background-color: $optwear-blue-light;
    position: relative;
    display: inline-block;
    @include u.below('sm') {
      font-size: u.size('text-sm');
    }
  }

  &__goto-page {
    position: relative;
    display: inline;

    &-oneliner {
      display: flex;
    }

    @include u.below('xs') {
      display: none;
    }
  }

  &__goto-page-popup {
    position: absolute;
    bottom: 0;
    background: $optwear-white;
    padding: 5px;
    border: 1px solid $color-background;

    .inpt {
      min-width: 106px;
      margin-right: 5px;
    }
  }
}


.ForumListNav-link[rel='first'] {
  //@include u.below('xs') {
  //    display: none;
  //}
}
