@use "src/assets/scss/utils" as u;

.l-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include wrapper;
  @include e(content) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: [full-start] minmax(0, 1fr) [content-start] #{u.size('page-lg')} [content-end] minmax(0, 1fr) [full-end];
  align-content: flex-start;
  @include u.below('lg') {
    grid-template-columns: [full-start] minmax(0, 1fr) [content-start] #{u.size('page-md')} [content-end] minmax(0, 1fr) [full-end];
  }
  @include u.below('md') {
    grid-template-columns: [full-start] #{u.size('unit')} [content-start] calc(100% - 16px) [content-end] #{u.size('unit')} [full-end];

  }

  > * {
    grid-column: content;
    //padding: 0 var(--grid-container-gutter);
  }

  .wide {
    grid-column: full;
  }
}
