@use "src/assets/scss/utils" as u;

.calendar-navigation {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  //width: 450px;
  margin: 0 0 u.size('unit');
  @include u.below('md') {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    justify-content: flex-start;
    padding: u.size('unit') u.size('unit-2x') u.size('unit') 0;
    box-sizing: border-box;

  }

  &__container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    @include u.below('md') {
      width: 100%;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: u.size('unit-2x');
        background: linear-gradient(90deg, rgba(u.get-color-value('body-background'), 0) 0%, rgba(u.var-color('body-background-rgb'), 1) 100%);
        //background: #000;
      }
    }
  }

  &__item {
    display: inline-block;

    &:not(:first-child) {
      margin: 0 0 0 10px;
    }

    @include u.below('md') {
      &:last-child {
        padding: 0 u.size('unit-4x') 0 0;
      }
    }

    &#active-subsection a {
      background-color: $optwear-blue-strong;
      color: $optwear-white !important;
    }
  }
}
