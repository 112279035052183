@use "src/assets/scss/utils" as u;

.index-welcome {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;
  margin: 0 0 30px;
  grid-column: full;
  background: $optwear-blue-gradient;
  @include b(block) {
    width: 100%;
    &:not(:last-child) {
      padding: 0 0 24px;
      margin: 0 0 24px;
      border-width: 0 0 2px;
      border-style: solid;
      border-color: #fff;
    }
    @include wrapper;
    @include e(content) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
  @include e(subtitle) {
    margin: 0 0 6px;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    @include u.below('md') {
      text-align: center;
    }
  }
  @include b(flow) {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 16px;
    @include u.below('xs') {
      flex-direction: column;
      justify-content: initial;
    }
    @include b(step) {
      display: flex;
      align-items: center;
      color: #fff;
      @include u.below('md') {
        flex: 2;
      }
      @include u.below('sm') {
        flex: initial;
        width: 100%;
        flex-direction: column;
      }
      @include e(number) {
        margin: 0 16px 0 0;
        font-size: 52px;
        @include u.below('sm') {
          margin: 0;
        }
      }
      @include b(heading) {
        font-size: 13px;
        @include u.below('sm') {
          text-align: center;
        }
        @include e(title) {
          margin: 0 0 6px;
          font-weight: 600;
        }
      }
    }
    @include e(hr) {
      flex: 1;
      text-align: center;
      @include u.below('xs') {
        width: 100%;
      }
      &:after {
        content: '⟶';
        color: #fff;
        font-size: 56px;
        @include u.below('md') {
          padding: 0 8px;
        }
        @include u.below('xs') {
          padding: initial;
          content: '\2193';
          font-size: 64px;
        }
      }
    }
  }
  @include e(main-title) {
    margin: 0 0 16px;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    @include u.below('md') {
      text-align: center;
      font-size: 24px;
    }
  }
  @include e(title) {
    margin: 0 0 24px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    @include u.below('md') {
      text-align: center;
      font-size: 20px;
    }
  }
  @include e(text) {
    width: 100%;
    margin: 0 0 16px;
    color: #fff;
    font-size: 16px;
    text-align: center;
  }
}
