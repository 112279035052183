@use "src/assets/scss/utils" as u;

.beginner {
  background-color: #2682c1;
  color: $optwear-white;
  counter-reset: index-beginner-steps;

  &-container {
    max-width: 1640px;
    position: relative;
    padding: 30px 20px;
    margin: auto;
  }

  &__header {
    margin: 15px 0;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    font-size: 20px;

    h1 {
      font-size: 1em;
      margin: 0.5em 0;
    }
  }

  &__help {
    margin: 15px 0;
    text-align: center;
    font-size: 16px;

    span {
      font-size: 20px;
    }

    p {
      padding: 0 10%;
    }

  }

  hr {
    margin: 2em 0;
  }

  &_steps {
    h2 {
      text-align: center;
      font-size: 24px;
    }

    ul {
      margin: 2em 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        position: relative;
        padding-right: 10%;

        &:after {
          content: '⟶';
          display: inline;
          position: absolute;
          font-size: 70px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:before {
          position: absolute;
          left: 0;
          top: -6px;
          content: counter(index-beginner-steps);
          counter-increment: index-beginner-steps;
          font-size: 50px;
          display: block;
          float: left;
        }

        &:last-child {
          padding-right: 0;

          &:after {
            content: "";
          }
        }

        p {
          display: block;
          margin: 0;
        }

        div {
          padding-left: 3em;
          padding-top: 10px;
        }
      }
    }
  }

  &_btn {
    display: block;
    margin: 0 auto;
    padding: 5px;
    color: $color-text !important;
    border-radius: 100px;
    background-color: $optwear-orange-light;
    text-decoration: none !important;
    width: 200px;
    font-size: 14px;
    line-height: 1.42857143;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    transition: all 0.25s;

    &:hover, &:focus {
      background-color: $optwear-orange-middle;
      text-decoration: none !important;
    }
  }
}
