.ryd-wrapper {
	border: 1px solid #ccc;
	padding: 5px;
	border-radius: 5px;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.free {
		color: #6FCF97;
	}

	.filled {
		color: rgba(0, 0, 0, 0.5);
	}

	.close {
		position: absolute;
		right: 5px;
		top: 5px;

		&:focus, &:hover {
			outline: none;
			color: #2D9CDB;
		}
	}

	.gray {
		color: rgba(0, 0, 0, 0.5);
	}
}

.ryd-title {
	margin-right: 0.625rem;
	margin-bottom: 0.375rem;
	display: inline-block;
}

.ryd-extend {
	display: flex;
	justify-content: center;
	margin-top: 5px;

	.btn {
		padding: 0 0.5em;
	}
}

.popup-container {
	position: absolute;
	left: calc(100% + 16px);
	z-index: 2;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.5);
	padding: 15px;
	bottom: -3.4rem;
	border-radius: 5px;
	white-space: nowrap;

	&:after, &:before {
		right: 100%;
		top: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-right-color: #fff;
		border-width: 15px;
		margin-top: -15px;
	}

	&:before {
		border-color: rgba(0, 0, 0, 0);
		border-right-color: #00000080;
		border-width: 16px;
		margin-top: -16px;
	}
}


.fade-enter-active, .fade-leave-active {
	transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
	opacity: 0;
}

.unfilled {
	color: #2D9CDB;
	white-space: nowrap;
}

.progress {
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-color: #eee;
	position: relative;
	border-radius: 5px;
	height: 27px;
	margin-bottom: 0;
}

.progress-fill {
	background-color: #6FCF97;
	border-radius: 4px;
	height: 25px;
	width: 0;
	position: absolute;
}

.progress-label {
	position: absolute;
	z-index: 3;
	left: 50%;
	color: rgba(0, 0, 0, 0.75);
	transform: translateX(-50%);
}


.lot-ryd-values {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	li {
		display: block;
		text-align: center;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-left: none;

		&:last-child {
			border-bottom-right-radius: 5px;
			border-top-right-radius: 5px;
		}

		&:first-child {
			border-left: 1px solid rgba(0, 0, 0, 0.2);
			border-bottom-left-radius: 5px;
			border-top-left-radius: 5px;
		}
	}

	&__filled {
		background-color: rgba(0, 0, 0, 0.2);

		.btn {
			color: #000;
		}

		&-you {
			.btn {
				color: #fff;
			}

			background-color: $optwear-orange-middle;
		}
	}

	&__free {
		background-color: #6FCF97;

		.btn {
			color: #fff;
		}
	}

	.btn {
		padding: 0;
	}
}
