@use "src/assets/scss/utils" as u;

.office-banners {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: u.size('unit-2x');
  margin-top: 34px;
  box-sizing: border-box;
  @include u.below('md') {
    padding: u.size('unit');
    align-items: flex-start;
  }
  @include u.below('xs') {
    flex-direction: column;
  }

  &__post-image {
    margin: 0 u.size('unit-4x') 0 0;
    @include u.below('xs') {
      order: 3;
      margin: u.size('unit-2x') 0 0;
      width: u.size('unit-10x') * 2;
      height: auto;
    }
  }

  &__body {
    font-size: u.size('text-md');
    @include u.below('md') {
      font-size: u.size('text-sm');
    }

    p, h2 {
      margin: 0;
    }

    h2 {
      line-height: 28px;
      font-size: u.size('text-lg');
      @include u.below('md') {
        font-size: u.size('text-md');
      }
    }

    p {
      line-height: 22px;
    }
  }

  &--home {
    padding: u.size('unit-4x') u.size('unit-2x');
    @include u.below('md') {
      padding: u.size('unit-2x') u.size('unit');
    }
    display: block;
    background: #deb489 url("~@Assets/imageset-old/bg_delivery.jpg") no-repeat;
    background-position: center center;
    background-size: cover, auto;
    @include u.below('xs') {
      background-position: left center;
    }
  }
}
