@use "src/assets/scss/utils" as u;

.TopicQuickReplyButtonsBB-button--smile {
  padding: 0;
  display: block;
  font-size: 0;

}

.TopicQuickReplySmiles {
  border-top: 1px solid $color-background;
  background-color: #efefef;
  width: 100%;
  overflow: hidden;

  &App {
    width: 100%;
    overflow: hidden;

    &.hidden {
      display: none;
    }
  }

  &__container {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    display: none;

    &.active {
      display: flex;
    }
  }
}
