@use "src/assets/scss/utils" as u;

.office-list {
  border: 1px solid $color-background;
  background-color: $optwear-white;
  margin: 0;
  height: 100%;
  padding: 0;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: normal;


  &__container {
    overflow: hidden;
    min-width: u.size('unit-10x') * 4;
    flex: 1;
    height: 100%;
    @include u.below('md') {
      width: u.size('unit-10x') * 3;
    }
    @include u.below('sm') {
      width: 100%;
      height: u.size('unit-10x') * 3;
    }
  }
}

@import "office-row";
