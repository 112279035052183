@use "src/assets/scss/utils" as u;

.TopicPostProfile {
  padding: 5px;
  display: flex;
  flex-direction: column;
  @include u.below('sm') {
    flex-direction: row;
  }

  &-wrap {
    max-width: $topicPost-left-width;
    min-width: $topicPost-left-width;
    border-right: 1px solid $color-background;
    border-left: 1px solid $color-topic-background;
    @include u.below('sm') {
      width: 100%;
      max-width: none;
    }
  }


  &-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    box-sizing: border-box;
    margin: 0 0 u.size('unit-2x');
    position: relative;
    @include u.below('md') {
      //visibility: hidden;
      //opacity: 0;
      //display: none;
      grid-area: info;
      align-items: center;
      flex-direction: row;
      margin: 0;
      padding: 0;
      a {
        color: $color-text-link;
      }
    }
    //@include u.below('sm') {
    //		padding: 0 0 0 15px;
    //		width: auto;
    //}
    &-mobile {

    }
  }


  &Details {
    width: 100%;
    padding-top: 5px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include u.above('md') {
      line-height: u.size('unit-3x');
      box-sizing: border-box;
    }

    &-label {
      //margin: 0 0 5px;
      color: darken($color-background, 35%);
      font-family: $decor-font-family;
      margin: 0 u.size('unit-half') 0 0;
    }

    &-mobile {

      &-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        @include u.below('md') {
          padding: u.size('unit-half');
          border: 1px solid $optwear-blue-strong;
          border-radius: u.size('unit');
        }
      }

      @include u.below('md') {

        user-select: none;

        //padding: u.size('unit-half');
        width: auto;
        padding: 0;
        line-height: 1;
        box-sizing: border-box;
        font-size: u.size('text-xs');
        &:not(:last-of-type) {
          margin: 0 u.size('unit-half') 0 0;
        }
        .TopicPostProfileDetails-label {
          display: none;
        }
      }
    }

    &-icon {

      color: #333;
      vertical-align: middle;
      margin: 0 u.size('unit') 0 0;
      display: flex;
      align-items: center;

      svg {
        width: u.size('unit-half-2x');
        height: u.size('unit-half-2x');

        vertical-align: middle;
      }

      @include u.below('xs') {
        margin: 0 u.size('unit-half') 0 0;
        svg {
          width: u.size('unit');
          height: u.size('unit');
        }
      }

      @include u.above('md') {
        display: none;
      }
    }

    &-more {
      display: none;
      @include u.below('md') {
        display: flex;
        flex-direction: column;
      }
    }

    &-hr {
      border: none;
      border-top: 1px solid $color-background;
      //max-width: $topicPost-left-width - 10px;
      background-color: $color-background;
      width: 100%;
      margin: u.size('unit') 0;
    }

    &-moreCollapse {
      @include button-reset;
      display: block;
      margin: 0 auto;
      outline: none 0;

      &:after {
        content: "";
        left: 10px;
        top: -2px;
        position: relative;
        border: solid darken($color-background, 35%);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        outline: none 0;
      }

      &--show:after {
        outline: none 0;
        top: 2px;
        transform: rotate(-135deg);
      }

      @include u.below('sm') {
        margin: 0;
        width: 100%;
        text-align: center;
      }
    }

    @include u.below('sm') {
      max-width: none;
    }
  }

  &-username {
    display: none;
    font-size: 16px;
    justify-content: center;
    padding: 15px 5px;
    width: 100%;

    @include u.below('sm') {
      display: flex;

    }
  }

  &-avatar {
    padding: 10px 5px;
    box-sizing: border-box;
    @include u.below('md') {
      order: -1;
      //@include u.square(64px);
      //border-radius: 100%;
      //overflow: hidden;
      padding: 0;
      margin: 0 u.size('unit') 0 0;

      grid-area: avatar;
      ~ .TopicPostHeader-author__dropdown {
        top: -10px;
      }
      img {
        object-fit: contain;
        font-family: "object-fit: contain;";
        width: 100%;
        height: 100%;


      }
    }

    //@include u.below('sm') {
    //		@include u.square(u.size('unit-6x'));
    //}

    //img {
    //		max-width: 100%;
    //		height: auto;
    //		display: block;
    //		overflow: hidden;
    //		margin: 0 auto;
    //}
  }

  &-rankImg {
    //max-width: $topicPost-left-width - 10px;
    border-bottom: 1px solid $color-background;
    width: 100%;
    padding: 0 0 u.size('unit');
    margin: 0 0 u.size('unit');
  }

  &-reputationPoints {
    &-count {
      margin: 0 u.size('unit-half') 0 0;
      @include u.below('md') {
        margin: 0 u.size('unit-2x') 0 0;
      }
    }

    &Button {
      svg {
        color: u.var-color('grey-40');
        width: u.size('unit-half-2x');
        height: u.size('unit-half-2x');
        @include u.below('md') {
          width: u.size('unit-2x');
          height: u.size('unit-2x');
        }
      }

      display: flex;
      align-items: center;


      &--up {
        &:hover,
        &:focus, {

          text-decoration: none;

          svg {
            color: u.var-color('success');
          }
        }

        margin: 0 u.size('unit-half') 0 0;
        @include u.below('md') {
          margin: 0 u.size('unit') 0 0;
        }
      }

      &--down {

        &:hover,
        &:focus {

          text-decoration: none;

          svg {
            color: u.var-color('danger');
          }
        }
      }
    }
  }

  &-reviews {
    height: 100%;
    //max-width: $topicPost-left-width;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @include u.below('md') {
      padding: 0 u.size('unit');
      line-height: u.size('line-height');
    }

    &-modal {
      @include u.above('md') {
        width: 100%;
      }
    }

    &-button {
      grid-area: reviews_button;
      padding: u.size('unit');
      border-radius: u.size('unit');
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid u.var-color('grey-25');
      margin: u.size('unit') 0 0;
      width: 200px;
      @include u.below('sm') {
        width: 100%;
      }
    }

    &-item {
      width: 100%;

      display: block;
      border-bottom: 1px solid u.var-color('grey-25');
      padding: u.size('unit') 5px;
      box-sizing: border-box;
      @include u.above('md') {
        overflow: hidden;
      }

      &:first-of-type {

        @include u.above('md') {
          border-top: 1px solid u.var-color('grey-25');
        }
      }

      &:last-of-type {
        @include u.below('md') {
          border-bottom: none;
        }
      }

      &-avatar {
        margin: 0 u.size('unit') 0 0;
      }

      &-profile {
        display: flex;
        flex-direction: column;
        font-size: u.size('text-sm');
      }

      &-header {
        display: flex;
        min-height: u.size('unit-4x');
        margin: 0 0 u.size('unit-2x');

      }

      &-username {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-date {
        color: u.var-color('text-ltr');
      }

      &-body {
        max-height: 250px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        padding: 0 0 u.size('unit-5x');
        box-sizing: border-box;
      }

      &-content {
        font-size: u.size('text-xs');
      }

      &-link {
        width: 100%;
        height: u.size('unit-5x');
        background: linear-gradient(180deg, rgba(#f7f7f7, 0) 0, rgba(#f7f7f7, .85) 16px, #f7f7f7 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: u.size('text-sm');
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @include u.below('md') {
          background: linear-gradient(180deg, rgba(#fff, 0) 0, rgba(#fff, 1) 16px, #fff 100%);
        }

        a {
          line-height: u.size('unit-3x');
          text-decoration: none;
          @include u.above('md') {

          }
        }
      }

      img {
        &[src*="forum/images/smilies"] {
          width: 25px;
          height: auto;
        }

        max-width: 100%;
        height: 100px;
        object-fit: contain;
        font-family: "object-fit: contain;";
      }

    }
  }

  &-mobile-button {
    margin: 0;

    .TopicPostProfileDetails-icon {
      margin: 0;
      //svg {
      //		@include u.square(u.size('unit-2x'));
      //		@include u.below('xs') {
      //				@include u.square(u.size('unit-half-2x'));
      //		}
      //}
    }

  }

  &-medal {
    &Wrap {
      @include u.below('md') {
        display: flex;
        align-items: center;
        min-height: u.size('unit-5x');
      }
    }

    &-item {
      &:not(:last-of-type) {
        margin: 0 u.size('unit-half') 0 0;
      }

      @include u.below('md') {
        margin: 0 u.size('unit') 0 0;
        width: u.size('unit-3x');
        height: u.size('unit-3x');
        object-fit: contain;
        font-family: "object-fit: contain;";
      }
    }
  }
}

.viewtopic-page {
  .catalog-sort-types {
    &:after {
      background: linear-gradient(90deg, rgba(#fff, 0) 0%, #fff 100%);
    }
  }
}
