@use "src/assets/scss/utils" as u;

.ucp {
  form[name="viewfolder"] {
    table.tablebg, .row1 {
      background-color: $optwear-white;
    }

    .ucp-pm-viewfolder-page-header {
      display: flex;
      flex-wrap: wrap;
      background: #1d809b;
      padding: u.size('unit') 0;
      color: #ffffff;
      @include u.below('md') {
        background: $optwear-blue-strong;

        align-items: center;
        padding: 10px 10px 10px 0;
        box-sizing: border-bow
      }

      .ucp-pm-viewfolder-page-select-all {
        height: u.size('unit-6x');
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          vertical-align: middle;
        }

        @include u.below('md') {
          width: 48px;
        }
      }

      .ucp-pm-viewfolder-page-actions {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 u.size('unit') 0 0;
        box-sizing: border-box;
        @include u.below('md') {
          padding: 0;
        }

        .ucp-folder-actions-select {
          flex: 1;
          width: auto;
        }
      }

      .ucp-pm-viewfolder-page-filters {
        min-width: 100%;
        margin: 0 0 u.size('unit-2x');
      }


    }

    table.tablebg-interactive {
      tbody {

      }
    }

    .topictitle, .topicauthor {
      position: relative;
      float: left;

      &:after {
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        transform: translateY(-50%);
      }
    }

    .topictitle {
      //padding-left: 20px;
      @include u.above('md') {
        max-width: 385px;
      }
      @include u.above('lg') {
        max-width: 533px;
      }

      display: flex;
      flex-direction: column;
      width: 100%;
      float: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include u.below('md') {
        //display: flex;
        //align-items: center;
        padding-left: 0;

      }

      .message-short-text {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 2;
        //-webkit-box-orient: vertical;
        //overflow: hidden;
      }

      a {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        @include u.below('md') {

          //display: -webkit-box;
          //-webkit-line-clamp: 2;
          //-webkit-box-orient: vertical;
          //overflow: hidden;
        }
      }
    }

    .topicauthor {
      //width: 120px;
      padding: 0 5px 0 0;
      //word-wrap: break-word;
      overflow: hidden;
      box-sizing: border-box;
      @include u.above('md') {
        width: 140px;
      }
      @include u.below('md') {
        padding: 0;
      }

      a {
        float: left;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .ucp-folder-message {


      @include u.below('md') {
        padding: 10px 10px 10px 0;
        display: grid;
        grid-template: "checkbox username date" 32px "icon subject ." minmax(32px, auto) / 48px 1fr auto;
      }

      &__checkbox {
        @include u.above('md') {
          width: 30px;
        }
        @include u.below('md') {
          grid-area: checkbox;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__icon {
        @include u.above('lg') {
          width: 50px;
        }
        @include u.above('md') {
          width: 30px;
        }
        @include u.below('lg') {
          img {
            width: 15px;
            height: 15px;
          }
        }
        @include u.below('md') {
          grid-area: icon;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__username {
        overflow: hidden;
        @include u.above('md') {
          width: 140px;
          text-align: start;
        }
        @include u.below('md') {
          grid-area: username;
          display: flex;
          align-items: center;
        }
      }

      &__subject {
        overflow: hidden;
        @include u.above('lg') {
          width: 533px;
        }
        @include u.above('md') {
          max-width: 385px;
        }
        @include u.below('md') {
          grid-area: subject;
          display: flex;
          align-items: center;

        }
      }

      &__date {
        @include u.above('lg') {
          width: 120px;
        }
        @include u.above('md') {
          width: 90px;
        }
        @include u.below('md') {
          grid-area: date;
          display: flex;
          align-items: center;
        }
      }
    }

    .ucp-folder-message__deleted {
      opacity: 0.5;
    }

    .topicdetails {
      display: flex;
      align-items: center;
      justify-content: center;
      @include u.below('md') {
        font-size: 13px;
      }
    }

    .ucp-folder-message_unread .topictitle,
    .ucp-folder-message_unread .topicauthor,
    .ucp-folder-message_unread .topicdetails {
      font-weight: bold;
    }


    .ucp-folder-message_unread, .pm_marked_colour, .pm_replied_colour {
      .topictitle {
        padding-left: 20px;
      }
    }

    .pm_friend_colour, .pm_foe_colour {
      .topicauthor {
        padding-left: 20px;
      }
    }

    .pm_friend_colour .topicauthor:after {
      content: " ";
      background-color: $optwear-orange-light;
    }

    .pm_foe_colour .topicauthor:after {
      content: " ";
      background-color: desaturate(#ff0000, 50%);
    }

    .pm_replied_colour .topictitle:after {
      content: " ";
      background-color: $color-background;
    }

    .pm_marked_colour .topictitle:after {
      content: " ";
      background-color: $color-text;
    }

    //.pm_marked_colour .topictitle:after {
    //		content: " ";
    //		background-color: $color-text;
    //}

    .ucp-folder-message_unread .topictitle:after {
      content: " ";
      background-color: $optwear-blue-light;
    }

    .ucp-folder-actions {
      //padding-left: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 0 0 10px;
      box-sizing: border-box;
      overflow: hidden;
      @include u.below('xs') {
        padding: 0;
        flex-wrap: wrap;
      }

      &-select {
        margin: 0 10px 0 0;
        width: 300px;
        @include u.below('xs') {
          width: 100%;
          margin: 0 0 5px;
        }
      }

      @include u.below('md') {

      }

      & > * {
        float: left;
      }
    }
  }
}
