.demo-container {padding: 0px 0;}
.demo-container h4 {font-size: 14px; margin: 0 0 5px 0;}

/* Mega Menu Styles */
.mega-menu ul, .mega-menu ul li {list-style: none;}
.mega-menu ul {position: relative; padding: 0; margin: 0;}
.mega-menu ul li ul {display: none;}
.mega-menu .sub {display: none;}
.mega-menu .sub ul {display: block;}

#mega-menu-1 {
font: normal 13px Arial, sans-serif;
list-style: none;
position: relative;
padding: 0;
margin: 0;
}
#mega-menu-1 .sub ul {
display: block;
}
#mega-menu-1 {
background: #222;
width: 100%;
height: 36px;
position: relative;
}
#mega-menu-1 li {
float: left;
margin: 0;
padding: 0;
font-weight: bold;
}
#mega-menu-1 li a {
float: left;
display: block;
color: #fff;
padding: 12px 25px;
background: #222;
text-decoration: none;
}
#mega-menu-1 li.mega-hover a, #mega-menu-1 li.mega-hover a:hover {
background: #CCC;
color: #000;
}
#mega-menu-1 li a:hover {
background: #999;
color: #000;
}
#mega-menu-1 li .sub-container {
position: absolute;
}
#mega-menu-1 li .sub {
background: #efefef;
padding: 15px;
border: 1px solid #ccc;
}
#mega-menu-1 li .sub .row {
width: 100%;
overflow: hidden;
clear: both;
}
#mega-menu-1 li .sub li {
list-style: none;
float: none;
width: 170px;
font-size: 1em;
font-weight: normal;
}
#mega-menu-1 li .sub li.mega-hdr {
margin: 0 10px 10px 0;
float: left;
}
#mega-menu-1 li .sub li.mega-hdr.last {
margin-right: 0;
}
#mega-menu-1 li .sub a {
background: none;
color: #111;
padding: 7px 10px;
display: block;
float: none;
font-size: 0.9em;
}
#mega-menu-1 li .sub li.mega-hdr a.mega-hdr-a {
padding: 5px 15px;
margin-bottom: 5px;
background: #6B6B6B;
text-transform: uppercase;
font-weight: bold;
color: #fff;
}
#mega-menu-1 li .sub li.mega-hdr a.mega-hdr-a:hover {
color: #000;
}
#mega-menu-1 .sub li.mega-hdr li a {
padding: 4px 5px;
font-weight: normal;
}
#mega-menu-1 .sub li.mega-hdr li a:hover {
color: #a32403;
background: #efefef;
}
#mega-menu-1 .sub ul li {
padding-right: 0;
}
#mega-menu-1 li .sub-container.non-mega .sub {
padding: 10px;
}
#mega-menu-1 li .sub-container.non-mega li {
padding: 0;
width: 190px;
margin: 0;
}
#mega-menu-1 li .sub-container.non-mega li a {
padding: 7px 5px 7px 22px;
}
#mega-menu-1 li .sub-container.non-mega li a:hover {
color: #a32403;
background: #efefef;
}

#notable table{
border:0px;
border-collapse:collapse;
}

#notable table td{
padding:0px;
}


.mega-menu ul, .mega-menu ul li {list-style: none;}
.mega-menu ul {position: relative; padding: 0; margin: 0;}
.mega-menu ul li ul {display: none;}
.mega-menu .sub {display: none;}
.mega-menu .sub ul {display: block;}

.light-blue {font: normal 13px Arial, sans-serif; line-height: 16px;}
.light-blue ul.mega-menu, .light-blue ul.mega-menu, .light-blue ul.mega-menu li {margin: 0; padding: 0; border: none;}
.light-blue ul.mega-menu {background: #222 url('~@Assets/imageset-old/images-old-optwear/bg_light_blue.png') repeat-x 0 -80px; width: 100%; height: 36px; border-right: 1px solid #44B3FF; border-left: 1px solid #44B3FF; position: relative;}
.light-blue ul.mega-menu li {float: left; margin: 0; padding: 0; font-size: 13px; font-weight: bold;}
.light-blue ul.mega-menu li a {float: left; display: block; color: #fff; padding: 10px 6px 10px 3px; background: url('~@Assets/imageset-old/images-old-optwear/bg_light_blue.png') repeat-x 100% 0;  text-decoration: none;}
.light-blue ul.mega-menu li a.dc-mega {position: relative;}
.light-blue ul.mega-menu li a .dc-mega-icon {display: block; position: absolute;  top: 27px; right: 5px; width: 8px; height: 6px; background: url('~@Assets/imageset-old/images-old-optwear/arrow.png') no-repeat 0 100%;}
.light-blue ul.mega-menu li.mega-hover a, .light-blue ul.mega-menu li a:hover {background-position: 100% -36px; color: #000; text-shadow: none;}
.light-blue ul.mega-menu li.mega-hover a .dc-mega-icon {background-position: 0 100%;}
.light-blue ul.mega-menu li .sub-container {position: absolute; background: url('~@Assets/imageset-old/images-old-optwear/bg_sub_left.png') no-repeat 0 100%; padding-left: 20px; margin-left: -3px;}
.light-blue ul.mega-menu li .sub {background: url('~@Assets/imageset-old/images-old-optwear/bg_sub.png') no-repeat 100% 100%; padding: 20px 20px 20px 10px;}
.light-blue ul.mega-menu li .sub-container.mega .sub {padding: 20px 20px 10px 0;}
.light-blue ul.mega-menu li .sub .row {width: 100%; overflow: hidden; clear: both;}
.light-blue ul.mega-menu li .sub li {list-style: none; float: none; width: 170px; font-size: 1em; font-weight: bold;}
.light-blue ul.mega-menu li .sub li.mega-hdr {margin: 0 10px 10px 0; float: left;}
.light-blue ul.mega-menu li .sub li.mega-hdr.last {margin-right: 0;}
.light-blue ul.mega-menu li .sub a {background: none; border: none; text-shadow: none; color: #111; padding: 7px 10px; display: block; float: none; text-decoration: none; font-size: 0.9em;}
.light-blue ul.mega-menu li .sub li.mega-hdr a.mega-hdr-a {padding: 5px 5px 5px 15px; margin-bottom: 5px; background: #4EC3F3 url('~@Assets/imageset-old/images-old-optwear/bg_mega_hdr.png') no-repeat 0 0; text-transform: uppercase; font-weight: bold; color: #000; text-shadow: 1px 1px 1px #fff;}
.light-blue ul.mega-menu li .sub li.mega-hdr a.mega-hdr-a:hover {color: #fff; text-shadow: none;}
.light-blue ul.mega-menu .sub li.mega-hdr li a {padding: 4px 5px 4px 20px; background: url('~@Assets/imageset-old/images-old-optwear/arrow_off.png') no-repeat 5px 8px; font-weight: bold;}
.light-blue ul.mega-menu .sub li.mega-hdr li a:hover {color: #a32403; background: #efefef url('~@Assets/imageset-old/images-old-optwear/arrow_on.png') no-repeat 5px 8px;}
.light-blue ul.mega-menu .sub ul li {padding-right: 0;}
.light-blue ul.mega-menu li .sub-container.non-mega .sub {padding: 20px 20px 20px 0;}
.light-blue ul.mega-menu li .sub-container.non-mega li {padding: 0; width: 190px; margin: 0;}
.light-blue ul.mega-menu li .sub-container.non-mega li a {padding: 7px 5px 7px 22px; background: url('~@Assets/imageset-old/images-old-optwear/arrow_off.png') no-repeat 7px 10px;}
.light-blue ul.mega-menu li .sub-container.non-mega li a:hover {color: #a32403; background: #efefef url('~@Assets/imageset-old/images-old-optwear/arrow_on.png') no-repeat 7px 10px;}
