.ui-tooltip {
	position: absolute;
	border-radius: 2px;
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
	background-color: $optwear-white;
	padding: 16px;
	border: 0;
	&-content {
		&:after {
			position: absolute;
			width: 14px;
			height: 14px;
			border-bottom: none;
			border-right: none;
			border-radius: 2px;
			content: " ";
			-webkit-filter: none;
			filter: none;
			background: linear-gradient(135deg, $optwear-white 0%, $optwear-white 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
			z-index: 1;
			top: 10px;
			left: 0;
			transform: matrix(0.70711, -0.70711, 0.70711, 0.70711, 0, -6);
			transform-origin: 0 100%;
		}
	}
}
