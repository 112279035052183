@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";
@import "../ForumList/ForumList";
@import "../AnnouncementBlock/announcement-block";
@import "../Topic/Topic";
@import "../ServiceMessage/ServiceMessage";

//.Main {
//	padding: 15px 0;
//	background-color: #ebebeb;
//	height: 100%;
//	flex-grow: 2;
//	width: 100%;
//	&_white {
//		background: $optwear-white;
//	}
//	&Container {
//		@include grid;
//		display: flex;
//		flex-direction: row-reverse;
//		&-noAds {
//			display: block;
//		}
//		&-SPTopicAds {
//			width: $optwear-container-width;
//			max-height: 100px;
//		}
//	}
//	&--SPTopic {
//		padding: 0;
//	}
//}

.site-main {
  width: 100%;
  background: #ebebeb;
  height: 100%;
  flex: 1 1 auto;
  padding: 30px 0;
  @include e(content);
}

#yandex-header {
  display: flex;
  justify-content: center;
  height: u.size('unit-10x');
  overflow: hidden;

  @include u.below('xs') {
    height: u.size('unit-8x');
  }
}
