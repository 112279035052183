/* Переопределение библиотечных стилей */
.fancybox-toolbar div {
	display: inline-block;
	height: 44px;
	line-height: 42px;
	background: rgba(30, 30, 30, 0.6);
	color: #cccccc;
	font-size: 12px;
	font-weight: bold;
	min-width: 42px;
	text-align: center;
	white-space: nowrap;
}

.fancybox-thumbs {
	transition: opacity 0.35s, visibility 0s;
	visibility: hidden;
	opacity: 0;
	.fancybox-show-thumbs & {
		display: flex;
		justify-content: center;
	}
	& ul li {
		float: left;
		margin: 1px 0 0 -1px;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		overflow: hidden;
		background-color: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 3px;
		height: 76px;
		width: 76px;
		&:before {
			opacity: 1;
			border-color: #ffffff;
		}
	}
	& &-active:before {
		border-color: $optwear-blue-light;
	}

}

.fancybox-button {
	&--close {
		background-color: #ffffff;
		border: 0 none;
		border-radius: 0 0 3px 3px;
		box-shadow: 1px 1px 10px #333333;
		color: $color-background;
		cursor: pointer;
		font-size: 20px;
		font-weight: bold;
		height: 48px;
		position: fixed;
		right: 20px;
		top: 0;
		width: 60px;
		z-index: 1;

		&:hover {
			color: $optwear-blue-light
		}
	}
	.fancybox-navigation &--arrow_left,
	.fancybox-navigation &--arrow_right {
		width: 60px;
		height: 60px;
	}
}

@media all and (min-width: 800px) {

	.fancybox-thumbs {
		top: auto;
		width: auto;
		bottom: 0;
		left: 0;
		right: 0;
		height: 95px;
		padding: 10px 10px 0;
		box-sizing: border-box;
		background: #ffffff;
		opacity: 0;
	}

	.fancybox-show-thumbs .fancybox-inner {
		right: 0;
		bottom: 95px;
		overflow: visible;
	}
	.fancybox-thumbs::-webkit-scrollbar {
		height: 7px;
	}

	.fancybox-thumbs::-webkit-scrollbar-track {
		background: #2a2a2a;
		border-radius: 10px;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	.fancybox-thumbs::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 10px;
	}
}

.fancybox-show-nav .fancybox-thumbs {
	opacity: 0.95;
	visibility: visible;
}
