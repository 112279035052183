@use "src/assets/scss/utils" as u;

.forum-list {
  flex: 1;
  @include u.below('md') {
    width: 100%;
    flex: initial;
  }
}

.viewforum-page .ForumList {
  .ForumListTable-header {
    th:nth-child(2) {
      //@include u.below('md') {
      //		display: table-cell;
      //}
      @include u.below('md') {
        display: none;
      }
    }

    th:nth-child(3) {
      //@include u.below('md') {
      //		display: table-cell;
      //}
      @include u.below('sm') {
        display: none;
      }
    }
  }

  tbody {
    tr {
      @include u.below('sm') {
        flex-flow: row wrap;
      }

      td:nth-child(2), td:nth-child(3) {
        @include u.below('md') {
          display: table-cell;
        }
      }

      td:nth-child(4) {
        @include u.below('md') {
          display: none;
        }
      }

      @include u.below('sm') {
        td:nth-child(1), td:nth-child(2) {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .ForumListTable-SubForum, .ForumListTable-SubForum-description, .ForumListTable-SubForum-level3 {
          width: 100%;
        }
        .ForumListTable-hr {

        }
        td:nth-child(1) {
          width: auto;
          border-width: 0;
        }
        td:nth-child(2) {
          width: auto;
          border-width: 0;
          order: 2;
        }
        td:nth-child(3) {
          border-width: 0;
          width: 100%;
          order: 1;
        }
        td:nth-child(5) {
          order: 3;
          flex: 1;
          max-width: none;
        }

      }
    }
  }
}

.ForumList {

  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;

  td.cat {
    padding: u.size('unit');
    text-align: left;
    box-sizing: border-box;
    @include u.below('sm') {
      text-align: center;
      display: flex;
      flex-direction: column;
      padding: u.size('unit');
      select {
        margin: 0 0 u.size('unit-half');
      }
    }
  }

  &-container {
    flex: 1;
    @include u.below('md') {
      overflow-x: hidden;
      flex: auto;
    }

    h1 {
      margin: 0;
      @include u.below('md') {
        font-size: 20px;
      }
    }
  }

  &-Controllers {
    padding: 10px 0;
    display: flex;
    @include u.below('md') {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  tbody {
    td {
      &:not(.cat) {
        @include u.below('sm') {
          background-color: transparent;
        }
      }
    }

    .ForumListTable-themes {
      @include u.below('sm') {
        width: 100%;
        text-align: left;
      }

      &:before {
        content: 'Темы';
        font-size: u.size('text-md');
        color: #000;
        margin: u.size('unit') 0 u.size('unit-half');
        width: 100%;
        display: none;
        @include u.below('sm') {
          display: block;
        }
      }
    }

    .ForumListTable-author {
      @include u.below('sm') {
        width: 100%;
        text-align: left;
      }

      &:before {
        content: 'Автор';
        font-size: u.size('text-md');
        color: #000;
        margin: u.size('unit') 0 u.size('unit-half');
        width: 100%;
        display: none;
        @include u.below('sm') {
          display: block;
        }
      }
    }

    .ForumListTable-lastMessage, .ForumListTableTopic-LastPost {
      p {
        margin: 0;
      }

      &:before {
        content: 'Последнее сообщение';
        font-size: u.size('text-md');
        color: #000;
        margin: u.size('unit') 0 u.size('unit-half');
        width: 100%;
        display: none;
        @include u.below('sm') {
          display: block;
        }
      }

      @include u.below('sm') {
        width: 100%;
        text-align: left;
      }
    }


    tr {
      @include u.below('sm') {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.forum-list-page, .viewforum-page .tablebg.ForumListTable {
  .ForumListTable-header {
    th:nth-child(1) {
      @include u.below('sm') {
        width: 100%;
      }
    }

    th:nth-child(2), th:nth-child(3) {
      @include u.below('md') {
        display: none;
      }
    }

    th:nth-child(4) {
      @include u.below('sm') {
        display: none;
      }
    }
  }

  tbody {

    tr {
      @include u.below('sm') {
        display: flex;
        flex-direction: column;
      }

      &:nth-child(1) {
        td:nth-child(4) {
          @include u.below('sm') {
            display: none;
          }
        }
      }

      td:nth-child(2), td:nth-child(3) {
        @include u.below('md') {
          display: none;
        }
      }

      td:nth-child(4) {
        @include u.below('sm') {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}


.ForumListTable.ForumListTable-ViewForum {
  @include u.below('xs') {
    .ForumListTable-forumRow {
      display: grid;
      grid-template: 'image heading' auto / u.size('unit-7x') 1fr;
    }

    .ForumListTableTopic-heading {
      grid-area: heading;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;

      > p {
        display: none;
      }

      .ForumListTableTopic-title {
        line-height: 1.2;
        margin: 0 0 u.size('unit');
      }
    }

    .ForumListTableTopic-image {
      grid-area: image;
      display: flex;
      flex-direction: column;
      justify-content: space-between !important;

      .sp_them_stat {
        @include u.square(u.size('unit-4x'));
      }

      &:after {
        content: 'Автор: ';
        display: block;
        margin: auto 0 0;
      }
    }


    .ForumListTableTopic-LastPost {
      display: none;
    }
    .ForumListTableTopic-pagination {
      display: none;
    }

    .ForumListTableTopic-author {
      &-text {
        display: none;
      }
    }
  }
}

@import "ForumList-Btn";
@import "ForumListTable/ForumListTable";
@import "ForumListTable/ForumListTable-HiddenLinksBtn";
@import "ForumListAds";
@import "ForumListNav";

.b-forum-list {

  &-rules {
    width: 100%;
    display: flex;
    justify-content: center;

    &-wrap {
      margin: 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      max-width: 100%;
    }
  }
}
