@use "src/assets/scss/utils" as u;

.TopicPoll {
  margin-bottom: 10px;

  &-question {
    display: block;
    text-align: center;
    font-size: 1.5em;
    font-weight: 700;
  }

  &-length {
    display: block;
    text-align: center;
    color: $color-text-weak;
  }

  &-submit {
    float: right;
  }

  &-results {
    line-height: 2.3em;
  }

  &Stats {
    padding-bottom: 10px;

    &-selector {
      display: inline;
      vertical-align: middle;
    }

    &Row {
      &-Wrap {
        padding: 3px 0;
      }

      &--voted:before {
        content: "✔";
        display: block;
        position: absolute;
        top: 2px;
        bottom: 0;
        right: 0;
        width: 17px;
        color: $optwear-blue-strong;
      }

      margin-right: 50px;
      overflow: hidden;
      background-color: lighten($color-background, 5%);
      position: relative;
      border-radius: 2px;

      &-percent {
        font-weight: 700;
        white-space: nowrap;
        text-align: right;
        float: right;
      }

      &-progress {
        height: 20px;
        background: $optwear-blue-gradient;
        border-radius: 2px;
      }

      &-count {
        font-size: 12px;
        color: $color-text-invert;
        line-height: 20px;
        position: relative;
        text-align: center;
        margin-top: -20px;
      }
    }
  }

}
