.pristroy-edit-attention {
	padding: 0 0 0 20px;
	box-sizing: border-box;
	border-left: 3px solid $optwear-orange-middle;
	font-size: 15px;
	line-height: 18px;
	max-width: 30%;
	width: 226px;
	font-family: $second-font-family;
	&__list {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	&__item {
		margin: 0 0 5px;
	}
}
