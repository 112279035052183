@use "src/assets/scss/utils" as u;

.filters {
  &-dropdown {
    padding-top: 10px;
    padding-bottom: 7px;
    padding-left: 14px;
    white-space: normal;
    position: relative;
    margin-left: 11px;

    &:hover {
      color: $color-text-link-hover;
    }

    &:before {
      content: "";
      position: absolute;
      right: auto;
      left: 1px;
      top: -3px;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      border-top: 1px solid;
      border-right: 1px solid;
      border-left: none;
      border-bottom: none;
      display: block;
      width: 6px;
      height: 6px;
      border-top-color: rgba(0, 0, 0, 0.3);
      border-right-color: rgba(0, 0, 0, 0.3);
      transform: rotate(135deg);

    }

    &:hover &:before {
      border-top-color: $color-text-link-hover;
      border-right-color: $color-text-link-hover;
    }

  }

  &-list {
    border: none;
    display: flex;
    flex-direction: column;

    label {
      line-height: 1;
      display: flex;
      align-items: center;
      padding: 6px;

      input[type="checkbox"] {
        margin-bottom: 0;
      }
    }
  }

  &-slider__input {
    width: 100px;
    padding: 0 10px 2px;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }
  }

  &-wrapper {
    margin-top: 0.4375rem;
    padding: 10px 0;
    z-index: u.z-index('after-default'); ///
    transition: 0.25s background-color;

    &.is-stuck {
      //z-index: 7;
      //background-color: rgb(204, 204, 204);
      position: relative !important;
    }
  }

  &-sorting_and_grouping {
    border-bottom: 2px solid rgb(230, 230, 230);
    padding-bottom: 0.5625rem;
  }

  &-qty {
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.3);
    font-size: 20px;
  }

  &-selected {
    display: inline-flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &-clear {
    color: rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    display: inline-block;
    height: 1.75rem;
    transition: all 0.2s;

    &:hover {
      border-color: transparent;
    }
  }

  &-search {
    max-width: 12.5rem;
    height: 1.875rem;
    line-height: 1.875rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1.25rem;
    border: 2px solid rgb(230, 230, 230);
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: 95% 55%;
    margin-top: 0.125rem;
    margin-bottom: -0.125rem;
    padding: 0 2rem 0.25rem 0.625rem;
  }
}

