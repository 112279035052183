@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";


.breadcrumbs, ul.breadcrumbs {
  padding: 0 0;
  margin: 0 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  @include u.below('md') {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    padding: 8px 0;
  }

  &-wrapper {
    @include u.below('md') {
      position: relative;
      width: 100%;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: u.size('unit-2x');
        background: linear-gradient(90deg, rgba(u.get-color-value('body-background'), 0) 0%, rgba(u.var-color('body-background-rgb'), 1) 100%);
      }
    }
  }

  &-item {
    padding: 0 0;
    margin: 0 0;
    display: inline;
    font-size: 14px;
    @include u.below('md') {
      white-space: nowrap;
    }

    a {
      text-decoration: none;
      vertical-align: middle;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child {
      @include u.below('md') {
        &:last-child {
          padding: 0 u.size('unit-4x') 0 0;
        }
      }

      a, a:link, a:visited, a:hover, a:active {
        color: $color-text-link-hover;
        text-decoration: none;
      }
    }
  }

  li:first-child:before {
    padding: 0 0;
  }

  li:first-child:after {
    padding: 0 6px;
  }

  li:before {
    padding: 0 6px;
    color: black;
    font-size: 20px;
    position: relative;
    content: " ";
    top: 1px;
  }

  li + li:before {

    content: "\203A";
  }

}
