.pristroy-edit-tabs {
	flex-grow: 2;
	padding: 0 0 4px;
	border-bottom: 1px solid #d2d2d2;
	list-style: none;
	margin: 0;
	&-item {
		display: inline-block;
		padding: 2px 7px;
		font-size: 13px;
		&__title {
			white-space: nowrap;
			font-weight: 700;
		}
		&__link {
			font-weight: 300;
			&:hover, &:focus {
				text-decoration: none;
			}
		}
		&__num {
			color: #999;
		}
	}

}
