@use "src/assets/scss/utils" as u;

.lot-old-item {
  margin: 0;
  width: 100%;
  @include u.below('xs') {
    font-size: u.size('text-xs');
  }

  &__toCart {
    margin: u.size('unit-2x') 0 0 0;
  }

  &-param {
    &.fixed {
      > .lot-old-item-param-title-item {
        height: 44px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include u.below('md') {
          height: u.size('unit-5x');
        }
        @include u.below('xs') {
          height: u.size('unit-4x');
        }
      }

    }

    width: 100%;
    display: inline-block;

    &:not(:last-of-type) {
      margin: 0 0 u.size('unit-half');
    }

    @include u.below('xs') {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-weight: 700;
      margin: 0 u.size('unit-half') 0 0;


    }
  }

  &-image {
    &__wrapper {
      padding: 5px;
      //width: 250px;
    }

    &__container {
      //width: 410px;
      //height: 410px;
      cursor: pointer;
      position: relative;
      border: 1px solid #aaaaaa;
      -moz-box-shadow: 0 0 6px #000000;
      -webkit-box-shadow: 0 0 6px #000000;
      box-shadow: 0 0 6px #000000;
      filter: progid:dximagetransform.microsoft.dropshadow(color=#000000, offX=6, offY=5, positive=true);

    }

    &__image {
      //width: 410px;
      width: 100%;
      //padding-bottom: 100%;
      display: block;
      outline: none;
      text-decoration: none;
      position: relative;
      @include u.below('lg') {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
      }

      .image {
        //object-fit: none;
        //font-family: "object-fit: none;";
        //max-width: 100% !important;
        //max-height: 100% !important;
        //width: auto;
        //height: auto;

      }
    }

    &__gallery {
      display: none;

      &-item {
        outline: none;
        text-decoration: none;
      }
    }
  }

  & .lot__tag-group {
    left: 0;
    top: 0;
  }

  .btn__changeState {
    height: 21px;
    padding: 0 14px;
    margin: 0 0 0 u.size('unit');
    //margin-top: -5px;
    //margin-left: 5px;
    @include u.below('xs') {
      margin: u.size('unit-half') 0 0;
    }
  }

  a.link.username-coloured {
    font-family: $bold-font-family;
  }

  .lot-summary-description {
    word-break: break-word-all;
    max-height: none;
    display: flex;
    flex-direction: column;

    &.show {
      .lot-summary-description-text {
        //@include u.below('md') {
        opacity: 1;
        height: 100%;
        visibility: visible;
        transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out, height 0.25s step-start;
        //}
      }

      .lot-summary-description-icon {
        //@include u.below('md') {
        svg {
          transform: rotate(180deg);
        }

        //}
      }
    }

    margin: u.size('unit') 0;

    &-icon {
      //display: none;
      //@include u.below('md') {
      display: flex;
      align-items: center;

      //}
      svg {
        @include u.square(u.size('unit-half-2x'))
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //@include u.below('md') {
      @include u.mixins-text-height(u.size('unit-6x'));
      border-top: 1px solid u.var-color('grey-25');

      svg {
        transition: transform 0.25s ease-out;
      }

      //}
      &-inner {
        font-weight: 700;

        &:after {
          @include u.above('md') {
            content: ':';
          }
        }
      }

    }

    &-text {
      //@include u.below('md') {
      opacity: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out, height 0.15s step-end;
      //}
    }
  }
}

.lot-old-list {
  @include u.below('md') {
    display: flex;
    flex-direction: column;
  }
}

.lot-old-list-row {

  display: flex;
  @include u.below('xs') {
    display: flex;
    //flex-direction: column;
    //flex-wrap: wrap;
  }
}

.lot-old-list-row__cell {
  vertical-align: top;
  padding: 21px 5px 5px;
  width: 50%;
  border: 1px solid $color-background;
  box-sizing: border-box;
  //@include u.below('xs') {
  //    width: 100%;
  //}
}

.t_o_state0 {
  color: #00ff00;
}

.t_o_state1 {
  color: #ff9900;
}

.t_o_state2 {
  color: #000000;
}

.t_o_state3 {
  color: #ff0000;
}

.t_o_state4 {
  color: #1e6ced;
}

.t_o_state5 {
  color: #9d9d9d;
}

.t_o_state6 {
  color: #ff0000;
}

.t_o_state7 {
  color: #8040ff;
}

.t_o_state8 {
  color: #ff00ff;
}

.lot-old-item-row-change {
  display: flex;
  margin: u.size('unit') 0 0;
  @include u.below('xs') {
    flex-direction: column;
    select {
      width: 100%;
    }
  }
}
