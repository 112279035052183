@import "@Scss/old/common/_variables.scss";
@keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loading {
	0% {
		border: 0 solid #ffffff;
	}

	20% {
		border: 8px solid #ffffff;
		width: 0;
		height: 0;
	}

	100% {
		border: 8px solid #ffffff;
		width: 100%;
		height: 100%;
	}
}

@keyframes loading-horizontal {
	0% {
		left: 0;
	}
	75% {
		left: 100%;
	}
	100% {
		left: 100%;
	}
}
@keyframes stub-bg {
  50% {
    opacity: 0.5;
  }
}
