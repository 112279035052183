@mixin organizer-round-avatar {
	.organizer {
			&-nickname {
					font-weight: 400;
			}
		&-avatar {
			width: 50px;
			height: 50px;
			margin-left: 1.25rem;
			border-radius: 50%;
			float: left;
			background-size: cover;

			&_wrapper {
				float: left;
				padding-left: 0.3125rem;
			}

			&_and_name-wrapper {
				display: inline-block;
				margin-left: 0.1875rem;
				margin-top: 0.625rem;
				width: 100%;
				max-width: 22rem;
				float: left;
			}

			&_link {
				div {
					transition: opacity 0.2s;
				}

				&:hover div {
					opacity: 0.5;
				}
			}
		}

		&_name_link {
			color: $optwear-blue-strong;
			transition: opacity 0.2s;
			word-wrap: break-word;

			&:hover {
				color: $optwear-blue-strong;
				opacity: 0.5;
			}
		}

		&-rank {
			margin-bottom: 0;
		}
	}
}
