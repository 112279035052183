@use "src/assets/scss/utils" as u;

.ForumList-Btn,
.ForumList-Btn:link {
  padding: 5px;
  display: inline-block;
  color: $optwear-white;
  margin: 2px 0;
  text-decoration: none;
  font-size: 16px;
  position: relative;
}

.ForumList-Btn-subscribe {
  .count {
    position: relative;
    padding: 0 0 0 u.size('unit-half');
    display: inline-block;
    border-left: 2px solid;
  }
}

.ForumList-Btn-additional {
  color: $optwear-blue-strong !important;
  transition: color 0.25s;

  &:hover, &:focus {
    color: $optwear-blue-light !important;
  }
}
