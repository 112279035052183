@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";


.calendar {
  flex-grow: 2;
  padding-left: 20px;
  @include u.below('md') {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  > .ForumListNav:first-of-type {
    margin: 0 0 10px;
  }

  &-container {
    display: flex;
    @include u.below('md') {
      flex-direction: column;
    }
  }

  &-header__labels {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    margin: 20px 0;
  }

  &__view {
    max-width: 930px;
  }

  td.days {
    background-color: #f6f6db;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #8f8f8f;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #8f8f8f;
    cursor: pointer;
  }

  td.notaday {
    cursor: pointer;
  }

  td.today {
    border-left: 0.2em solid #1c89ef;
    border-right: 0.2em solid #115799;
    border-top: 0.2em solid #1c89ef;
    border-bottom: 0.2em solid #115799;
  }

  td.isev {
    font-weight: bold;
  }

  td.isever {
    font-weight: bold;
    color: #d31141;
  }

  td.days_is {
    background-color: #e5f5d3;
  }

  td.select_day {
    background: #f9f9f9;
  }

  .cp {
    background-color: #f9f9f9;
    padding: 0 10px;
  }

  #navigation {
    padding-top: 0;
  }

  #wrap {
    min-width: 1000px;
  }

  table {
    font-size: 15px !important;
  }

  &__remove-user {
    @include button-reset;
    font-size: 22px;
    font-weight: bold;
    color: #ff0000;

    &:focus, &:active, &:hover {
      color: $color-text;
      outline: none;
    }
  }

  &__username-list {
    display: block;
    margin: 1em 0;
  }

  &__flags {
    td:nth-child(2) {
      display: flex;
    }

    input {
      display: none;

      & + img {
        background-color: #edf0f5;
        padding: 5px;
      }

      & + .no-flag {
        background-color: transparent;
        border: 1px dashed #1d809b;
      }

      &:checked + img,
      &:checked + .no-flag {
        background-color: $optwear-blue-strong;
        padding: 5px;
      }

    }

    .no-flag {
      display: inline-block;
      width: 37px;
      height: 37px;
      box-sizing: border-box;
    }
  }

  &__btn-new-event {
    width: 202px;
    @include u.below('md') {
      width: 100%;
      @include u.mixins-text-height(u.size('unit-5x'));
      display: flex;
      align-items: center;
      justify-content: center;
      margin: u.size('unit-2x') 0 0;
    }
  }
}

.calendar-show-btn {
  width: 100%;
  @include u.mixins-text-height(u.size('unit-5x'));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

/* Обработчик запрещённых символов */

.event-prevented i {
  text-decoration: underline;
  cursor: pointer;
}

div.event-prevented {
  background: $optwear-white;
  border-top: 1px dashed $color-background;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-x: scroll;
}


@import "calendar-mini";
@import "calendar-navigation";
@import "calendar-tape";
@import "calendar-messages";
@import "calendar-tabs";


.sp-event-calendar-page, .sp-comment-calendar-page {

  .ForumListNav-link.ForumListNav-pages {
    @include u.between('md', 'lg') {
      display: none;
    }
    @include u.below('xs') {
      display: none;
    }
  }

  .ForumListNav-link[rel="last"] {
    @include u.between('md', 'lg') {
      display: none;
    }
    @include u.below('xs') {
      display: none;
    }
  }

  .tabbed {
    margin: 0 0 u.size('unit');
    @include u.below('md') {
      margin: u.size('unit-2x') 0;
    }
  }

  .ForumListNav {
    margin: 0 !important;
    @include u.below('xs') {
      width: 100%;
      display: flex;
      .ForumListNav-curPage, .ForumListNav-link {
        flex: 1;
        text-align: center;
      }
    }

  }
}
