@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";
@import "@Scss/old/common/_mixins.scss";

.brands {
  &-title {
    font-size: u.size('text-xxl');
    @include u.below('sm') {
      font-size: u.size('text-xl');
    }
  }

  @include b(form) {
    width: 100%;
    display: flex;
    margin: 0 0 15px;
    @include u.below('xs') {
      flex-direction: column;
    }
    @include e(search-block) {
      flex: 1;
      display: flex;
      position: relative;
      @include u.below('xs') {
        margin: 0 0 u.size('unit');
      }
    }
    @include e(search) {
      border-radius: 5px;
      background: #fff;
      outline: 0;
      display: block;
      width: 100%;
      padding: 0 30px 0 10px;
      line-height: 43px;
      font-size: 13px;
      border: 1px solid #cbcbcb;
      font-family: 'Roboto', Arial, Sans-serif;
      &:not(:placeholder-shown) {
        opacity: 1;
        pointer-events: auto;
      }

      &:placeholder-shown {
        & + .brands-form__reset {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
    @include e(submit) {
      margin: 0 0 0 10px;
      color: #fff;
      padding: 0 20px;
      outline: 0;
      border: 0;
      border-radius: 3px;
      background-image: none;
      height: 43px;
      font-weight: 500;
      @include u.below('xs') {
        margin: 0;
      }
      font-family: 'Roboto', Arial, Sans-serif;
      font-size: 15px;
      letter-spacing: .2px;
      box-shadow: none;
      text-shadow: none;
      background-color: #ea6b41;
      transition: .2s;
      cursor: pointer;
      line-height: 3em;
      &:hover {
        background-color: #e55734;
      }
    }
    @include e(reset) {
      transition: .2s;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: 0;
      font-size: 20px;
      cursor: pointer;
      outline: none;
    }
  }
  @include e(count) {
    display: flex;
    margin: 0 0 u.size('unit-2x');
    @include u.below('sm') {
      margin: 0 0 u.size('unit');
    }
  }
  @include b(list) {
    overflow: hidden;
    list-style: none;
    width: 100%;
    padding: 0;
    position: relative;
    margin: 0 0 60px;
    @include b(block) {
      width: 100%;
      display: flex;
      flex-direction: column;
      opacity: 1;
      transition: .5s;
      @include m(hidden) {
        opacity: 0;
        position: absolute;
        top: -100%;
        pointer-events: none;
      }
      @include e(char) {
        width: 100%;
        position: relative;
        font-size: 24px;
        @include u.below('sm') {
          font-size: u.size('text-lg');
        }
        @include u.below('xs') {
          font-size: u.size('text-md');
        }
        font-weight: 700;
        margin: 0 0 15px;
        padding: 0 0 15px;
        text-transform: uppercase;
        &:after {
          content: '';
          height: 1px;
          background: #e55734;
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }
    }
    @include b(sublist) {
      list-style: none;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: u.size('unit-2x') u.size('unit-2x');
      @include u.below('md') {
        grid-template-columns: repeat(6, 1fr);
      }
      @include u.below('xs') {
        grid-template-columns: repeat(4, 1fr);
      }


      margin: 0 0 30px;
      @include b(item) {
        box-sizing: border-box;
        //width: 20%;


        border-radius: u.size('unit');
        border: 1px solid u.var-color('grey-50');

        //padding: 24px;
        //min-height: 100px;
        opacity: 1;
        transition: .5s;
        @include m(hidden) {
          opacity: 0;
          position: absolute;
          top: -100%;
          pointer-events: none;
        }
        @include e(img) {
          position: absolute;
          top: 0;
          left: 0;
          object-fit: contain;
          font-family: "object-fit: contain;";
          width: 100%;
          height: 100%;
        }
        @include e(link) {
          width: 100%;
          &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
          }
          text-align: center;
          text-decoration: none !important;
          font-size: u.size('text-sm');
          @include u.below('sm') {
            font-size: u.size('text-xs');
          }
          color: #3f3f3f !important;
          position: relative;
          transition: .2s;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            color: #e55734;
          }
        }
      }
    }
  }
}


.abc-sort {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;
  white-space: nowrap;
  @include e(block) {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    &:after {
      content: '';
      width: u.size('unit-4x');
      height: 100%;
      background: linear-gradient(90deg, rgba(u.get-color-value('body-background'), 0) 0%, u.var-color('body-background') 100%);
      top: 0;
      right: 0;
      display: none;
      position: absolute;
      @include u.below('sm') {
        display: block;
      }
    }
    //&:not(:last-child) {
    //	margin: 0 0 15px;
    //}
  }
  @include e(title) {
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px 0 0;
  }
  @include b(list) {
    width: 100%;
    display: flex;
    list-style: none;
    margin: 0;
    padding: u.size('unit-2x') 0;
    @include u.below('sm') {
      overflow-x: auto;
    }
    @include b(item) {
      display: flex;
      align-items: center;
      &:last-child {
        @include u.below('sm') {
          padding: 0 u.size('unit-4x') 0 0;
        }
      }

      @include e(link) {
        text-decoration: none !important;
        font-size: 16px;
        color: #666 !important;
        text-transform: uppercase;
        transition: .2s;
        &:hover {
          color: #e55734;
        }
      }
      &:not(:last-child) {
        &:after {
          content: '|';
          padding: 0 0 0 5px;
          margin: 0 5px 0 0;
        }
      }
    }
  }
}
