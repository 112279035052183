@use "src/assets/scss/utils" as u;

.drop-target-image {
  cursor: pointer;

  &__insert {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    color: $optwear-white;
    //opacity: 0;
    transition: 0.5s opacity;
    user-select: none;
  }
}

.TopicQuickReply-images {
  //display: flex;
  //flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 8px;
  @include u.below('lg') {
    grid-template-columns: repeat(6, 1fr);
  }
  @include u.below('sm') {
    grid-template-columns: repeat(4, 1fr);
  }
  @include u.below('xs') {
    grid-template-columns: repeat(2, 1fr);
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    //margin-right: 20px;
    //max-width: 300px;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .dz-progress {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 11px;
      padding: 5px;
      box-sizing: border-box;
    }

    .sp-topic-progress-line__background {
      display: inline-block;
      transition: width 1s;
      order: 1;
      width: 100%;
      margin: 5px 0 0;
    }

    .sp-topic-progress-line__fill {
      width: 0;
    }

    .drop-target-image {
      position: absolute;
      left: 0;
      top: 0;
      //width: 100px;
      //	height: 100px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      //padding: 4px;
      box-sizing: border-box;
      border: 1px solid grey;

      &__img {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
      }
    }
  }

  .image-edit-actions {
    position: absolute;
    right: 0;
    top: 0;
    color: $optwear-white;
    background: rgba(0, 0, 0, 0.4);
    font-size: 13px;
    line-height: 0;
    cursor: default;
  }

  .image-edit-actions__delete {
    cursor: pointer;
    padding: 2px;
    opacity: 1;
    height: 16px;
    width: 16px;
    line-height: 1.2;
    text-align: center;
  }

  .image-edit-actions__delete:active {
    color: #fea219;
  }
}

.OptwearDropzonePreview--error {
  .drop-target-image__insert {
    display: none;
  }
}
