@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.user-page {
  width: 100%;
  display: grid;
  grid-template: "header" auto
		"sig" auto
		"purchases" auto / 1fr;
  grid-auto-rows: 0;
  grid-gap: u.size('unit-5x') u.size('unit-5x');

  &__block {
    border: 1px solid $color-background;
    border-radius: $border-radius-main;
    background: $optwear-white;
    font-size: 15px;
    padding: 15px;
    box-sizing: border-box;
    @include u.below('md') {
      padding: 8px;
    }
  }

  &__sig {
    flex-grow: 2;
    grid-area: sig;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    img {
      max-width: 100%;
    }
  }

  &__pristroy-show-more {
    text-align: center;
    font-size: 16px;
    display: block;
    width: 100%;
    border-top: 1px solid $color-background;
    line-height: 3em;

    &:hover, &:focus {
      text-decoration: none;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &__header {
    //display: flex;
    //justify-content: space-between;
    //flex-wrap: wrap;
    &-form {
      grid-area: header;
    }

    width: 100%;
    display: grid;
    grid-gap: u.size('unit-5x') u.size('unit-5x');
    grid-template: "profile summary" auto "admin_actions admin_actions" auto / 40% 60%;
    @include u.below('lg') {
      grid-template: "profile summary" auto "admin_actions admin_actions" auto / 50% 50%;
    }
    @include u.below('md') {
      grid-template: "profile" auto "summary" auto "admin_actions" auto / 1fr;
    }

    &-username {
      grid-area: username;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 u.size('unit-3x');
      overflow: hidden;
      @include u.below('md') {
        justify-content: space-between;
        margin: 0;
      }

      &-rank {
        font-weight: 500;
        font-size: u.size('text-md');
        @include u.below('md') {
          font-size: u.size('text-xs');
          border-radius: 5px;
          background-color: $optwear-blue-light;
          color: #fff;
          padding: 4px;
          box-sizing: border-box;
        }
      }

      &-block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0 0 u.size('unit');
        margin: 0 0 u.size('unit');
        border-bottom: 1px solid grey;
        overflow: hidden;
        @include u.below('md') {
          border-bottom: none;
          padding: 0;
          margin: 0;
        }

      }

      h3 {
        margin: 0;
      }
    }
  }

  &-profile {
    grid-area: profile;
    display: grid;
    grid-template: "avatar" auto "username" auto "user_actions" auto / 1fr;
    @include u.below('md') {
      grid-template: "avatar username" minmax(0, auto) "user_actions user_actions" auto / u.size('unit-6x') 1fr;
      grid-gap: u.size('unit-3x') u.size('unit-2x');
    }
  }

  &__avatar {
    grid-area: avatar;
    box-sizing: border-box;
    border-width: 1px 1px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 u.size('unit-5x');
    @include u.below('md') {
      margin: 0;
    }

    &-img {
      display: flex;
      margin: 0 auto;
      position: relative;
      @include u.square(200px);
      @include u.below('md') {
        border-radius: 100%;
        overflow: hidden;
        border: 2px solid u.var-color('grey-50');
        width: 100%;
        height: u.size('unit-6x');
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
        font-family: "object-fit: contain;";
        width: 100%;
        height: 100%;
      }
    }
  }

  &__user-actions {
    border-width: 0 1px 1px;
    grid-area: user_actions;
    line-height: 1.8;
    @include u.below('md') {
      margin: 0 -8px -8px;
      border-radius: 0 0 4px 4px;
      overflow: hidden;
    }

    &-list {
      @include u.below('md') {
        width: 100%;
        display: flex;
      }
    }

    &-item {
      @include u.below('md') {
        background-color: #a7abaf;
        flex: 1;
        &:not(:last-child) {
          border-right: 1px solid #d9d9d9;
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      @include u.below('md') {
        width: 100%;
        @include u.mixins-text-height(u.size('unit-5x'));
        justify-content: center;
      }
    }

    &-icon {
      display: flex;
      color: #333;
      margin: 0 u.size('unit') 0 0;
      @include u.below('md') {
        color: #fff;
        margin: 0;
      }
    }
  }

  &__actions_admin {
    grid-area: admin_actions;
    display: flex;
    flex-direction: column;
    border: 1px solid $optwear-orange-middle;
    width: 100%;
    //margin-top: 40px;

  }

  &__actions-list_admin {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    @include u.below('md') {
      max-height: 240px;
      padding: 0;
    }
    @include u.below('xs') {
      max-height: none;
      justify-content: flex-start;
    }
    max-height: 160px;

    li {
      padding: 5px 2px;
    }
  }

  &__username {
    font-size: 22px;

    font-weight: bold;
    position: relative;
    padding: 0 15px 0 0;
    overflow: hidden;


    //display: flex;
    //align-items: baseline;
    @include u.below('md') {
      font-size: 14px;
    }

    &:after {
      //top: 50%;
      //	transform: translate(0, -50%);
      //	right: 0;
    }
  }

  &__last-visit {
    float: right;
    font-size: u.size('text-xs');
    white-space: nowrap;
  }

  &__summary {
    //margin-left: 40px;
    flex-grow: 2;
    //max-width: 530px;
    grid-area: summary;
    @include u.below('md') {
      border: 1px solid $color-background;
      border-radius: $border-radius-main;
      background: $optwear-white;
      padding: 8px;
      box-sizing: border-box;
    }

    h1 {
      text-align: right;
    }

    tr td:first-child {
      text-align: left;
    }

    font-size: 14px;

    &-title {
      font-size: u.size('text-lg');
      color: u.var-color('text-lt');
      margin: 0 0 u.size('unit-2x');
    }
  }

  &__actions {
    //padding-top: 34px;
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      box-sizing: border-box;
      padding: u.size('unit');
      @include u.below('md') {
        padding: 0 u.size('unit-half');
      }
    }
  }

  &__list-purchases-title {
    margin: 0 0 u.size('unit-4x');
    //margin-top: 40px;
  }

  &__list-purchases {
    grid-area: purchases;

    .list-purchases {
      .list-purchases__header {
        display: none;
      }

      tbody {
        @include u.below('md') {
          display: flex;
          flex-direction: column;
          tr {
            word-break: break-all;
            display: grid;
            grid-template: "title" auto "status" auto "catalogs" auto / 1fr;
            margin: 0 0 u.size('unit-2x');
            border: 1px solid u.var-color('grey-25');
            padding: 8px 8px 0;
            width: 100%;
            box-sizing: border-box;

            td {
              width: 100% !important;
              background-color: #fff;
              border: none;
              padding: 0;
              box-sizing: border-box;
            }

            .list-purchases-body-category {
              display: none;
            }

            .list-purchases-body-title {
              grid-area: title;
              font-size: u.size('text-lg');
            }

            .list-purchases-body-catalogs {
              grid-area: catalogs;

              .catalogs-list__item {
                min-height: 45px;
                display: flex;
                align-items: center;
                padding: u.size('unit') 0;
                box-sizing: border-box;

                &:not(:last-of-type) {
                  border-bottom: 1px solid u.var-color('grey-25');
                }
              }

              .catalogs-list__item-collapser {
                justify-content: center;
                color: #004d97;

                &:before {
                  display: none;
                }

                .catalogs-list__item-collapser-text {
                  background-color: transparent;
                }
              }
            }

            .list-purchases-body-status {
              grid-area: status;
              font-size: u.size('text-sm');
              padding: u.size('unit') 0 0;
              margin: 0 0 u.size('unit-3x');
            }
          }
        }
      }
    }
  }

  &__info {
    padding: 0 0 u.size('unit');
    margin: 0 0 u.size('unit');
    border-bottom: 1px solid grey;
    line-height: 1.8;
    @include u.below('md') {
      padding: 0;
      margin: 0;
      border-bottom: none;
    }
  }
}
