@use "src/assets/scss/utils" as u;
//.AnnouncementBlock{
//	display: flex;
//	margin: 0 auto;
//	max-width: 1200px;
//}

@import "AnnouncementBlock-Carousel";
@import "AnnouncementBlock-List";
@import "AnnouncementBlock-Page";

.index-announcement-block {
  width: 100%;
  display: flex;
}

.AnnouncementBlock-index {
  display: flex;
  @include u.below('md') {
    flex-direction: column;
  }
}

.AnnouncementBlock {

  padding: u.size('unit-2x');
  @include u.below('md') {
    padding: u.size('unit');
  }

  &-nav {
    flex: 1;
    @include u.below('md') {
      width: 100%;

    }
  }

  &-Page {
    width: 800px;
    max-width: none;
    margin-left: 0;
    @include u.below('lg') {
      width: 690px;
    }
    @include u.below('md') {
      width: 100%;
      font-size: u.size('text-xl');
    }
    @include u.below('xs') {
      font-size: u.size('text-lg');
    }

    > a {
      width: 100%;

      > img {
        width: 100%;
      }
    }

    > img {
      @include u.below('sm') {
        width: 50%;
      }
    }
  }
}
