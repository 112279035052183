.ucp-yandex-header {
	z-index: 0;
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	#yandex-header {
		max-width: 730px;
		margin: 20px 0;
	}
}
