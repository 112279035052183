@use "src/assets/scss/utils" as u;
@use 'sass:color';

.cart-user-settings {
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  line-height: 17px;
  font-size: u.size('text-sm') !important;
  height: auto !important;
  padding: 0 0 u.size('unit-2x') 0;

  .cart-user-settings__textInput {
    padding: 0 !important;
    display: flex;
    align-items: center;
    @include u.mixins-text-height(u.size('unit-5x'));

    input {
      margin: 0 0 0 u.size('unit');
      font-size: u.size('text-sm') !important;
      @include u.mixins-text-height(u.size('unit-3x'));
      padding: 0 u.size('unit-half');
    }
  }

  .button_sp {
    box-sizing: border-box;
  }
}

.cart-user-settings__textInput {
  min-width: 30%;
  padding: 7px 0;
}

.cart-user-settings__textInput input {
  width: 300px;
  height: 17px;
  padding: 5px 9px 8px;
  border-radius: 2px;
}

.cart-user-settings__textInput div.sp_edit {
  padding-top: 16px;
}

.cart-user-settings__textInput--phone-number {

}

.cart-user-settings__textInput #user_phone {
  width: 200px;
}

.cart-user-settings__textInput--userPhone {
  margin: 0 0;
}

.cart-user-settings__confirmPhone input {
  width: 200px;
}

.cart-user-settings__selector {
  text-align: right;
  width: 50%;
  padding: 0 0 0 u.size('unit-2x');
  box-sizing: border-box;
  @include u.below('sm') {
    order: -1;
    width: 100%;
    text-align: left !important;
    padding: 0;
    margin: 0 0 u.size('unit');
  }

  .cart-user-settings__selector-row {
    @include u.mixins-text-height(u.size('unit-5x'));
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include u.below('sm') {
      justify-content: flex-start;
    }
  }

  .cart-user-settings__selector-card {
    margin: 0;

    svg {
      margin: 0 u.size('unit') 0 0;
    }
  }

  .cart-user-settings__selector-select {
    width: 100%;
    @include u.below('sm') {
      width: 50%;
    }
    @include u.below('xs') {
      width: 100%;
    }
  }

}

.cart-user-settings__textInput--userPhone input.cart-user-settings__textInput--smsCode {
  width: 100px;
}

.cart-user-settings__selector select {
  width: 545px;
  border-radius: 2px;
}

.cart-user-settings__input--notValidated {
  background-color: rgba(208, 43, 7, 0.71);
}

.cart-user-settings__input--Validated {
  background-color: rgba(193, 252, 192, 0.71);
}

.cart-user-settings__amountSpOnPage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  font-size: 13px;
}

.cart-user-settings-show {
  width: 100%;
  margin: 0 0 u.size('unit');
  box-sizing: border-box;
  font-size: u.size('text-sm');
  text-align: center;
  @include u.below('xs') {
    font-size: u.size('text-xs');
  }
}

.cart-user-settings__confirmPhone {
  margin: u.size('unit') 0 0;
}

.cart-user-settings__userInfo {
  flex-flow: column wrap;
  width: 100%;
  font-size: u.size('text-sm') !important;
  padding: 0 !important;
  justify-content: flex-start;
  min-height: u.size('unit-5x') * 3 !important;
  max-height: 200px;
  height: auto !important;
  display: none !important;
  @include u.below('sm') {
    flex-wrap: wrap;
    max-height: none;
  }

  &.show {
    display: flex !important;
  }

  span {
    padding-bottom: 0 !important;
  }

  &-container {
    width: 50%;

    > span {
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      @include u.below('sm') {
        width: 100%;
      }
      box-sizing: border-box;
      line-height: u.size('unit-5x');
      min-height: u.size('unit-5x');
    }
  }

  .status-phone {
    width: 100%;
    margin: u.size('unit') 0 0;
    padding-left: 0;

    .confirm-phone {
      margin: 0 !important;
    }

    @include u.below('sm') {
      display: flex;
      flex-direction: column;
      .phone_confirmed {
        margin: u.size('unit-half') 0 0;
      }
    }
  }

  .edit_info {
    @include u.mixins-text-height(u.size('unit-3x'));
    display: flex;
    align-items: center;
    margin: 0 0 0 u.size('unit');
    box-sizing: border-box;

  }


  .upd_user {
    margin: 0 u.size('unit-half') 0 u.size('unit');
    @include u.mixins-text-height(u.size('unit-3x'));
    padding: 0 u.size('unit-half');
  }

  .cart-user-settings__userInfo-id {
    margin: 0 0 0 u.size('unit');
    font-size: u.size('text-md');
  }

  .button_sp {
    box-sizing: border-box;
  }


}

.cart-user-settings__userInfo span {
  /* margin-bottom: 7px; */
  margin: inherit;
  padding-bottom: 3px;
}

.cart-user-settings__textInput label[for="name"] {
  padding-left: 29px;
}

input.user_phone::after {
  display: inline-block;
  width: 25px;
  height: 40px;
  margin: -3px 10px 3px;
  border: solid red;
  border-width: 0 15px 15px 0;
  transform: rotate(45deg);
}

.cart-body {
  margin: u.size('unit-2x') 0 0;
  padding: 0 !important;
  border: none !important;
  font-family: Roboto, Arial, sans-serif !important;
  font-size: u.size('text-xs') !important;
}

.ui-widget button, .ui-widget input, .ui-widget select, .ui-widget textarea {
  font-family: Roboto, Arial, sans-serif !important;
}

table.sp tbody td {
  padding: 0 !important;
  font-size: 100% !important;

  &.sp-td-name {
    font-weight: 700;
    @include u.below('lg') {
      font-size: u.size('text-sm') !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    @include u.below('xs') {
      align-self: center;
    }
  }

  &.sp-td-cost, &.sp-td-cost2, &.sp-td-percent, &.sp-td-delivery, &.sp-td-count, &.sp-td-amount {
    @include u.below('lg') {
      font-size: u.size('text-sm') !important;
    }
  }

}

table.sp tbody {
  tr {
    &.sp-tr-amount {
      font-weight: 700;
      @include u.below('lg') {
        display: flex;
        padding: 0 u.size('unit');
        flex-wrap: wrap;
        font-size: u.size('text-sm') !important;
      }

      @include u.below('sm') {
        justify-content: center;
      }

      > td {
        padding: u.size('unit') !important;
        box-sizing: border-box;

        &:first-of-type {
          @include u.below('lg') {
            width: calc(50% + #{u.size('unit')});
            text-align: right;
            padding: u.size('unit') 0 u.size('unit') u.size('unit') !important;
          }
          @include u.below('sm') {
            width: 100%;
            text-align: center;
          }
        }

        @include u.below('lg') {
          display: block;
        }

        &.sp-tr-amount-item {
          flex: 1;

          @include u.below('sm') {
            flex: auto;
            width: 20%;
            font-weight: 400;
          }

          &.to_price, &.total_price {
            @include u.below('sm') {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            &:before {
              content: 'Цена';
              display: none;
              margin: 0 0 u.size('unit');
              @include u.below('sm') {
                display: flex;
              }
            }
          }

          &.to_org_fee, &.total_org_fee {
            @include u.below('sm') {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            &:before {
              content: 'Орг %';
              display: none;
              margin: 0 0 u.size('unit');
              @include u.below('sm') {
                display: flex;
              }
            }
          }

          &.to_delivery, &.total_delivery {
            @include u.below('sm') {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            &:before {
              content: 'Доставка';
              display: none;
              margin: 0 0 u.size('unit');
              @include u.below('sm') {
                display: flex;
              }
            }
          }

          &.to_kol, &.total_kol {
            @include u.below('sm') {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            &:before {
              content: 'Кол-во';
              display: none;
              margin: 0 0 u.size('unit');
              @include u.below('sm') {
                display: flex;
              }
            }
          }

          &.to_sum, &.total_summ {
            @include u.below('sm') {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            &:before {
              content: 'Сумма';
              display: none;
              margin: 0 0 u.size('unit');
              @include u.below('sm') {
                display: flex;
              }
            }
          }

        }

        &.discount, &.total_sum {

          @include u.below('lg') {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            //width: 100%;
            text-align: right;
          }

          //@include u.below('sm') {
          //    width: 50%;
          //		align-items: center;
          //}

          .sp-tr-amount-group {
            display: inline-block;

            &-label {
              white-space: nowrap;
              margin: 0 u.size('unit-half') 0 0;
            }

            //@include u.below('lg') {
            //		display: flex;
            //		justify-content: flex-end;
            //		width: 50%;
            //}
          }
        }

        &.discount {
          @include u.below('lg') {
            margin: u.size('unit') 0 0;
            border-top: 1px solid u.var-color('grey-50');
            padding: u.size('unit-2x') u.size('unit') u.size('unit') !important;
            min-width: 50%;
            flex: 1;
          }
          @include u.below('xs') {
            width: 50%;
            flex: auto;
            align-items: center;
          }
          //.sp-tr-amount-group {
          //		&:first-of-type {
          //				@include u.below('lg') {
          //						padding: 8px 0 0;
          //				    border-top: 1px solid u.var-color('grey-50');
          //				}
          //		}
          //}
        }

        &.total_sum {
          @include u.below('lg') {
            margin: u.size('unit') 0 0;
            border-top: 1px solid u.var-color('grey-50');
            padding: u.size('unit-2x') u.size('unit') u.size('unit') !important;
          }
          @include u.below('xs') {
            width: 50%;
            flex: auto;
            align-items: center;
          }
        }

        //&:not(.total_sum) {
        //		@include u.below('sm') {
        //				display: none !important;
        //		}
        //}
      }
    }

    &.sp-tr-cr-switcher {

      @include u.below('lg') {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .spprdesc.vat {
        @include u.below('md') {
          width: 100% !important;
          box-sizing: border-box;
        }
      }

      .ui-accordion-content {
        padding: u.size('unit');
        color: u.var-color('text') !important;

        @include u.below('sm') {
          tr {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }

        .top {
          .spprlist {
            margin: 0;

          }

          ul.spprlist {
            @include u.below('md') {
              height: 200px;
              overflow: hidden;
              overflow-y: auto;
            }

            li {
              margin: 0 0 u.size('unit');

              &:last-of-type {
                margin: 0;
              }

              padding: u.size('unit-half');
              box-sizing: border-box;
              line-height: u.size('unit-4x');
              min-height: u.size('unit-4x');
            }
          }
        }
      }

      table {
        border-collapse: collapse;

        td {
          padding: u.size('unit') !important;
          background-color: color.scale($optwear-orange, $lightness: 75%) !important;
        }
      }
    }

    &.sp-tr-cart-props-info {
      @include u.below('lg') {
        display: flex;
        width: 100%;
      }

      > td {
        @include u.below('lg') {
          width: 100%;
        }
      }
    }
  }
}

#purchases2 {
  margin: u.size('unit-2x') 0;

  [lot] {
    box-sizing: border-box;
    @include u.below('lg') {
      display: grid;
      grid-template: 'image name cost cost2' minmax(0, auto) 'image id cost cost2' minmax(0, auto) 'image date cost cost2' minmax(0, auto) 'image params cost cost2' minmax(0, auto) 'actions info info info' / 96px calc(50% - 104px) 1fr 1fr;
      padding: u.size('unit');
      grid-gap: 0 u.size('unit');
      justify-items: center;
      td {
        text-align: left;
        padding: 0 !important;
        @include u.below('xs') {
          &.sp-td-cost, &.sp-td-percent, &.sp-td-delivery, &.sp-td-count, &.sp-td-amount {
            padding: u.size('unit-3x') 0 !important;
          }
        }
      }
    }
    @include u.below('sm') {
      grid-template: 'image name name' minmax(0, auto) 'image id id' minmax(0, auto) 'image date date' minmax(0, auto) 'image params params' minmax(0, auto) '. cost cost2' minmax(0, auto) 'actions info info' / 96px 1fr 1fr;
    }
    @include u.below('xs') {
      grid-template: 'image name name name' minmax(0, auto) 'image id id id' minmax(0, auto) 'image date date date' minmax(0, auto) 'params params params params' minmax(0, auto) 'cost cost cost2 cost2' minmax(0, auto) 'actions info info info' minmax(0, auto) / 1fr 1fr 1fr 1fr;
    }
  }

  .highslide-gallery {

    @include u.square(u.size('unit-6x') * 2);
    cursor: pointer;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    &.empty {
      background-size: 100% 100%;
    }

    @include u.below('xs') {
      width: 100%;
      height: auto;
      &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  .spcart {
    border-style: solid;
    border-color: u.var-color('grey-50');
    border-width: 1px 1px 0 1px;
    //
    //td {
    //		padding: u.size('unit') !important;
    //		box-sizing: border-box;
    //}

    &-td {
      padding: u.size('unit') !important;
      box-sizing: border-box;

      &-name {
        width: auto;
        font-size: u.size('text-sm') !important;

        @include u.below('md') {
          grid-area: name;
          width: 100%;
        }
      }

      &-info {
        max-width: 300px;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include u.below('lg') {
          max-width: 250px;
          width: 250px;
        }
        @include u.below('md') {
          grid-area: info;
          width: 100%;
        }
      }

      &-status {
        width: 200px;
        font-size: u.size('text-sm') !important;
        @include u.below('md') {
          grid-area: status;
          width: 100%;
          align-self: flex-end;
        }
        @include u.below('xs') {
          align-self: center;
        }
      }

      &-actions {
        width: 200px;
        text-align: right;
        @include u.below('lg') {
          width: 150px;
        }
        @include u.below('md') {
          grid-area: actions;
          display: flex;
          text-align: left;
          justify-content: flex-end;
          > .button_sp {
            &:not(:last-child) {
              margin: 0 u.size('unit') 0 0;
            }
          }
        }
      }
    }


    tr {
      @include u.below('md') {
        display: grid;
        width: 100%;
        grid-template: 'actions actions' auto 'name name' auto 'info status' auto / minmax(0, auto) 1fr;
      }
      @include u.below('xs') {
        grid-template: 'actions' auto 'name' auto 'info' auto 'status' auto / 1fr;
      }
    }

  }

  [lot] {
    border-bottom: 1px solid u.var-color('grey-50');

    td {
      padding: u.size('unit') !important;
      box-sizing: border-box;

      &.sp-td-image {
        padding: 0 !important;
      }
    }
  }

  &:last-child {
    .spcart {
      border-width: 1px;
    }
  }
}

table.sp.sp2 {
  border-style: solid;
  border-color: u.var-color('grey-50');
  border-width: 0 1px 1px 1px;
  @include u.below('lg') {
    display: flex;
    flex-direction: column;
  }

  .sp-td-cost {
    &:before {
      content: 'Цена+Орг%';
    }
  }

  > thead {
    @include u.below('lg') {
      display: flex;

    }

    tr {
      @include u.below('lg') {
        width: 100%;
        padding: 0 u.size('unit');
        display: flex;
      }

      th {
        box-sizing: border-box;
        @include u.below('lg') {
          padding: 0 !important;
          @include u.mixins-text-height(u.size('unit-4x'));
        }
      }
    }
  }

  > tbody {
    @include u.below('lg') {
      display: flex;
      flex-direction: column;
    }
  }


  thead {
    background-color: $optwear-orange-middle;

    > tr {
      padding: 0 u.size('unit');
    }

    th {
      padding: 16px 8px !important;
      text-align: center;
      box-sizing: border-box;
      @include u.below('sm') {
        @include u.mixins-text-height(u.size('unit-4x'));
        padding: 0 !important;
      }
    }


  }

  tbody {
    @include u.below('sm') {
      display: flex;
      flex-direction: column;
    }

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid u.var-color('grey-50') !important;
      }

      @include u.below('sm') {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    td {
      text-align: center;
      padding: u.size('unit') !important;
      box-sizing: border-box;
      @include u.below('sm') {
        display: flex;
        width: 100%;
        align-items: center;
      }
    }
  }
}

table.sp.sp4 {
  margin: u.size('unit-2x') 0;
  border-style: solid;
  border-color: u.var-color('grey-50');
  border-width: 0 1px 1px 1px;
  @include u.below('sm') {
    display: flex;
    flex-direction: column;
  }

  thead {
    background-color: $optwear-orange-middle;

    > tr {
      padding: 0 u.size('unit');
    }

    th {
      padding: 16px 8px !important;
      text-align: center;
      box-sizing: border-box;
      @include u.below('sm') {
        @include u.mixins-text-height(u.size('unit-4x'));
        padding: 0 !important;
      }

      &:first-child {
        width: 45%;
        @include u.below('lg') {
          width: 40%;
        }
        @include u.below('sm') {
          width: 100%;
        }
      }

      &:nth-child(2) {
        width: 20%;

      }

      &:nth-child(3) {
        width: 10%;
        @include u.below('lg') {
          width: 15%;
        }

      }

      &:nth-child(4) {
        width: 25%;
      }
    }


  }

  tbody {
    @include u.below('sm') {
      display: flex;
      flex-direction: column;
    }

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid u.var-color('grey-50') !important;
      }

      @include u.below('sm') {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    td {
      text-align: center;
      padding: u.size('unit') !important;
      box-sizing: border-box;
      @include u.below('sm') {
        display: flex;
        width: 100%;
        align-items: center;
      }

      &:first-child {
        &:before {
          content: 'Название:';
          display: none;
          margin: 0 u.size('unit-half') 0 0;
          @include u.below('sm') {
            display: block;
          }
        }
      }

      &:nth-child(2) {
        &:before {
          content: 'Организатор:';
          display: none;
          margin: 0 u.size('unit-half') 0 0;
          @include u.below('sm') {
            display: block;
          }
        }
      }

      &:nth-child(3) {
        &:before {
          content: 'Дата:';
          display: none;
          margin: 0 u.size('unit-half') 0 0;
          @include u.below('sm') {
            display: block;
          }
        }
      }

      &:nth-child(4) {
        text-align: right;
      }
    }
  }
}


[id^='ptable'] {
  th {
    padding: u.size('unit-2x') u.size('unit') !important;
    box-sizing: border-box;
  }

  .forabg {
    border-radius: 0;
    box-shadow: none;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: u.var-color('grey-50');
    padding: 0 0 u.size('unit');
    margin: 0 0 u.size('unit-2x');
    width: 100%;
    @include u.below('lg') {
      overflow: hidden;
    }

    > .sp {
      @include u.below('lg') {
        display: flex;
        flex-direction: column;
      }

      > thead {
        @include u.below('lg') {
          display: flex;

        }

        tr {
          @include u.below('lg') {
            width: 100%;
            padding: 0 u.size('unit');
            display: flex;
          }

          th {
            box-sizing: border-box;
            @include u.below('lg') {
              padding: 0 !important;
              @include u.mixins-text-height(u.size('unit-4x'));
            }
          }
        }
      }

      > tbody {
        @include u.below('lg') {
          display: flex;
          flex-direction: column;
        }

        [order_id] {
          position: relative;
          @include u.below('lg') {
            display: grid;
            grid-template: 'image name cost percent delivery count amount' minmax(0, auto) 'image catalog cost percent delivery count amount' minmax(0, auto) 'image params cost percent delivery count amount' minmax(0, auto) 'actions status info info info info info' / 96px calc(50% - 104px) 1fr 1fr 1fr 1fr 1fr;
            padding: u.size('unit');
            grid-gap: 0 u.size('unit');
            justify-items: center;
            @include u.below('sm') {
              grid-template: 'image name name name name name' minmax(0, auto) 'image catalog catalog catalog catalog catalog' minmax(0, auto) 'image params params params params params' minmax(0, auto) 'status cost percent delivery count amount' minmax(0, auto) 'actions info info info info info' / 144px 1fr 1fr 1fr 1fr 1fr;
            }
            @include u.below('xs') {
              grid-template: 'image image name name name' minmax(0, auto)
														'image image catalog catalog catalog' minmax(0, auto)
														'image image params params params' minmax(0, auto)
														'image image status status status' minmax(0, auto)
														'cost percent delivery count amount' minmax(0, auto)
														'info info info info info' / 1fr 1fr 1fr 1fr 1fr;
            }
            td {
              text-align: left;
              padding: 0 !important;
              @include u.below('xs') {
                &.sp-td-cost, &.sp-td-percent, &.sp-td-delivery, &.sp-td-count, &.sp-td-amount {
                  padding: u.size('unit') 0 !important;
                }
                &.sp-td-name, &.sp-td-catalog, &.sp-td-params {
                  padding: 0 u.size('unit-4x') 0 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .highslide-gallery {

    @include u.square(u.size('unit-6x') * 2);
    cursor: pointer;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;


    &.empty {
      background-size: 100% 100%;
    }

    @include u.below('sm') {
      @include u.square(u.size('unit-6x') * 3);
    }

    @include u.below('xs') {
      width: 100%;
      height: auto;
      &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  .spcart {
    border-style: solid;
    border-color: u.var-color('grey-50');
    border-width: 1px 1px 0 1px;
    //
    //td {
    //		padding: u.size('unit') !important;
    //		box-sizing: border-box;
    //}

    //tbody {
    //		> tr {
    //				display: flex;
    //				align-items: center;
    //		}
    //}

    &-td {
      padding: u.size('unit') !important;
      box-sizing: border-box;

      &-name {
        flex: 1;
        font-size: u.size('text-sm') !important;

        > a {
          font-weight: 700;
        }

        @include u.below('md') {
          grid-area: name;
          width: 100%;
        }
        @include u.below('sm') {
          align-self: center;
        }
      }

      &-info {
        max-width: 300px;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include u.below('lg') {
          max-width: 250px;
          width: 250px;
        }
        @include u.below('md') {
          grid-area: info;
          width: 100%;
        }
      }

      &-status {
        width: 200px;
        font-size: u.size('text-sm') !important;
        @include u.below('md') {
          grid-area: status;
          width: 100%;
          align-self: flex-end;
        }
        @include u.below('xs') {
          align-self: center;
        }
      }

      &-actions {
        display: flex;
        align-items: center;
        @include u.above('md') {
          width: 220px;
          justify-content: flex-end;
        }
        @include u.below('md') {
          grid-area: actions;
          width: auto;
          padding: 0 0 0 u.size('unit-2x');
        }

        &-show {
          width: u.size('unit-10x');
          margin: 0 0 0 u.size('unit');
          @include u.mixins-text-height(u.size('unit-3x'));
          padding: 0 u.size('unit') !important;
        }

        @include u.below('xs') {
          justify-content: flex-end;
        }

        &-review {
          margin: 0 0 0 u.size('unit');

          .button_sp {
            display: flex;
            align-items: center;
            background: linear-gradient(to bottom, u.var-color('primary-lt'), u.var-color('primary-ltst'));
            border: 1px solid u.color('primary');
            color: #fff;
            @include u.mixins-text-height(u.size('unit-3x'));
          }

          &-icon {
            @include u.square(u.size('unit-half-2x'));
            margin: 0 u.size('unit-half') 0 0;
          }
        }

        //@include u.below('lg') {
        //		width: 150px;
        //}
        //@include u.below('md') {
        //		grid-area: actions;
        //		width: 100%;
        //		display: flex;
        //		text-align: left;
        //		justify-content: flex-end;
        //		> .button_sp {
        //				&:not(:last-child) {
        //						margin: 0 u.size('unit') 0 0;
        //				}
        //		}
        //}
      }
    }

    tr {
      @include u.above('md') {
        display: flex;
        align-items: center;
      }
      @include u.below('md') {
        display: grid;
        width: 100%;
        grid-template: 'name name actions' auto 'info status actions' auto / minmax(0, auto) 1fr auto;
        overflow: hidden;
      }
      @include u.below('xs') {
        grid-template: 'actions' auto 'name' auto 'info' auto 'status' auto / 100%;
      }
    }

  }

  [order_id] {
    border-bottom: 1px solid u.var-color('grey-50');

    td {
      padding: u.size('unit') !important;
      box-sizing: border-box;

      &.sp-td-image {
        padding: 0 !important;
      }
    }
  }

  &:last-child {
    .spcart {
      border-width: 1px;
    }
  }

}


.cart-props-info {
  &-text {

    &-lg {
      font-size: 125%;
    }
  }
}


.shop-cart-page {
  .page_selectors {
    width: 100%;
    padding: u.size('unit-2x') u.size('unit');
    box-sizing: border-box;
    @include u.below('sm') {
      font-size: u.size('text-md');
    }

    @include u.below('xs') {
      width: 100%;
      display: flex;
      align-items: baseline;
      background-color: color.scale($optwear-blue, $lightness: 50%);
      border-radius: u.size('unit-half');
      padding: u.size('unit');
      margin: u.size('unit-2x') 0;
      b {
        color: #fff;
      }
    }

    &-item {
      @include u.below('xs') {
        flex: 1;
        text-align: center;
      }
    }
  }

  .sp-banks {
    @include u.below('xs') {
      display: flex;
      > .btn {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  }

  .ui-tabs-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: u.size('unit') !important;
    @include u.below('xs') {
      flex-wrap: wrap;
    }

    &:after {
      display: none;
    }

    > .button_sp {
      margin: 0 0 0 auto;
      @include u.below('sm') {
        width: u.size('unit-4x') !important;
        margin: 0 0 0 u.size('unit-2x');
      }
      @include u.below('xs') {
        order: -1;
        width: 100% !important;
        margin: 0 0 u.size('unit') auto;
      }
    }

    li {
      border-radius: u.size('unit-half') !important;
      top: 0;
      position: relative;

      a {
        width: 100%;
        box-sizing: border-box;
        padding: u.size('unit') u.size('unit-2x') !important;
        text-align: center;
        @include u.below('sm') {
          padding: u.size('unit') u.size('unit-half') !important;
        }
      }

      &.ui-tabs-selected {
        margin-bottom: 1px !important;
        padding-bottom: 0 !important;
      }

      //&:before {
      //		content: '';
      //		position: absolute;
      //		left: 0;
      //		width: 100%;
      //		height: 50%;
      //		background-color: #fff;
      //		bottom: -#{u.size('unit')};
      //		transition: all .15s ease-in-out;
      //		opacity: 0;
      //		visibility: hidden;
      //		z-index: 0;
      //}
      //
      //&.ui-tabs-selected {
      //		&:before {
      //				opacity: 1;
      //				visibility: visible;
      //		}
      //}

      @include u.below('sm') {
        flex: 1
      }
    }
  }

  .ui-tabs-panel {
    padding: 0 !important;
  }

  div[id^=purchase].forabg table.sp thead tr {
    background: transparent;
  }

  .forabg {
    background-color: $optwear-orange-middle;
    box-sizing: border-box;

    th {
      text-align: center;
    }
  }

  .fieldset_sp {
    @include u.below('sm') {
      display: flex;
      flex-direction: column;

      //label {
      //		margin: 0 0 u.size('unit');
      //}

      select {
        width: 100% !important;
        margin: 0 0 u.size('unit');
      }

      .ui-buttonset {
        margin-right: 0;
        display: flex;
        align-items: stretch;
        width: 100%;

        label {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          > .ui-button-text {
            padding: u.size('unit') 0;
          }
        }
      }
    }
  }

}

.payment_block {
  > tbody {
    @include u.below('md') {
      display: flex;
      flex-direction: column;
      > tr {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: u.size('unit-2x') u.size('unit');
      }
    }
  }
}

.payment_block_add {
  @include u.between('md', 'lg') {
    .autowidth {
      width: 100px !important;
    }
  }
  @include u.below('md') {
    font-size: u.size('text-md') !important;
  }
  @include u.below('xs') {
    font-size: u.size('text-xs') !important;
  }


  &-label {
    @include u.below('md') {
      width: 250px;
      text-align: left;
      @include u.below('sm') {
        width: auto;
        margin: 0 0 u.size('unit');
      }
      @include u.below('xs') {
        margin: 0 0 u.size('unit-half');
      }
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    @include u.above('md') {
      justify-content: space-between;
    }
  }

  tr {
    @include u.below('md') {
      padding: u.size('unit');
    }

    &:first-child {
      display: flex;
      align-items: flex-end;
      @include u.below('md') {
        display: grid;
        grid-template: auto / 1fr 1fr;
        grid-gap: u.size('unit') u.size('unit-2x');
        align-items: center;

        td {

          &:not(.cart-reference) {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @include u.below('sm') {
              flex-direction: column;
              align-items: flex-start;
            }

            input, select {
              margin: 0 0 0 u.size('unit');

              &:not([type="checkbox"]) {
                flex: 1;
                @include u.below('sm') {
                  margin: 0;
                  width: 100% !important;
                  box-sizing: border-box;
                }
              }
            }

            .button_sp.add_payment {
              @include u.below('md') {
                width: 100% !important;
                box-sizing: border-box;
              }
            }

            &:not(:last-child) {
              margin: 0 0 u.size('unit-2x');
              @include u.below('xs') {
                margin: 0 0 u.size('unit');
              }
            }
          }

          &.cart-reference {
            text-align: left;
            @include u.below('md') {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .payment_block_add-label {
                margin: 0;
              }

              #reference {
                margin: 0 0 0 u.size('unit');
              }

            }


          }

          &.payment-button {
            @include u.below('md') {
              grid-column: span 2;
            }
          }


        }
      }
    }
  }

  &-footer {
    display: block;

    > td {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      @include u.below('xs') {
        justify-content: center;
      }
    }
  }
}

.sp {
  &-tr {
    &-cr-switcher {
      &-title {
        padding: u.size('unit-2x') u.size('unit') u.size('unit-2x') u.size('unit-3x') !important;
      }
    }

  }

  &-th {

    &-action {
      width: u.size('unit-4x');
      @include u.below('lg') {
        width: 50%;
      }
      @include u.below('sm') {
        width: 100%;
      }

      &:before {
        content: "\00a0\00a0";
        display: block;
        @include u.below('lg') {
          content: 'Лот';
        }
      }
    }

    &-image {
      width: u.size('unit-6x') * 2;
    }

    &-cost {
      @include u.below('lg') {
        flex: 1;
      }
    }

    &-percent {
      @include u.below('lg') {
        flex: 1;
      }
    }

    &-delivery {
      @include u.below('lg') {
        flex: 1;
      }
    }

    &-count {
      @include u.below('lg') {
        flex: 1;
      }
    }

    &-amount {
      @include u.below('lg') {
        flex: 1;
      }
    }

    //&-title {
    //		width: 25%;
    //}
    //
    //&-catalog {
    //		width: 20%;
    //}
  }

  &-td {
    &-actions {
      @include u.below('lg') {
        grid-area: actions;
        align-self: flex-end;
        justify-self: flex-start;
      }

      @include u.below('sm') {
        align-self: center;
      }

      @include u.below('xs') {
        position: absolute;
        top: u.size('unit');
        right: u.size('unit');

        > *:first-child {
          margin: 0 !important;
        }
      }

    }

    &-image {
      width: u.size('unit-6x') * 2;

      .highslide {
        width: 100%;
        height: 100%;
        display: block;
      }

      @include u.below('lg') {
        grid-area: image;
        width: 100%;
      }
      @include u.below('sm') {
        margin: 0 0 u.size('unit');
      }
      @include u.below('xs') {
        margin: 0;
      }
    }


    &-name {
      @include u.below('lg') {
        grid-area: name;
        margin: 0 0 u.size('unit');
        width: 100%;
      }

      @include u.below('xs') {
        margin: 0 0 u.size('unit-half');
      }
    }

    &-id {
      @include u.below('lg') {
        grid-area: id;
        //display: flex;
        //flex-direction: column;
        width: 100%;
      }

      &:before {
        content: 'ID:';
        display: none;
        font-weight: 700;
        @include u.below('lg') {
          display: block;
        }
      }
    }

    &-date {
      @include u.below('lg') {
        grid-area: date;
        //display: flex;
        //flex-direction: column;
        width: 100%;
      }

      &:before {
        content: 'Дата:';
        display: none;
        font-weight: 700;
        @include u.below('lg') {
          display: block;
        }
      }
    }

    &-catalog {
      @include u.below('lg') {
        grid-area: catalog;
        //display: flex;
        //flex-direction: column;
        width: 100%;
        > a {
          color: #004d97 !important;
        }
      }

      @include u.below('md') {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @include u.below('xs') {
        margin: 0 0 u.size('unit-half');
      }


      //&:before {
      //		content: 'Каталог:';
      //		display: none;
      //		font-weight: 700;
      //		@include u.below('lg') {
      //				display: block;
      //		}
      //}
    }

    &-params {
      @include u.below('lg') {
        grid-area: params;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin: u.size('unit') 0;
      }
      @include u.below('sm') {
        margin: u.size('unit') 0 u.size('unit-3x');
      }
      @include u.below('xs') {
        margin: 0 0 u.size('unit-half');
      }

      &-item {
        &:not(:first-child) {
          margin: u.size('unit') 0 0;
          @include u.below('xs') {
            margin: u.size('unit-half') 0 0;
          }
        }

        select {
          width: 100%;
        }
      }
    }

    &-cost {
      @include u.below('lg') {
        grid-area: cost;
        align-self: center;
      }
      @include u.below('sm') {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @include u.below('xs') {
        @include u.below('xs') {
          padding: u.size('unit-3x') 0;
        }
      }

      &:before {
        content: 'Цена';
        display: none;
        margin: 0 0 u.size('unit');
        @include u.below('sm') {
          display: flex;
        }
        @include u.below('xs') {
          margin: 0 0 u.size('unit-half');
        }
      }
    }

    &-cost2 {
      @include u.below('lg') {
        grid-area: cost2;
        align-self: center;
      }
      @include u.below('sm') {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @include u.below('xs') {
        @include u.below('xs') {
          padding: u.size('unit-3x') 0;
        }
      }

      &:before {
        content: 'Моя цена';
        display: none;
        margin: 0 0 u.size('unit');
        @include u.below('sm') {
          display: flex;
        }
      }
    }

    &-percent {
      @include u.below('lg') {
        grid-area: percent;
        align-self: center;
      }
      @include u.below('sm') {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @include u.below('xs') {
        @include u.below('xs') {
          padding: u.size('unit-3x') 0;
        }
      }

      &:before {
        content: 'Орг %';
        display: none;
        margin: 0 0 u.size('unit');
        @include u.below('sm') {
          display: flex;
        }
      }
    }

    &-delivery {
      @include u.below('lg') {
        grid-area: delivery;
        align-self: center;
      }
      @include u.below('sm') {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @include u.below('xs') {
        @include u.below('xs') {
          padding: u.size('unit-3x') 0;
        }
      }

      &:before {
        content: 'Доставка';
        display: none;
        margin: 0 0 u.size('unit');
        @include u.below('sm') {
          display: flex;
        }
      }
    }

    &-count {
      @include u.below('lg') {
        grid-area: count;
        align-self: center;
      }
      @include u.below('sm') {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @include u.below('xs') {
        @include u.below('xs') {
          padding: u.size('unit-3x') 0;
        }
      }

      &:before {
        content: 'Кол-во';
        display: none;
        margin: 0 0 u.size('unit');
        @include u.below('sm') {
          display: flex;
        }
      }
    }

    &-amount {
      @include u.below('lg') {
        grid-area: amount;
        align-self: center;
      }
      @include u.below('sm') {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @include u.below('xs') {
        @include u.below('xs') {
          padding: u.size('unit-3x') 0;
        }
      }

      &:before {
        content: 'Сумма';
        display: none;
        margin: 0 0 u.size('unit');
        @include u.below('sm') {
          display: flex;
        }
      }
    }

    &-status {
      width: u.size('unit-8x') * 2;

      &-item {
        min-height: u.size('unit-5x');
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: u.size('unit');

        @include u.below('xs') {
          min-height: u.size('unit-3x');
          height: u.size('unit-3x');
        }
      }

      &-review {
        cursor: pointer;
        flex-direction: column;
        width: 100%;
        margin: 0;
        background-color: u.var-color('primary-lt');
        color: #fff;
        padding: u.size('unit') 0;
        border: none;
        transition: background-color .25s ease-in-out;

        @include u.below('xs') {
          height: auto;
        }

        @include u.mixins-hover() {
          background-color: u.var-color('primary');
        }

        &-icon {
          @include u.square(u.size('unit-half-2x'));
          margin: 0 u.size('unit-half') 0 0;
        }

        &-text {
          margin: 0 0 u.size('unit');
        }

        &-label {
          display: flex;
          align-items: center;
        }
      }

      @include u.below('lg') {
        grid-area: status;
        width: 100%;
        align-self: flex-end;
      }
      @include u.below('sm') {
        align-self: center;
      }
    }

    &-info {
      width: 15%;

      textarea {
        margin: u.size('unit') 0 0;
        resize: vertical;
        min-height: u.size('unit-10x');
        width: 100% !important;
        box-sizing: border-box;
        @include u.below('lg') {
          height: u.size('unit-5x');
          min-height: 0;
        }
        @include u.below('xs') {
          height: u.size('unit-5x');
          margin: 0;
        }
      }

      .al-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include u.below('lg') {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        &-adm-comment {
          font-weight: 700;
        }

        &-from {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          @include u.below('lg') {
            width: 100%;
            justify-content: flex-end;
            margin: u.size('unit') 0 0;
            flex-direction: row;
            span:first-of-type {
              margin: 0 u.size('unit-half') 0 0;
            }
          }
        }

        &-who {
          display: flex;
          //flex-direction: column;
          justify-content: flex-end;
          flex-wrap: wrap;
          margin: 0 0 u.size('unit-half');
          @include u.below('lg') {
            //flex-direction: row;

            //margin: 0 u.size('unit-4x') 0 0;
            span:first-of-type {
              margin: 0 u.size('unit-half') 0 0;
            }
          }
          @include u.below('sm') {
            margin: 0 0 u.size('unit-half');
          }

        }
      }

      @include u.below('lg') {
        grid-area: info;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
      }
      @include u.below('sm') {
        margin: u.size('unit') 0 0;
      }
      @include u.below('xs') {
        margin: 0;

      }

    }
  }
}

.shop-cart-page {
  thead, tbody, tfoot {
    background-color: transparent;
    border: none;
  }

  .m-preloader-init {
    background-color: u.var-color('body-background');
  }

  .ui-widget {
    font-size: u.size('text-xs') !important;
  }
;

  .ui-datepicker {
    width: auto !important;
  }
}

#sp_seting {
  > .inner {
    display: flex;
    flex-direction: column;
  }

  .panel-close {
    margin: u.size('unit') 0 0 auto;
    display: flex;

    svg {
      @include u.square(u.size('unit-2x'));
    }
  }
}

.tabs-panel-close {
  float: right;
  height: 29px;
  line-height: 29px;
  width: 18px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  padding: 0 u.size('unit') !important;
}


.spcart-td-actions-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;

  @include u.mixins-hover {
    > .mobile-modal {
      opacity: 1;
      visibility: visible;
    }
  }

  &-button {
    @include u.mixins-text-height(u.size('unit-3x'));
    padding: 0 u.size('unit') !important;
    display: flex !important;
    align-items: center;

    svg {
      @include u.square(u.size('unit-2x'));
    }


  }

  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;

    &-item {
      width: 100%;
      @include u.above('md') {
        padding: 0 u.size('unit-2x');
      }

      box-sizing: border-box;

      &:not(:last-child) {
        margin: 0 0 u.size('unit');
        @include u.below('md') {
          margin: 0 0 u.size('unit-2x');
        }
      }

      .button_sp {
        color: #2e6e9e;
      }

      &-archive {
        background: u.var-color('primary-lt') !important;
        color: #fff !important;
        border: none !important;
      }

      &-icon {
        @include u.square(u.size('unit-half-2x'));
      }

      &-reputation {
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        border: none !important;

        .svg-icon {
          @include u.square(u.size('unit-half-2x'));
        }

        &-up {
          background: u.var-color('success') !important;
        }

        &-down {
          background: u.var-color('danger') !important;
        }
      }

      > .button_sp, form > .button_sp {
        text-align: center;
        box-sizing: border-box;
        width: 100%;
        @include u.mixins-text-height(u.size('unit-3x'));
        padding: 0 u.size('unit') !important;
        @include u.below('md') {
          @include u.mixins-text-height(u.size('unit-5x'));
        }
      }
    }
  }

  > .mobile-modal {
    @include u.above('md') {
      transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 100%;
      right: 0;
      background-color: u.var-color('grey-10');
      border-radius: u.size('unit');
      padding: u.size('unit-2x') 0;
      z-index: u.z-index('after-default');
      @include u.mixins-box-shadow-md(6);
    }
  }

  a {
    color: #2e6e9e;
  }


  &.hidden {
    display: none;
  }
}

.al-info-adm-comment {
  text-align: right;
  background-color: rgba($optwear-yellow, .5);
  padding: 0 u.size('unit-half');
  word-break: break-all;
  @include u.below('lg') {
    text-align: left;
  }
}

.payment_block_add-footer {
  margin: u.size('unit') 0 0;
}

.shop-cart-page {
  .highslide-wrapper {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .highslide-gallery {
    position: relative;
    @include u.below('xs') {

      > a.highslide {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.sp-tr-cr-switcher-show {


  &-wrapper {

  }

  &-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-btn {
    width: u.size('unit-10x') * 2;
    margin: 0 0 0 u.size('unit') !important;
    @include u.mixins-text-height(u.size('unit-3x'));
    padding: 0 u.size('unit') !important;
  }
}

.sp-tr-cr-switcher-info {
  &.hidden {
    display: none;
  }

  &.fixed {
    > .top {
      display: none;
    }

    > .vat {
      border-width: 1px 0 1px 0;
    }
  }
}

.cart-props-info-container {
  &.paid {
    border-top: 1px solid #989898;
    margin: u.size('unit') 0 0;
    padding: u.size('unit') 0 0;
  }

  &.hidden {
    display: none;
  }
}

.cart-props-info-show {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-btn {
    width: u.size('unit-10x') * 2;
    margin: 0 0 0 u.size('unit') !important;
    @include u.mixins-text-height(u.size('unit-3x'));
    padding: 0 u.size('unit') !important;
  }
}

.modal-container {
  > .lot-summary {
    width: 100%;
  }
}

.highslide-container {
  > table {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

.cart-search {
  margin: u.size('unit') 0 !important;
  font-size: u.size('text-md') !important;

  &-highlight {
    background-color: rgba($optwear-yellow, .5);
    font-weight: 700;
    padding: 0 u.size('unit-half');
  }
}


#cartSearchBtnSearch:disabled {
  pointer-events: none;
}

.cart-main {
  position: relative;
  overflow: initial !important;
}

.cart-banks {
  padding: 0 u.size('unit-2x');
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  @include u.below('xs') {
    width: 100%;
    flex: auto;
    margin: u.size('unit-half') 0 0;
    padding: 0;
  }

  &-container {
    display: flex;
    align-items: center;
    @include u.below('xs') {
      width: 100%;
    }
  }

  &-item {
    display: flex !important;
    align-items: center;
    padding: 0 u.size('unit-2x') !important;
    line-height: 29px !important;
    margin: 0 0.2em 1px 0 !important;
    position: relative;
    top: 1px;
    @include u.below('xs') {
      flex: 1;
      justify-content: center;
    }

    svg {
      margin: 0 u.size('unit') 0 0;
      @include u.square(u.size('unit-2x'));
    }

    &-alfa {
      color: #EF3124 !important;

    }

    &-sber {
      color: #00be64 !important;
    }
  }
}

.cart-sale, .cart-resale {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: u.size('unit-2x') 0 0;
  @include u.below('xs') {
    margin: u.size('unit') 0 0;
  }
  @include u.square(u.size('unit-3x'));
  transition: color .25s ease-in-out;
  color: u.var-color('text-lt');

  svg {
    @include u.square(u.size('unit-3x'));
  }

  padding: 0;
  border: none;
  background: transparent;
}

.cart-resale {
  cursor: pointer;
  @include u.mixins-hover {
    color: u.var-color('primary');
  }
  @include u.below('md') {
    color: u.var-color('primary');
  }
}

#sp_seting {
  font-size: u.size('text-sm') !important;
}
