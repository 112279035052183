.lot-row-part {
		&:not(:last-child) {
			margin-right: 60px;
		}
		&_pos {
			&_left {
				width: 12%;
				padding-top: 10px;
				padding-right: 0;
				padding-left: 20px;
				padding-bottom: 10px;
			}
			&_center, &_right {
				display: flex;
				flex-direction: column;
				width: 43%;
				padding: 15px 0 20px;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
			}
			&_right {
				width: 31%;
				padding: 15px 20px 20px 0;
			}
		}
	}
