@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.catalog-sort-types {
  //padding-top: 9px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    width: u.size('unit-4x');
    height: 100%;
    background: linear-gradient(90deg, rgba(u.get-color-value('body-background'), 0) 0%, u.var-color('body-background') 100%);
    bottom: 0;
    right: 0;
    display: none;
    position: absolute;
    @include u.below('sm') {
      display: block;
    }
  }

  @include u.below('lg') {
    margin: 10px 0 0;
    padding: 0 0 0 10px;
    order: 1;
  }
  @include u.below('xs') {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    padding: 0;
  }

  &__btn {
    padding-right: 15px;
    display: inline-flex;
    align-items: center;
    @include u.below('sm') {
      display: flex;
      align-items: center;
    }
    @include u.below('xs') {
      box-sizing: border-box;
      white-space: nowrap;
    }

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      @include u.below('sm') {
        padding-right: u.size('unit-4x');
      }
    }

    &:hover {
      text-decoration: none;
      color: $optwear-orange-middle;
    }

    &-groups {
      display: inline;

      @include u.below('sm') {
        display: flex;
        padding: 10px 0;
        position: relative;
        width: 100%;
        overflow-x: auto;
        .catalog-sort-types__btn:first-child {
          padding: 0 15px 0 0;
        }
      }
    }

    &_active.catalog-sort-types__btn_dir_a {
      &:after {
        content: '';
        margin: 0 0 0 u.size('unit-half');
        width: u.size('unit-2x');
        height: u.size('unit-2x');
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiIGZpbGw9IiM2NTY1NjUiPjxwYXRoIGQ9Ik0wIDBoNHYyaC00ek0wIDNoNnYyaC02ek0wIDZoOHYyaC04eiIvPjwvc3ZnPgo=) no-repeat center center;
      }

    }

    &_active.catalog-sort-types__btn_dir_b {

      &:after {
        content: '';
        margin: 0 0 0 u.size('unit-half');
        width: u.size('unit-2x');
        height: u.size('unit-2x');
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiIGZpbGw9IiM2NTY1NjUiPjxwYXRoIGQ9Ik0wIDZoNHYyaC00ek0wIDNoNnYyaC02ek0wIDBoOHYyaC04eiIvPjwvc3ZnPgo=) no-repeat center center;
      }
    }
  }
}

.view-type {
  float: right;
  margin-right: 10px;
  cursor: pointer;

  &:focus, &:hover {
    outline: none;

    svg {
      transition: all 0.5s;
      transform: scale(0.8, 0.8);
    }
  }

  svg path {
    fill: $color-background;
  }

  &__active svg path {
    fill: $optwear-blue-light;
  }
}


