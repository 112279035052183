.ui-widget {
	font-family: $second-font-family;
}

.ui-widget .ui-widget {
	font-size: 1em;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
	font-family: $second-font-family;
	font-size: 1em;
}

//.ui-widget-content {
//	border: 1px solid $color-background;
//	background: $optwear-white;
//	color: $color-text;
//}
//
//.ui-widget-content a {
//	color: $color-text-link;
//}
//
//.ui-widget-header {
//	border: 1px solid #4297d7;
//	background: $optwear-blue-strong;
//	color: $optwear-white;
//	font-weight: bold;
//}
//
//.ui-widget-header a {
//	color: $optwear-white;
//}
