@use "src/assets/scss/utils" as u;

.ForumListAds {
  overflow: hidden;
  width: u.size('unit-10x') * 3;
  margin: 0 0 0 u.size('unit-2x');
  display: flex;
  flex-direction: column;
  @include u.below('md') {
    display: none;
  }

  &-Banner {
    overflow: hidden;
    min-height: 300px;
    width: 100%;

    &:not(:last-child) {
      margin: 0 0 u.size('unit');
      @include u.below('md') {
        margin: 0 u.size('unit') 0 0;
      }
    }

    @include u.below('md') {
      min-height: 0;
      height: u.size('unit-10x');
      flex: 1;
      width: auto;
    }
  }

  &Wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &Content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  @include u.below('lg') {
    width: u.size('unit-10x') * 2;
    margin: 0 0 0 u.size('unit');
  }
  @include u.below('md') {
    flex-direction: row;
    width: 100%;
    order: -1;
    margin: 0 0 u.size('unit');
  }
}
