.lot-summary-features-btn-group {
  margin-top: 1em;
  &__item {
    &-wrapper + &-wrapper {
      margin-left: 16px;
    }
    cursor: pointer;
    color: $optwear-blue-light;
    transition: color 0.12s ease-out;
    text-decoration: none;
    outline: 0;
    touch-action: manipulation;
  }
}
