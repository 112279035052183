.lot-table {
	width: 100%;
	margin: 0 auto;
	clear: both;
	border-collapse: separate;
	border-spacing: 0;
	border-bottom: 1px solid $color-background;
	border-top: 1px solid $color-background;
	text-align: center;
	& thead th,
	& thead td {
		font-weight: bold;
		padding: 10px 18px;
		border-bottom: 1px solid $color-background;
	}
	& tbody th, & tbody td {
		padding: 8px 10px
	}
	& thead th:active,
	& thead td:active {
		outline: none;
	}
	& thead .sorting,
	& thead .sorting_asc,
	& thead .sorting_desc,
	& thead .sorting_asc_disabled,
	& thead .sorting_desc_disabled {
		cursor: pointer;
		*cursor: hand;
		background-repeat: no-repeat;
		background-position: center right;
	}

	& thead .sorting {
		position: relative;
		&:after {
			position: relative;
			content: "   ⥯";
			top: 2px;
			width: 15px;
			min-width: 15px;
			height: 15px;
		}
	}

	& thead .sorting_asc {
		position: relative;
		&:after {
			position: relative;
			content: "    ";
			width: 15px;
			min-width: 15px;
			height: 15px;
			background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiIGZpbGw9IiM2NTY1NjUiPjxwYXRoIGQ9Ik0wIDBoNHYyaC00ek0wIDNoNnYyaC02ek0wIDZoOHYyaC04eiIvPjwvc3ZnPgo=") no-repeat right 55%;
		}
	}
	& thead .sorting_desc {
		position: relative;
		&:after {
			content: "    ";
			position: relative;
			width: 15px;
			height: 15px;
			background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiIGZpbGw9IiM2NTY1NjUiPjxwYXRoIGQ9Ik0wIDZoNHYyaC00ek0wIDNoNnYyaC02ek0wIDBoOHYyaC04eiIvPjwvc3ZnPgo=") no-repeat right 55%;
		}
	}

	& tbody tr:hover {
		background-color: lighten($color-background, 10%);
	}

}

td[class*="lot-table__status_c"] {
	color: $optwear-white;
}

// c - color
.lot-table__status_c_ {
	&0 {
		background-color: desaturate(#00ff00, 55%);
	}

	&1 {
		background-color: desaturate(#ff9900, 55%);
	}

	&2 {
		background-color: transparent;
		color: $color-text !important;
	}

	&3 {
		background-color: desaturate(#ff0000, 55%);
	}

	&4 {
		background-color: desaturate(#1e6ced, 55%);
	}

	&5 {
		background-color: desaturate(#9d9d9d, 55%);
	}

	&6 {
		background-color: desaturate(#ff0000, 55%);
	}

	&7 {
		background-color: desaturate(#8040ff, 55%);
		color: $optwear-white;
	}

	&8 {
		background-color: desaturate(#ff00ff, 55%);
		color: $optwear-white;
	}
}

@import "wrapper";
