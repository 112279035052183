.turbo .forabg {
	border: 2px dotted #fc8f3d;
	background-color: transparent;
}

.turbo-label {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	min-width: 147px;
	padding: 0 10px;
	font-size: 17px;
	font-family: monospace;
	font-weight: bold;
}

.onoffswitch {
	position: relative;
	width: 90px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.onoffswitch-checkbox {
	display: none;
}

.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	height: 30px;
	padding: 0;
	line-height: 30px;
	border: 2px solid #999999;
	border-radius: 30px;
	background-color: #EEEEEE;
	transition: background-color 0.3s ease-in;
}

.onoffswitch-label:before {
	content: "";
	display: block;
	width: 30px;
	margin: 0;
	background: #FFFFFF;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 58px;
	border: 2px solid #999999;
	border-radius: 30px;
	transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
	background-color: #1D809B;
}

.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
	border-color: #1D809B;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
	right: 0;
}
