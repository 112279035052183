@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.message {
  border: 1px solid $color-background;
  border-radius: u.size('unit');
  padding: u.size('unit-2x');
  background: $optwear-white;
  font-size: 15px;
  box-sizing: border-box;
  @include u.below('md') {
    padding: u.size('unit');
  }

  &_w {
    background-color: $color-warning;
    color: $optwear-white;
  }

  &_no-padding {
    padding: 0;
  }
}

.StandaloneMessage {
  @extend .message;
  margin: u.size('unit-6x') auto;
  box-sizing: border-box;
  width: 680px;

  &-label {
    text-align: center;
    font-size: 20px;
    margin: 0 0 25px;
    font-weight: 700;
  }

  @include u.below('md') {
    width: 80%;
    margin: u.size('unit-3x') auto;
  }
  @include u.below('sm') {
    width: 100%;
    margin: 0 auto;
  }
}
