@use "src/assets/scss/utils" as u;

.user-part {
  &-avatar {
    @include u.square(u.size('unit-8x'));
    min-width: u.size('unit-8x');
    min-height: u.size('unit-8x');
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    border: 1px solid var(--color-grey-50, gray);
    box-sizing: border-box;

    @include u.below('md') {
      @include u.square(u.size('unit-6x'));
      min-width: u.size('unit-6x');
      min-height: u.size('unit-6x');
    }

    &-sm {
      @include u.square(u.size('unit-4x'));
      min-width: u.size('unit-4x');
      min-height: u.size('unit-4x');
      @include u.below('md') {
        @include u.square(u.size('unit-4x'));
        min-width: u.size('unit-4x');
        min-height: u.size('unit-4x');
      }
    }

    &-no-image {
      @include u.mixins-absolute-center;
      width: 100%;
      height: 100%;
    }

    img {
      @include u.mixins-absolute-center;
      object-fit: contain;
      font-family: "object-fit: contain;";
      width: 100% !important;
      height: 100% !important;
    }

    &-mobile {
      @include u.below('md') {
        @include u.square(u.size('unit-8x'));
        min-width: u.size('unit-8x');
        min-height: u.size('unit-8x');
        position: relative;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        border: 1px solid var(--color-grey-50, gray);
        box-sizing: border-box;
      }
      @include u.below('sm') {
        @include u.square(u.size('unit-6x'));
        min-width: u.size('unit-6x');
        min-height: u.size('unit-6x');
      }
    }
  }
}
