@import "@Scss/old/common/_variables.scss";
.LoginActionStandalone {

	&-errorMessage {
		min-height: 40px;
		padding: 7px 18px 9px;
		margin: 15px 20px;
		border: 1px solid #f2ab99;
		border-radius: 2px;
		background: #ffefe9;
		font-size: 14px;
	}

	&-wrap {
		margin: 0 auto;
		width: 350px;
	}

	.Login {
		&-btnWrapper {
			display: flex;
			margin-top: 13px;
			margin-bottom: 7px;

		}
		&Captcha {
			display: block;

			&-code {
				margin-top: 10px;
			}
		}
	}

}
