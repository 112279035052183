@use "src/assets/scss/utils" as u;
@import "@Scss/old/common/_variables.scss";

.bbcodeSpoiler {
  &Wrap {
    overflow: hidden;
    border-bottom-width: 1px;
    font-size: 1em;
    margin: 4px 0;
    box-shadow: 0 2px 1px #cccccc;
  }

  &-icon {
    position: relative;
    border-radius: 5px;
    background-color: u.var-color('grey-10');
    border: 1px solid u.var-color('grey-25');
    margin: 0 u.size('unit') 0 0;
    width: 16px;
    height: 16px;
    display: flex;
    box-sizing: border-box;

    &-plus {
      opacity: 0;
      visibility: hidden;
    }

    &-minus {
      opacity: 1;
      visibility: visible;
    }

    svg {
      width: 70%;
      height: 70%;
      @include u.mixins-absolute-center;
      transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    }
  }

  &Header {
    background-color: #d9d9d9;
    padding: 6px 5px;
    font-size: 0.9em;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    &-hidden {
      .bbcodeSpoiler-icon {
        &-plus {
          opacity: 1;
          visibility: visible;
        }

        &-minus {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    //&-hidden:before {
    //	content: "+" !important;
    //}
    //&:before {
    //	content: "─";
    //	border: 2px solid #cccccc;
    //	line-height: 13px;
    //	padding: 1px;
    //	background: linear-gradient(135deg, $color-text-weak, #ffffff);
    //	text-align: center;
    //	width: 10px;
    //	top: 4px;
    //	left: 1px;
    //	position: absolute;
    //}
    //&-label {
    //	padding-left: 18px;
    //}
  }

  &Body {
    display: block;
    border-bottom: 1px solid #cccccc;
    padding: 3px;

    &-hidden {
      display: none;
    }
  }
}
