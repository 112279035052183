@use "src/assets/scss/utils" as u;

.index-seo-text {
  @include e(content) {
    flex-direction: row;
    @include u.below('sm') {
      flex-direction: column;
    }
  }
  @include e(block) {
    flex: 1;
    display: flex;
    flex-direction: column;
    &:not(:last-of-type) {
      margin: 0 64px 0 0;
      @include u.below('sm') {
        margin: 0 0 32px;
        flex: auto;
      }
    }
  }
  @include e(title) {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    @include u.below('sm') {
      margin: 0 0 8px;
    }
  }
  @include e(text) {
    font-size: 13px;
    color: #585858;
    margin: 0 0 32px;
    @include u.below('sm') {
      margin: 0 0 16px;
    }
    &:last-child {
      margin: 0;
    }
  }
  @include e(list) {
    margin: 0 0 32px;
    @include u.below('sm') {
      margin: 0 0 16px;
    }
    list-style: disc;
    & > .index-seo-text__text {
      margin: 0;
    }
    &:last-child {
      margin: 0;
    }
  }
}
