.NavLineItems-wrap nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.submenu {
	list-style: none;
	box-sizing: border-box;
	border-top: 1px solid #e5e5e5;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.07);
	background: $optwear-white;
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	padding: 15px;
	position: absolute;
	top: 100%;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	transform: translate3d(0, 30px, 0);
	transform-origin: 0 0;
	transition-delay: 0.3s;
	& > * {
		box-sizing: border-box;
	}
	&-left{
		left: 0;
	}
	&-right{
		right: 0;
	}
	&-four-items >li {
		max-width: 25% !important;
	}
	img {
		display: block;
		width: 100%;
	}
	h3 {
		font-size: 20px;
		font-weight: 400;
		margin: 10px 0;
	}
	ul {
		font-family: $light-font-family;
		font-weight: normal;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	& > li{
		justify-items: flex-start;
		flex-wrap: wrap;
		//padding: 0 0 0 18px;
		margin-bottom: 5px;
		max-width: 33%;
		ul {
			max-width: 420px;
			flex-direction: column;
			display: flex;
			justify-items: flex-start;
			flex-wrap: wrap;
			//max-height: calc(100vh - 205px - 18px); // 205 - высота хедера
		}
		& > a {
			font-weight: bold;
		}
	}
	li {
		.link-image {
			padding: 0;
		}
		a {
			line-height: 1.4em;
			color: $color-text;
			white-space: nowrap;
			font-size: 16px;
			text-align: left;
		}
	}
}

.top-menu {
	position: relative;
	& > li {
		&:hover .submenu,
		&:hover .submenu-standart,
		&:focus-within .submenu,
		&:focus-within .submenu-standart,
		&:active .submenu,
		&:active .submenu-standart {
			max-width: 1200px;
			opacity: 1;
			visibility: visible;
			transform: translate3d(0, 0, 0);
		}
		& > a {
			display: block;
			transition: 0.2s linear;
			white-space: nowrap;
			text-align: center;
		}
	}
	& > * {
		box-sizing: border-box;
	}
}

