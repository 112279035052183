@use "src/assets/scss/utils" as u;

.lot-table__wrapper {
  border: 1px solid $color-background;
  border-radius: 5px;
  background: $optwear-white;

  .ui-widget-header {
    background: none;
    color: u.color('text');
  }

  .dataTable {
    thead {
      @include u.below('sm') {
        display: none;
      }
    }

    tbody {
      tr {
        @include u.below('xs') {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid u.color('grey-50');
          text-align: left;
        }
      }
    }

    th {
      padding: 0 !important;

      &:nth-child(2) {
        width: 200px !important;
      }

      &:nth-child(6) {
        width: 130px !important;
      }
    }
  }

  .TopicCatalogControlsUpdate {
    float: right;
    display: inline-flex;
    padding: 9px 10px 0;
    box-sizing: border-box;
    @include u.below('sm') {
      float: none;
    }
  }

  .dataTables {
    &_length,
    &_info {
      float: left;
      padding-top: 8px;
      padding-right: 0;
      padding-left: 15px;
      padding-bottom: 10px;
    }

    &_filter,
    &_paginate {
      float: right;
      text-align: right;
      padding: 5px;
      padding-right: 15px;
    }

    &_info {
      padding-top: 12px;
    }

    &_filter {
      padding-top: 13px;

      & input[type="search"] {
        border: 1px solid $color-background;
        margin-left: 8px;
      }
    }

    &_paginate {
      padding-top: 4px;

      & .ellipsis {
        padding: 1px 10px;
      }

      .paginate_button {
        box-sizing: border-box;
        display: inline-block;
        min-width: 1.5em;
        margin: 0;
        padding: 1px 10px;
        line-height: 30px;
        height: 34px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;
        *cursor: hand;
        border: 1px solid #d8d8d8;

        &.current,
        &.current:hover,
        &.current:focus,
        &.current:active {
          background-color: $optwear-blue-light;
          color: $optwear-white;
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:active {
          cursor: default;
          border: 1px solid transparent;
          background: transparent;
          box-shadow: none;
          color: $color-text;
        }

        &:hover,
        &:active {
          background-color: $optwear-blue-strong;
          color: $optwear-white;
        }
      }
    }
  }

  &:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}
