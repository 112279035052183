.attachtitle, .quotetitle {
	margin: 10px 5px 0;
	padding: 4px;
	border-width: 1px 1px 0;
	border-style: solid;
	border-color: $color-background;
	color: #333333;
	background-color: $color-topic-background;
	font-size: 0.85em;
	font-weight: 700;
}

.attachcontent, .codecontent, .quotecontent {
	border: solid #a9b8c2;
	font-weight: 400;
}

.quotetitle .quotetitle {
	font-size: 1em;
}

.attachcontent, .quotecontent {
	margin: 0 5px 10px;
	padding: 5px;
	border-width: 0 1px 1px;
	font-size: 1em;
	line-height: 1.4em;
	background-color: $optwear-white;

}
