/* Icons
---------------------------------- */
.ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	width: 16px;
	height: 16px;
	background: url("~@Assets/imageset-old/images-old/lib/jquery-ui/ui-icons_469bdd_256x240.png") no-repeat;
}

.ui-widget-content .ui-icon {
	background-image: url("~@Assets/imageset-old/images-old/lib/jquery-ui/ui-icons_469bdd_256x240.png");
}

.ui-widget-header .ui-icon {
	background-image: url("~@Assets/imageset-old/images-old/lib/jquery-ui/ui-icons_d8e7f3_256x240.png");
}

.ui-state-default .ui-icon {
	background-image: url("~@Assets/imageset-old/images-old/lib/jquery-ui/ui-icons_6da8d5_256x240.png");
}

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon {
	background-image: url("~@Assets/imageset-old/images-old/lib/jquery-ui/ui-icons_217bc0_256x240.png");
}

.ui-state-active .ui-icon {
	background-image: url("~@Assets/imageset-old/images-old/lib/jquery-ui/ui-icons_f9bd01_256x240.png");
}

.ui-state-highlight .ui-icon {
	background-image: url("~@Assets/imageset-old/images-old/lib/jquery-ui/ui-icons_2e83ff_256x240.png");
}

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
	background-image: url("~@Assets/imageset-old/images-old/lib/jquery-ui/ui-icons_cd0a0a_256x240.png");
}

@import "icon-positioning";
