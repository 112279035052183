.office-row {
	border-bottom: 1px solid $color-background;
	padding: u.size('unit-2x') u.size('unit-2x') u.size('unit-2x') u.size('unit-4x');
	cursor: pointer;
	position: relative;
		font-size: u.size('text-md');
		@include u.below('lg') {
				font-size: u.size('text-sm');
		}
		@include u.below('md') {
				font-size: u.size('text-xs');
				padding: u.size('unit') u.size('unit') u.size('unit') u.size('unit-4x');
		}

	&:hover, &:focus, &.current {
		background: $color-background;
	}

	&__title {
		margin: 0;
		margin-bottom: 7px;
			font-size: u.size('text-md');
			//@include u.below('lg') {
			//		font-size: u.size('text-md');
			//}
			@include u.below('md') {
					font-size: u.size('text-sm');
			}
	}

	&__btn {
		position: absolute;
		left: 8px;
		top: u.size('unit-2x');
		opacity: 0;
		transition: all 0.25s;
		padding: 0;
		margin: 0;
			@include u.below('md') {
			    opacity: 1;
					
			}
		&:hover, &:focus {
			outline: none;

			svg path {
				fill: $color-accent;
			}
		}

		&--processing {
			animation: selectingInProgress 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite alternate;
		}

		&--selected {
			opacity: 1;

			svg path {
				fill: $color-accent;
			}
		}
	}

	&:focus, &:hover &__btn {
		opacity: 1;
	}

	&__entries {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			margin: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			text-decoration: none;
			color: $color-text-link;

			&:hover, &:focus {
				text-decoration: none;
				color: $color-text-link-hover;
			}

			&:visited {
				color: $color-text-link-visited;
			}
		}
	}

	&--empty {
		height: 538px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@keyframes selectingInProgress {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.5);
	}
}
