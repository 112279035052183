.lot-empty {
	margin: 0 auto;
	width: 300px;
	&__icon {
		width: 300px;
		height: 200px;
		fill: darken($color-background, 40%);
	}
	&__label {
		margin-top: 0;
		text-align: center;
	}
}
