.sp-topic {
	&-progress-line {
		&__background {
			background: #cccccc linear-gradient(#d8d8d8, #7f7f7f);
			border: 1px solid black;
			width: 50%;
			font-size: 7px;
			line-height: 2px;
		}
		&__fill {
			max-width: 100%;
			height: 12px;
			font-size: 7px;
			line-height: 2px;
			border-right: 1px solid black;
			background-color: #008040;
			color: white;
			font-weight: bold;
			background-repeat: repeat-x;
			&_color{
				&_red{
						background: #583232 linear-gradient(#a13232, #583232);
				}
				&_yellow{
						background: #b2a000 linear-gradient(#ecd71d, #b2a000);
				}
				&_green{
						background: #1a8000 linear-gradient(#90bb7b, #1a8000);
				}
			}
		}
		&__percent {
			padding: 0;
			padding-left: 25px;
			font-size: 10px;
			line-height: 12px;
		}
	}
}
