/* Misc visuals
---------------------------------- */

/*  Corner radius  */
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
	border-top-left-radius: $border-radius-main;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
	border-top-right-radius: $border-radius-main;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
	border-bottom-left-radius: $border-radius-main;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
	border-bottom-right-radius: $border-radius-main;
}

/*  Overlays  */
.ui-widget-overlay {
	background: #aaaaaa url('~@Assets/imageset-old/images-old/lib/jquery-ui/ui-bg_flat_0_aaaaaa_40x100.png') 50% 50% repeat-x;
	opacity: 0.30;
	filter: Alpha(Opacity=30);
}

.ui-widget-shadow {
	margin: -8px 0 0 -8px;
	padding: 8px;
	background: #aaaaaa url('~@Assets/imageset-old/images-old/lib/jquery-ui/ui-bg_flat_0_aaaaaa_40x100.png') 50% 50% repeat-x;
	opacity: 0.30;
	filter: Alpha(Opacity=30);
	-moz-border-radius: 8px;
	-khtml-border-radius: 8px;
	-webkit-border-radius: 8px;
	border-radius: 8px;
}

