@use "src/assets/scss/utils" as u;

.ForumListTable {
  width: 100%;

  #forum-list .ForumList & {
    font-size: 20px;

    tr {
      td {
        vertical-align: baseline;
      }
    }
  }

  &-title {
    font-size: 15px;
  }

  &-heading {

  }

  &Topic {
    &-image {
      border-right: none !important;

      &-read {
        @include u.below('sm') {
          display: none !important;
        }
        border-right: none !important;
      }

      .sp_them_stat {
        @include u.below('sm') {
          @include u.square(u.size('unit-3x'));
          background-size: contain;
          background-position: center center;
        }
      }
    }

    &-pagination {
      display: inline-block;
    }

    &-form {
      width: 100%;

      td {
        width: 100% !important;
        @include u.below('sm') {
          height: auto;
        }
      }

      form {
        display: flex;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        @include u.below('sm') {
          width: 100%;
          flex-direction: column;
          //padding: 10px;
          select {
            margin: 0 0 u.size('unit-half');
          }
        }
        @include u.below('xs') {
          width: 100%;
        }
      }
    }

    &-title {
      margin: 0;
      display: inline-block;

      a {
        font-size: 15px;
      }

      @include u.below('sm') {
        width: 100%;
      }
    }

    &-counters {
      min-width: 150px;
    }

    &-LastPost {
      text-align: left;
      min-width: 150px;
      max-width: 150px;
      word-wrap: break-word;
      line-height: 16px;
    }

    &-author {

      width: 200px;
      @include u.below('sm') {
        width: 100%;
      }
    }
  }

  &-forumRow {
    td {
      padding: 10px;
      border-bottom: 1px solid #c0c8d0;
      border-right: 1px solid #c0c8d0;
    }

    &--noSplit {
      border-right: 0;
    }

    &:hover {
      background-color: #c0c8d0
    }

    h3, p {
      margin: 0;
    }
  }

  &-ViewForum {
    border: 1px solid #c0c8d0;
    position: relative;
  }

  &-SubForum {
    text-transform: uppercase;
    font-weight: lighter;
    font-size: 23px;
    margin-bottom: 0;
    margin-top: 10px;
    @include u.below('md') {
      font-size: u.size('text-lg');
    }
  }

  &-header {
    background: $optwear-blue-strong;
    color: $optwear-white;
    font-size: 18px;

    th {
      font-weight: lighter;
      text-align: center;
      white-space: nowrap;

      &:not(:first-child) {
        padding: 0 5px;
      }

    }

    &--LForum {
      line-height: 2em;
    }
  }

  &-hr {
    padding: 10px 0 0;

    hr {
      background-color: $optwear-blue-light;
      height: 2px;
      margin: 0;
      width: 100%;
    }
  }

  &-SubForum {
    &-description {
      color: #808184;
      font-size: 16px;
      @include u.below('sm') {
        .postlink {
          &:not([href*="vk"]):not([href*="odnoklassniki"]):not([href*="facebook"]):not([href*="instagram"]) {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    &-level3 {
      h3 {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-weight: lighter;
      }

      a {
        color: $color-text-link;

        &:active,
        &:hover,
        &:visited,
        &:focus {
          color: $color-text-link-hover;
        }
      }
    }

    a {
      //color: #000000;
      text-decoration: none;

      &:active,
      &:hover,
      &:visited,
      &:focus {
        text-decoration: none;
        color: $color-text-link-hover;
      }
    }
  }

  &-lastMessage {
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
    width: 200px;
    white-space: nowrap;
    margin: 0;

    a {
      color: $color-text-link;
      text-decoration: none;

      &:active,
      &:hover,
      &:visited,
      &:focus {
        text-decoration: none;
        color: $color-text-link-hover;
      }
    }

  }

}


.ForumListTable-SubForum-description {
  margin: 0;
}
