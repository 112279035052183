.lot-summary-features-list {
  margin: 0;
  padding: 0;
	list-style: none;
  &__item {
    position: relative;
    padding-left: 12px;
    &:before {
      content: "\2013";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
