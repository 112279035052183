.catalogs-list {
	list-style: none;
	margin: 0;
	padding: 0;
	&__item{
		transition: all 0.25ms;
	}
	&__item-collapser {
		cursor: pointer;
		text-align: center;
		position: relative;
		z-index: 1;
		&-text {
			background: #fff;
			padding: 0 15px;
		}
		&:before {
			border-top: 2px solid #dfdfdf;
			content: "";
			margin: 0 auto;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			bottom: 0;
			width: 95%;
			z-index: -1;
		}
	}
}
