@use "src/assets/scss/utils" as u;

.Topic {
  //margin: 0 auto;
  display: flex;
  position: relative;

  &-label {
    margin: 0;
    margin-top: 0.87em;
  }

  &-btns {
    display: inline-block;

    #PURCHASE_META_REACT_MODULE, #PURCHASE_META_REACT_MODULE_FALLBACK {
      display: inline-block;

      .btn {
        > .count {
          position: relative;
          padding: 0 0 0 u.size('unit-half');
          display: inline-block;
          border-left: 2px solid;
        }
      }
    }

    &-attention {
      background: $color-accent;
      font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
    }
  }

  &-container {
    overflow: hidden;
    flex: 1;

    & > h1 {
      margin: 0;
      @include u.below('md') {
        font-size: 20px;
      }
      @include u.below('xs') {
        font-size: u.size('text-md');
      }
    }

    .message {
      padding: 20px;
      @include u.below('md') {
        padding: 5px;
        overflow: hidden;
      }
    }
  }


  &SortForm {
    //text-align: center;
    background: $optwear-blue-gradient;
    color: $optwear-white;
    font-size: 14px;

    form {
      min-height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 5px;

      select, input {
        height: 100%;
        @include u.below('md') {
          width: 100%;
          height: auto;
        }
      }

      select {
        @include u.below('md') {
          margin: 10px 0;
          &:last-of-type {
            margin: 10px 0 0;
          }
        }
      }

      @include u.below('md') {
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
      }
    }

  }

  &Post {
    &-empty {

    }
  }

  &ActionMenu {
    display: flex;
    padding: 10px 0;
    @include u.below('md') {
      flex-direction: column;
    }
  }

  &QuickAction {
    padding: 5px 0;
  }

  &PurchaseSummary {
    display: flex;
    flex-direction: column;
    margin: 5px 0 15px;

    &-item {
      font-size: 16px;
      display: flex;
      flex-direction: column;

      @include u.below('xs') {
        font-size: u.size('text-sm');
      }

      &:not(:last-child) {
        margin: 0 0 15px;
      }

      &-label {
        font-weight: 500;
        margin: 0 0 5px;
      }
    }
  }
}

.TopicFirstActionMenu {
  @include u.below('md') {
    .TopicActionMenu {
      padding: 0 0 u.size('unit');

      .Topic-btns {
        a {
          display: none;
        }

        #PURCHASE_META_REACT_MODULE, #PURCHASE_META_REACT_MODULE_FALLBACK {
          width: 100%;
          display: block;

          .btn {
            width: 100%;
            margin: u.size('unit') 0 0;
          }
        }

        a.ForumList-Btn-subscribe {
          display: inline-block;
          width: 100%;
          margin: u.size('unit') 0 0;
        }
      }
    }
  }
}

@import "TopicQuickReply";
@import "TopicPoll";
@import "TopicPost/TopicPost";
@import "TopicCatalog/TopicCatalog";
@import "sp-topic/sp-topic";
