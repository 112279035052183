.AnnouncementBlock-List{
	margin-left: 5%;
	list-style-type: none;
	line-height: 1.9em;
	font-size: 17px;
	li {
		display: flex;
		align-items: flex-start;
	}

	a {
		padding-left: 5px;
	}
}
