.codetitle {
	margin: 10px 5px 0;
	padding: 2px 4px;
	border-width: 1px 1px 0;
	border-style: solid;
	border-color: #A9B8C2;
	color: #333;
	background-color: #A9B8C2;
	font-family: "Lucida Grande", Verdana, Helvetica, Arial, sans-serif;
	font-size: .8em
}

.codecontent, pre {
	font-family: Monaco, 'Courier New', monospace
}

.codecontent {
	direction: ltr;
	margin: 0 5px 10px;
	padding: 5px;
	border-width: 0 1px 1px;
	color: #060;
	font-size: .85em;
	background-color: #FAFAFA
}
