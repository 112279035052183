@import "@Scss/old/common/_variables.scss";
$button-blue: #05a0d4;
$button-gray: #c6c6c6;
.Login-label {
	display: none;
}

.Login {
	padding: 25px;
	&Captcha {
		display: none;
		&-image {
			width: 100%;
		}
		&-label {
			font-size: 14px;
		}
	}
}

.Login-formFields {
	font-family: $basic-font-family;
	&Wrapper {
		.Login-formFields {
			margin-bottom: 10px;
		}
	}
	input {
		font-family: $basic-font-family;
		line-height: 17px;
		font-size: 15px;
		height: 25px;
		border-radius: $border-radius-main;
		border-color: #c6c6c6;
	}
}

.Login-btn {
	height: 25px;
	font-size: 15px;
	padding: 0;
	min-width: 145px;
	font-family: $basic-font-family;
	text-transform: none;
	margin-top: 0;
	margin-bottom: 0;
	line-height: 22px;
	box-sizing: border-box;
	&_register {
		flex-grow: 3;
		&:hover,
		&:active,
		&:visited,
		&:focus {
			color: $optwear-white;
			text-decoration: none;
		}
	}
	&_login {
		flex-shrink: 1;
		flex-grow: 1;
		margin-right: 10px;
		border-color: $button-blue;
		&:hover, &:focus {
			background-color: $button-blue;
			border-color: $button-blue;
		}
	}
	&_registerFlyout {
		display: block;
		width: 100%;
		margin: 0 auto;
		text-transform: capitalize;
	}
	&_forget {
		&:hover, &:focus {
			border-color: $button-gray;
			background-color: $button-gray;
		}
	}
}

.LoginAutoSignIn {
	font-size: 15px;
	display: inline !important;
	&__label {
		font-size: 13px;
		margin-top: -17px;
		display: block;
		margin-left: 20px;
	}
	&__checkbox {
		margin-left: 2px;
		margin-top: 4px;
	}
}

.UserBlockAccountFlyOut {
	.Login-btnWrapper {
		display: flex;
		margin-top: 13px;
		margin-bottom: 7px;
		max-width: 300px;

	}
	.SiteHeader-item--login {
		display: flex;
	}
	.LoginAutoSignIn-checkbox {
		display: inline !important;
	}
}
