@use "src/assets/scss/utils" as u;
@use 'sass:meta';
//@use "~tippy.js/dist/tippy.css";


//@include meta.load-css('~tippy.js/dist/tippy.css');
.tippy-tooltip {
  text-align: center;
}

//.tippy-tooltip.optwear-theme {
//		background: linear-gradient(to right, u.var-color('secondary') 0%, u.var-color('primary') 100%);
//		color: u.var-color('white');
//}
//
//.tippy-tooltip.optwear-theme[data-placement^='top'] .tippy-arrow {
//		border-top-color: u.var-color('primary');
//}
//.tippy-tooltip.optwear-theme[data-placement^='bottom'] .tippy-arrow {
//		border-bottom-color: u.var-color('primary');
//}
//.tippy-tooltip.optwear-theme[data-placement^='left'] .tippy-arrow {
//		border-left-color: u.var-color('primary');
//}
//.tippy-tooltip.optwear-theme[data-placement^='right'] .tippy-arrow {
//		border-right-color: u.var-color('primary');
//}
