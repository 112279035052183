.lot-user-form-quantity {
	display: flex;
	align-items: center;
	min-width: 130px;
	max-width: 160px;
	#catalog &{
		max-width: none;
	}
	&__label {
		padding-top: 7px;
		display: block;
	}
	&__unit {
		padding-left: 10px;
	}
	.quantity-input__button {
		font-size: 25px;
		padding-top: 0;
		padding-bottom: 0;
	}
}
