.pristroy-edit-item {
	width: 957px;
	box-sizing: border-box;
	border: 0;
	font-family: $second-font-family;
	textarea {
		height: 210px;
		resize: none;
		padding: 4px 10px;
	}
	&__note {
		font-size: 13px;
		line-height: 18px;
		color: #858585;
		margin: 0;
	}
	&__submit {
		float: right;
	}
}
